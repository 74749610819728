export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Date custom scalar type */
  Date: GraphQL_Date;
  /** Notification emails scalar */
  NotificationEmailsScalar: GraphQL_NotificationEmailsScalar;
};

export enum AccessTokenError {
  EmailDoesNotExist = 'EmailDoesNotExist',
  EmailNotSent = 'EmailNotSent',
  EmailOrPasswordNotSent = 'EmailOrPasswordNotSent',
  ErrorCreatingAccessToken = 'ErrorCreatingAccessToken',
  ErrorOnOrganizationCreation = 'ErrorOnOrganizationCreation',
  GoogleTokenInvalid = 'GoogleTokenInvalid',
  InvalidCharityNumber = 'InvalidCharityNumber',
  InvalidOrganizationName = 'InvalidOrganizationName',
  NoDonorAllowedOnMobileApp = 'NoDonorAllowedOnMobileApp',
  PasswordDoNotMatch = 'PasswordDoNotMatch',
  ResetTokenDoNotMatch = 'ResetTokenDoNotMatch',
  StytchOrganizationIdNotFound = 'StytchOrganizationIdNotFound',
  TokenDoesNotMatch = 'TokenDoesNotMatch',
  TwoFactorCodeDoesNotMatch = 'TwoFactorCodeDoesNotMatch',
  TwoFactorCodeExpired = 'TwoFactorCodeExpired',
  UnauthorizedToken = 'UnauthorizedToken',
  UrlInvalid = 'UrlInvalid',
  UserAlreadyExistsWithOrganization = 'UserAlreadyExistsWithOrganization',
  UserNotFoundWithOrganization = 'UserNotFoundWithOrganization'
}

export type AccessTokenObject = {
  __typename?: 'AccessTokenObject';
  accessToken?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  error?: Maybe<AccessTokenError>;
  id?: Maybe<Scalars['String']>;
  locale?: Maybe<Locales>;
  maskedPhoneNumber?: Maybe<Scalars['String']>;
  redirectUrl?: Maybe<Scalars['String']>;
  refreshToken?: Maybe<Scalars['String']>;
  role?: Maybe<UserRoles>;
  shouldAuthenticateBySms?: Maybe<Scalars['Boolean']>;
  shouldAuthenticateWithTwoFactor?: Maybe<Scalars['Boolean']>;
  shouldRedirectToMFAConfig?: Maybe<Scalars['Boolean']>;
  stytchVerdict?: Maybe<Scalars['String']>;
};

export type AccessTokenObjectBjc0GueY = {
  __typename?: 'AccessTokenObjectBjc0GueY';
  error?: Maybe<AccessTokenObjectBjc0GueYErrorObject>;
  object?: Maybe<AccessTokenObject>;
};

export type AccessTokenObjectBjc0GueYErrorObject = {
  __typename?: 'AccessTokenObjectBjc0GueYErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type AccessTokenObjectLfVabbXo = {
  __typename?: 'AccessTokenObjectLfVabbXo';
  error?: Maybe<AccessTokenObjectLfVabbXoErrorObject>;
  object?: Maybe<AccessTokenObject>;
};

export type AccessTokenObjectLfVabbXoErrorObject = {
  __typename?: 'AccessTokenObjectLfVabbXoErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type AccessTokenObjectZuA5AgzL = {
  __typename?: 'AccessTokenObjectZuA5AgzL';
  error?: Maybe<AccessTokenObjectZuA5AgzLErrorObject>;
  object?: Maybe<AccessTokenObject>;
};

export type AccessTokenObjectZuA5AgzLErrorObject = {
  __typename?: 'AccessTokenObjectZuA5AgzLErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type AccessTokenObjectd5UcOYtl = {
  __typename?: 'AccessTokenObjectd5UcOYtl';
  error?: Maybe<AccessTokenObjectd5UcOYtlErrorObject>;
  object?: Maybe<AccessTokenObject>;
};

export type AccessTokenObjectd5UcOYtlErrorObject = {
  __typename?: 'AccessTokenObjectd5UcOYtlErrorObject';
  code?: Maybe<AccessTokenError>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type AccessTokenObjectjrEixuYp = {
  __typename?: 'AccessTokenObjectjrEixuYP';
  error?: Maybe<AccessTokenObjectjrEixuYpErrorObject>;
  object?: Maybe<AccessTokenObject>;
};

export type AccessTokenObjectjrEixuYpErrorObject = {
  __typename?: 'AccessTokenObjectjrEixuYPErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type AccessTokenObjectmSxynZpj = {
  __typename?: 'AccessTokenObjectmSxynZpj';
  error?: Maybe<AccessTokenObjectmSxynZpjErrorObject>;
  object?: Maybe<AccessTokenObject>;
};

export type AccessTokenObjectmSxynZpjErrorObject = {
  __typename?: 'AccessTokenObjectmSxynZpjErrorObject';
  code?: Maybe<AccessTokenError>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type AccessTokenObjectrJhfKpmU = {
  __typename?: 'AccessTokenObjectrJhfKpmU';
  error?: Maybe<AccessTokenObjectrJhfKpmUErrorObject>;
  object?: Maybe<AccessTokenObject>;
};

export type AccessTokenObjectrJhfKpmUErrorObject = {
  __typename?: 'AccessTokenObjectrJhfKpmUErrorObject';
  code?: Maybe<AccessTokenError>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type AccessTokenResponseObject = {
  __typename?: 'AccessTokenResponseObject';
  error?: Maybe<AccessTokenResponseObjectErrorObject>;
  object?: Maybe<AccessTokenObject>;
};

export type AccessTokenResponseObjectErrorObject = {
  __typename?: 'AccessTokenResponseObjectErrorObject';
  code?: Maybe<AccessTokenError>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type AccountBalancesObject = {
  __typename?: 'AccountBalancesObject';
  available?: Maybe<Array<BalanceObject>>;
  issuing?: Maybe<AccountBalancesObject>;
  pending?: Maybe<Array<BalanceObject>>;
};

export type AccountBalancesObjectoZ8Y8waw = {
  __typename?: 'AccountBalancesObjectoZ8Y8waw';
  error?: Maybe<AccountBalancesObjectoZ8Y8wawErrorObject>;
  object?: Maybe<AccountBalancesObject>;
};

export type AccountBalancesObjectoZ8Y8wawErrorObject = {
  __typename?: 'AccountBalancesObjectoZ8Y8wawErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ActivateDonationFormInput = {
  donationFormId: Scalars['String'];
  isOnboarding: Scalars['Boolean'];
};

export type ActivateTicketingInput = {
  isOnboarding: Scalars['Boolean'];
  ticketingId: Scalars['String'];
};

export type AggregateRateFieldsObject = {
  __typename?: 'AggregateRateFieldsObject';
  commandId: Scalars['String'];
  rateFields: Array<RateFieldObjectWithCount>;
};

export type AggregateRateFieldsObjectGjO0L7qt = {
  __typename?: 'AggregateRateFieldsObjectGjO0L7qt';
  error?: Maybe<AggregateRateFieldsObjectGjO0L7qtErrorObject>;
  items?: Maybe<Array<AggregateRateFieldsObject>>;
};

export type AggregateRateFieldsObjectGjO0L7qtErrorObject = {
  __typename?: 'AggregateRateFieldsObjectGjO0L7qtErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum AmplitudeVariantValue {
  A = 'A',
  B = 'B',
  C = 'C',
  CONTROL = 'CONTROL',
  OFF = 'OFF'
}

export type AnswerInput = {
  answer?: InputMaybe<Scalars['String']>;
  choiceIds?: InputMaybe<Array<Scalars['String']>>;
  id: Scalars['String'];
  questionId: Scalars['String'];
  rateId?: InputMaybe<Scalars['String']>;
  utm?: InputMaybe<Scalars['String']>;
};

export type AnswerObject = {
  __typename?: 'AnswerObject';
  answer?: Maybe<Scalars['String']>;
  choiceIds?: Maybe<Array<Scalars['String']>>;
  choices?: Maybe<Array<ChoiceObject>>;
  commandId?: Maybe<Scalars['String']>;
  donationId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  orderId?: Maybe<Scalars['String']>;
  productTicketId?: Maybe<Scalars['String']>;
  question?: Maybe<QuestionObject>;
  questionId: Scalars['String'];
  ticketId?: Maybe<Scalars['String']>;
};

export type AnswerObjectHuYzUBzw = {
  __typename?: 'AnswerObjectHuYzUBzw';
  error?: Maybe<AnswerObjectHuYzUBzwErrorObject>;
  items?: Maybe<Array<AnswerObject>>;
};

export type AnswerObjectHuYzUBzwErrorObject = {
  __typename?: 'AnswerObjectHuYzUBzwErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type AnswerObjectkJvfdHWv = {
  __typename?: 'AnswerObjectkJvfdHWv';
  error?: Maybe<AnswerObjectkJvfdHWvErrorObject>;
  items?: Maybe<Array<AnswerObject>>;
};

export type AnswerObjectkJvfdHWvErrorObject = {
  __typename?: 'AnswerObjectkJvfdHWvErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type AnswerObjectuIjnRHnT = {
  __typename?: 'AnswerObjectuIjnRHnT';
  error?: Maybe<AnswerObjectuIjnRHnTErrorObject>;
  items?: Maybe<Array<AnswerObject>>;
};

export type AnswerObjectuIjnRHnTErrorObject = {
  __typename?: 'AnswerObjectuIjnRHnTErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type AnswerResponseListObject = {
  __typename?: 'AnswerResponseListObject';
  error?: Maybe<AnswerResponseListObjectErrorObject>;
  items?: Maybe<Array<AnswerObject>>;
};

export type AnswerResponseListObjectErrorObject = {
  __typename?: 'AnswerResponseListObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type AnswerResponseObject = {
  __typename?: 'AnswerResponseObject';
  error?: Maybe<AnswerResponseObjectErrorObject>;
  object?: Maybe<AnswerObject>;
};

export type AnswerResponseObjectErrorObject = {
  __typename?: 'AnswerResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ApplicationInitialStatusObject = {
  __typename?: 'ApplicationInitialStatusObject';
  firstForm?: Maybe<FirstFormObject>;
  hasActiveDonationForm: Scalars['Boolean'];
  hasActiveTicketing: Scalars['Boolean'];
  hasArchivedDonationForm: Scalars['Boolean'];
  hasArchivedTicketing: Scalars['Boolean'];
  hasDonationForm: Scalars['Boolean'];
  hasTicketing: Scalars['Boolean'];
  latestForm?: Maybe<FrontendFormObject>;
};

export type ApplicationInitialStatusResponseObject = {
  __typename?: 'ApplicationInitialStatusResponseObject';
  error?: Maybe<ApplicationInitialStatusResponseObjectErrorObject>;
  object?: Maybe<ApplicationInitialStatusObject>;
};

export type ApplicationInitialStatusResponseObjectErrorObject = {
  __typename?: 'ApplicationInitialStatusResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum AvailablePaymentCurrency {
  Cad = 'Cad',
  Usd = 'Usd'
}

export type BalanceObject = {
  __typename?: 'BalanceObject';
  amount: Scalars['Float'];
  currency: Scalars['String'];
};

export type BalanceTransactionExportFilter = {
  endDate?: InputMaybe<Scalars['Date']>;
  startDate?: InputMaybe<Scalars['Date']>;
};

export enum BalanceTransactionType {
  issuing_authorization_hold = 'issuing_authorization_hold',
  issuing_dispute = 'issuing_dispute',
  issuing_transaction = 'issuing_transaction',
  payment = 'payment',
  payment_refund = 'payment_refund',
  payout = 'payout',
  pending_topup = 'pending_topup',
  scheduled_payout = 'scheduled_payout',
  topup = 'topup'
}

export enum BankAccountStatus {
  Errored = 'Errored',
  Verified = 'Verified'
}

export type BidRateObject = {
  __typename?: 'BidRateObject';
  rateId: Scalars['String'];
  title: Scalars['String'];
};

export type BidRateObject0Hr8Ral5 = {
  __typename?: 'BidRateObject0Hr8RAL5';
  error?: Maybe<BidRateObject0Hr8Ral5ErrorObject>;
  items?: Maybe<Array<BidRateObject>>;
};

export type BidRateObject0Hr8Ral5ErrorObject = {
  __typename?: 'BidRateObject0Hr8RAL5ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type Boolean0HWmY9Te = {
  __typename?: 'Boolean0HWmY9Te';
  error?: Maybe<Boolean0HWmY9TeErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type Boolean0HWmY9TeErrorObject = {
  __typename?: 'Boolean0HWmY9TeErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type Boolean0W03O2W7 = {
  __typename?: 'Boolean0W03O2W7';
  error?: Maybe<Boolean0W03O2W7ErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type Boolean0W03O2W7ErrorObject = {
  __typename?: 'Boolean0W03O2W7ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type Boolean0akIasJz = {
  __typename?: 'Boolean0akIasJZ';
  error?: Maybe<Boolean0akIasJzErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type Boolean0akIasJzErrorObject = {
  __typename?: 'Boolean0akIasJZErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type Boolean0pGjq6k5 = {
  __typename?: 'Boolean0pGJQ6k5';
  error?: Maybe<Boolean0pGjq6k5ErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type Boolean0pGjq6k5ErrorObject = {
  __typename?: 'Boolean0pGJQ6k5ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type Boolean2eqBxl4u = {
  __typename?: 'Boolean2eqBXL4u';
  error?: Maybe<Boolean2eqBxl4uErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type Boolean2eqBxl4uErrorObject = {
  __typename?: 'Boolean2eqBXL4uErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type Boolean2wbedRfz = {
  __typename?: 'Boolean2wbedRfz';
  error?: Maybe<Boolean2wbedRfzErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type Boolean2wbedRfzErrorObject = {
  __typename?: 'Boolean2wbedRfzErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type Boolean3PlgjQ9s = {
  __typename?: 'Boolean3PlgjQ9s';
  error?: Maybe<Boolean3PlgjQ9sErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type Boolean3PlgjQ9sErrorObject = {
  __typename?: 'Boolean3PlgjQ9sErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type Boolean3kow4dYe = {
  __typename?: 'Boolean3kow4dYE';
  error?: Maybe<Boolean3kow4dYeErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type Boolean3kow4dYeErrorObject = {
  __typename?: 'Boolean3kow4dYEErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type Boolean4ABkaUal = {
  __typename?: 'Boolean4ABkaUAL';
  error?: Maybe<Boolean4ABkaUalErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type Boolean4ABkaUalErrorObject = {
  __typename?: 'Boolean4ABkaUALErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type Boolean4aVi8Xr9 = {
  __typename?: 'Boolean4aVi8XR9';
  error?: Maybe<Boolean4aVi8Xr9ErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type Boolean4aVi8Xr9ErrorObject = {
  __typename?: 'Boolean4aVi8XR9ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type Boolean4p7qk46m = {
  __typename?: 'Boolean4p7qk46m';
  error?: Maybe<Boolean4p7qk46mErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type Boolean4p7qk46mErrorObject = {
  __typename?: 'Boolean4p7qk46mErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type Boolean6iQzU8kP = {
  __typename?: 'Boolean6iQzU8kP';
  error?: Maybe<Boolean6iQzU8kPErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type Boolean6iQzU8kPErrorObject = {
  __typename?: 'Boolean6iQzU8kPErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type Boolean7ffvdsXp = {
  __typename?: 'Boolean7ffvdsXp';
  error?: Maybe<Boolean7ffvdsXpErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type Boolean7ffvdsXpErrorObject = {
  __typename?: 'Boolean7ffvdsXpErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type Boolean18sl8Nlt = {
  __typename?: 'Boolean18sl8Nlt';
  error?: Maybe<Boolean18sl8NltErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type Boolean18sl8NltErrorObject = {
  __typename?: 'Boolean18sl8NltErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type Boolean67kg3kNa = {
  __typename?: 'Boolean67kg3kNA';
  error?: Maybe<Boolean67kg3kNaErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type Boolean67kg3kNaErrorObject = {
  __typename?: 'Boolean67kg3kNAErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanAhHy1jxB = {
  __typename?: 'BooleanAhHy1jxB';
  error?: Maybe<BooleanAhHy1jxBErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanAhHy1jxBErrorObject = {
  __typename?: 'BooleanAhHy1jxBErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanBnCcfQxb = {
  __typename?: 'BooleanBnCcfQxb';
  error?: Maybe<BooleanBnCcfQxbErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanBnCcfQxbErrorObject = {
  __typename?: 'BooleanBnCcfQxbErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanCaIovkw8 = {
  __typename?: 'BooleanCAIovkw8';
  error?: Maybe<BooleanCaIovkw8ErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanCaIovkw8ErrorObject = {
  __typename?: 'BooleanCAIovkw8ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanCkcJEji7 = {
  __typename?: 'BooleanCkcJEji7';
  error?: Maybe<BooleanCkcJEji7ErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanCkcJEji7ErrorObject = {
  __typename?: 'BooleanCkcJEji7ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanD0niaLYp = {
  __typename?: 'BooleanD0niaLYp';
  error?: Maybe<BooleanD0niaLYpErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanD0niaLYpErrorObject = {
  __typename?: 'BooleanD0niaLYpErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanEEfW0wz8 = {
  __typename?: 'BooleanEEfW0wz8';
  error?: Maybe<BooleanEEfW0wz8ErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanEEfW0wz8ErrorObject = {
  __typename?: 'BooleanEEfW0wz8ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanF4ryRcon = {
  __typename?: 'BooleanF4ryRCON';
  error?: Maybe<BooleanF4ryRconErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanF4ryRconErrorObject = {
  __typename?: 'BooleanF4ryRCONErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanGnd6Zteu = {
  __typename?: 'BooleanGND6ZTEU';
  error?: Maybe<BooleanGnd6ZteuErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanGnd6ZteuErrorObject = {
  __typename?: 'BooleanGND6ZTEUErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanGqe3Bijd = {
  __typename?: 'BooleanGqe3BIJD';
  error?: Maybe<BooleanGqe3BijdErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanGqe3BijdErrorObject = {
  __typename?: 'BooleanGqe3BIJDErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanJ8e34ujp = {
  __typename?: 'BooleanJ8e34ujp';
  error?: Maybe<BooleanJ8e34ujpErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanJ8e34ujpErrorObject = {
  __typename?: 'BooleanJ8e34ujpErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanNtcomtMh = {
  __typename?: 'BooleanNTCOMTMh';
  error?: Maybe<BooleanNtcomtMhErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanNtcomtMhErrorObject = {
  __typename?: 'BooleanNTCOMTMhErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanResponseObject = {
  __typename?: 'BooleanResponseObject';
  error?: Maybe<BooleanResponseObjectErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanResponseObjectErrorObject = {
  __typename?: 'BooleanResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanS1CqJuGs = {
  __typename?: 'BooleanS1CqJUGs';
  error?: Maybe<BooleanS1CqJuGsErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanS1CqJuGsErrorObject = {
  __typename?: 'BooleanS1CqJUGsErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanS2KzqG5J = {
  __typename?: 'BooleanS2KzqG5J';
  error?: Maybe<BooleanS2KzqG5JErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanS2KzqG5JErrorObject = {
  __typename?: 'BooleanS2KzqG5JErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanSg02wFux = {
  __typename?: 'BooleanSG02wFux';
  error?: Maybe<BooleanSg02wFuxErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanSg02wFuxErrorObject = {
  __typename?: 'BooleanSG02wFuxErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanT7er1OoZ = {
  __typename?: 'BooleanT7er1OoZ';
  error?: Maybe<BooleanT7er1OoZErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanT7er1OoZErrorObject = {
  __typename?: 'BooleanT7er1OoZErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanTqEsFe5B = {
  __typename?: 'BooleanTqESFe5B';
  error?: Maybe<BooleanTqEsFe5BErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanTqEsFe5BErrorObject = {
  __typename?: 'BooleanTqESFe5BErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanVtPd9gIj = {
  __typename?: 'BooleanVtPD9gIj';
  error?: Maybe<BooleanVtPd9gIjErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanVtPd9gIjErrorObject = {
  __typename?: 'BooleanVtPD9gIjErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanWDsKs1Bb = {
  __typename?: 'BooleanWDsKS1Bb';
  error?: Maybe<BooleanWDsKs1BbErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanWDsKs1BbErrorObject = {
  __typename?: 'BooleanWDsKS1BbErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanX4rePyHt = {
  __typename?: 'BooleanX4rePyHT';
  error?: Maybe<BooleanX4rePyHtErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanX4rePyHtErrorObject = {
  __typename?: 'BooleanX4rePyHTErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanYDoHd8La = {
  __typename?: 'BooleanYDoHd8La';
  error?: Maybe<BooleanYDoHd8LaErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanYDoHd8LaErrorObject = {
  __typename?: 'BooleanYDoHd8LaErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanZMmein5A = {
  __typename?: 'BooleanZMmein5A';
  error?: Maybe<BooleanZMmein5AErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanZMmein5AErrorObject = {
  __typename?: 'BooleanZMmein5AErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanZSm7l5tV = {
  __typename?: 'BooleanZSm7l5tV';
  error?: Maybe<BooleanZSm7l5tVErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanZSm7l5tVErrorObject = {
  __typename?: 'BooleanZSm7l5tVErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanbKOlaGTl = {
  __typename?: 'BooleanbKOlaGTl';
  error?: Maybe<BooleanbKOlaGTlErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanbKOlaGTlErrorObject = {
  __typename?: 'BooleanbKOlaGTlErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanbPeYspzJ = {
  __typename?: 'BooleanbPeYspzJ';
  error?: Maybe<BooleanbPeYspzJErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanbPeYspzJErrorObject = {
  __typename?: 'BooleanbPeYspzJErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type Booleancu2sQ1tm = {
  __typename?: 'Booleancu2sQ1tm';
  error?: Maybe<Booleancu2sQ1tmErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type Booleancu2sQ1tmErrorObject = {
  __typename?: 'Booleancu2sQ1tmErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleandbicoUgB = {
  __typename?: 'BooleandbicoUgB';
  error?: Maybe<BooleandbicoUgBErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleandbicoUgBErrorObject = {
  __typename?: 'BooleandbicoUgBErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleandcJHbjeA = {
  __typename?: 'BooleandcJHbjeA';
  error?: Maybe<BooleandcJHbjeAErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleandcJHbjeAErrorObject = {
  __typename?: 'BooleandcJHbjeAErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanfFVyQt09 = {
  __typename?: 'BooleanfFVyQT09';
  error?: Maybe<BooleanfFVyQt09ErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanfFVyQt09ErrorObject = {
  __typename?: 'BooleanfFVyQT09ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanfNhQdRIv = {
  __typename?: 'BooleanfNhQdRIv';
  error?: Maybe<BooleanfNhQdRIvErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanfNhQdRIvErrorObject = {
  __typename?: 'BooleanfNhQdRIvErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleangnXmrTyc = {
  __typename?: 'BooleangnXmrTyc';
  error?: Maybe<BooleangnXmrTycErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleangnXmrTycErrorObject = {
  __typename?: 'BooleangnXmrTycErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type Booleanj1lPsDKf = {
  __typename?: 'Booleanj1lPsDKf';
  error?: Maybe<Booleanj1lPsDKfErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type Booleanj1lPsDKfErrorObject = {
  __typename?: 'Booleanj1lPsDKfErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanjF5Hz1Hx = {
  __typename?: 'BooleanjF5Hz1HX';
  error?: Maybe<BooleanjF5Hz1HxErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanjF5Hz1HxErrorObject = {
  __typename?: 'BooleanjF5Hz1HXErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanlcOfm0Yl = {
  __typename?: 'BooleanlcOfm0Yl';
  error?: Maybe<BooleanlcOfm0YlErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanlcOfm0YlErrorObject = {
  __typename?: 'BooleanlcOfm0YlErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleannFDzo2Bp = {
  __typename?: 'BooleannFDzo2Bp';
  error?: Maybe<BooleannFDzo2BpErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleannFDzo2BpErrorObject = {
  __typename?: 'BooleannFDzo2BpErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanoxKUyLvw = {
  __typename?: 'BooleanoxKUyLvw';
  error?: Maybe<BooleanoxKUyLvwErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanoxKUyLvwErrorObject = {
  __typename?: 'BooleanoxKUyLvwErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanpCa6Rq4M = {
  __typename?: 'BooleanpCa6Rq4M';
  error?: Maybe<BooleanpCa6Rq4MErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanpCa6Rq4MErrorObject = {
  __typename?: 'BooleanpCa6Rq4MErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanqcqDQzb2 = {
  __typename?: 'BooleanqcqDQzb2';
  error?: Maybe<BooleanqcqDQzb2ErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanqcqDQzb2ErrorObject = {
  __typename?: 'BooleanqcqDQzb2ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleansJtAy9Bc = {
  __typename?: 'BooleansJTAy9BC';
  error?: Maybe<BooleansJtAy9BcErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleansJtAy9BcErrorObject = {
  __typename?: 'BooleansJTAy9BCErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleantBt59btI = {
  __typename?: 'BooleantBT59btI';
  error?: Maybe<BooleantBt59btIErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleantBt59btIErrorObject = {
  __typename?: 'BooleantBT59btIErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanuJaHxAbe = {
  __typename?: 'BooleanuJaHxABE';
  error?: Maybe<BooleanuJaHxAbeErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanuJaHxAbeErrorObject = {
  __typename?: 'BooleanuJaHxABEErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type Booleanud4nUlUf = {
  __typename?: 'Booleanud4nUlUF';
  error?: Maybe<Booleanud4nUlUfErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type Booleanud4nUlUfErrorObject = {
  __typename?: 'Booleanud4nUlUFErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type Booleanvhk1u516 = {
  __typename?: 'Booleanvhk1u516';
  error?: Maybe<Booleanvhk1u516ErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type Booleanvhk1u516ErrorObject = {
  __typename?: 'Booleanvhk1u516ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanvqqoDmRt = {
  __typename?: 'BooleanvqqoDMRt';
  error?: Maybe<BooleanvqqoDmRtErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanvqqoDmRtErrorObject = {
  __typename?: 'BooleanvqqoDMRtErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type Booleanx3yfMSqa = {
  __typename?: 'Booleanx3yfMSqa';
  error?: Maybe<Booleanx3yfMSqaErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type Booleanx3yfMSqaErrorObject = {
  __typename?: 'Booleanx3yfMSqaErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanxJq9M1pz = {
  __typename?: 'BooleanxJq9M1pz';
  error?: Maybe<BooleanxJq9M1pzErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanxJq9M1pzErrorObject = {
  __typename?: 'BooleanxJq9M1pzErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanxRfKmRzq = {
  __typename?: 'BooleanxRfKMRzq';
  error?: Maybe<BooleanxRfKmRzqErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanxRfKmRzqErrorObject = {
  __typename?: 'BooleanxRfKMRzqErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanxUy1AJdx = {
  __typename?: 'BooleanxUy1AJdx';
  error?: Maybe<BooleanxUy1AJdxErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanxUy1AJdxErrorObject = {
  __typename?: 'BooleanxUy1AJdxErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanxrK0yVzg = {
  __typename?: 'BooleanxrK0yVzg';
  error?: Maybe<BooleanxrK0yVzgErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanxrK0yVzgErrorObject = {
  __typename?: 'BooleanxrK0yVzgErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type Booleanyz50Vfwm = {
  __typename?: 'Booleanyz50Vfwm';
  error?: Maybe<Booleanyz50VfwmErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type Booleanyz50VfwmErrorObject = {
  __typename?: 'Booleanyz50VfwmErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanzH9HuK1a = {
  __typename?: 'BooleanzH9HuK1a';
  error?: Maybe<BooleanzH9HuK1aErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanzH9HuK1aErrorObject = {
  __typename?: 'BooleanzH9HuK1aErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum CardHolderStatus {
  Active = 'Active',
  Blocked = 'Blocked',
  Inactive = 'Inactive'
}

export type CardVolumeObject = {
  __typename?: 'CardVolumeObject';
  amount: Scalars['Float'];
  count: Scalars['Float'];
};

export type CardVolumeResponseObject = {
  __typename?: 'CardVolumeResponseObject';
  error?: Maybe<CardVolumeResponseObjectErrorObject>;
  object?: Maybe<CardVolumeObject>;
};

export type CardVolumeResponseObjectErrorObject = {
  __typename?: 'CardVolumeResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type CheckIfResetPasswordIsValidInput = {
  email: Scalars['String'];
  resetPasswordToken: Scalars['String'];
};

export type CheckoutSessionObject = {
  __typename?: 'CheckoutSessionObject';
  cancelUrl?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  object?: Maybe<Scalars['String']>;
  successUrl?: Maybe<Scalars['String']>;
};

export type CheckoutSessionResponseObject = {
  __typename?: 'CheckoutSessionResponseObject';
  error?: Maybe<CheckoutSessionResponseObjectErrorObject>;
  object?: Maybe<CheckoutSessionObject>;
};

export type CheckoutSessionResponseObjectErrorObject = {
  __typename?: 'CheckoutSessionResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ChoiceFieldInput = {
  body: Scalars['String'];
  choiceId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  locale: Locales;
};

export type ChoiceFieldObject = {
  __typename?: 'ChoiceFieldObject';
  body: Scalars['String'];
  id: Scalars['String'];
  locale: Locales;
};

export type ChoiceInput = {
  choiceFields: Array<ChoiceFieldInput>;
  id: Scalars['String'];
  isArchived?: InputMaybe<Scalars['Boolean']>;
  maximum?: InputMaybe<Scalars['Float']>;
  sortIndex: Scalars['Float'];
};

export type ChoiceObject = {
  __typename?: 'ChoiceObject';
  choiceFields: Array<ChoiceFieldObject>;
  countAlreadyAnswered?: Maybe<Scalars['Float']>;
  id: Scalars['String'];
  maximum?: Maybe<Scalars['Float']>;
  sortIndex: Scalars['Float'];
};

export type ChunkImportObject = {
  __typename?: 'ChunkImportObject';
  chunkIndex: Scalars['Float'];
  zeffyImport: ZeffyImportObject;
};

export type ChunkImportObject75mftgFj = {
  __typename?: 'ChunkImportObject75mftgFj';
  error?: Maybe<ChunkImportObject75mftgFjErrorObject>;
  object?: Maybe<ChunkImportObject>;
};

export type ChunkImportObject75mftgFjErrorObject = {
  __typename?: 'ChunkImportObject75mftgFjErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ClusterObject = {
  __typename?: 'ClusterObject';
  coordinates: CoordinatesObject;
  count: Scalars['Float'];
  cursorType: CursorType;
  displayedLatitude: Scalars['Float'];
  displayedLongitude: Scalars['Float'];
  id: Scalars['String'];
};

export type ClusterResponseObject = {
  __typename?: 'ClusterResponseObject';
  error?: Maybe<ClusterResponseObjectErrorObject>;
  items?: Maybe<Array<ClusterObject>>;
};

export type ClusterResponseObjectErrorObject = {
  __typename?: 'ClusterResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum CommandLang {
  EN = 'EN',
  ES = 'ES',
  FR = 'FR'
}

export type CommandObject = {
  __typename?: 'CommandObject';
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  contactId?: Maybe<Scalars['String']>;
  corporationName?: Maybe<Scalars['String']>;
  country?: Maybe<CountryCode>;
  createdAtUtc: Scalars['Date'];
  cs?: Maybe<Scalars['String']>;
  currency: AvailablePaymentCurrency;
  discount?: Maybe<DiscountObject>;
  discountAmount: Scalars['Int'];
  discountId?: Maybe<Scalars['String']>;
  donationId?: Maybe<Scalars['String']>;
  eTicketUrl?: Maybe<Scalars['String']>;
  eligibleAmount: Scalars['Int'];
  email?: Maybe<Scalars['String']>;
  extraDonation: Scalars['Int'];
  firstName?: Maybe<Scalars['String']>;
  formId: Scalars['String'];
  formLang: CommandLang;
  formType: FormType;
  id: Scalars['String'];
  isCorporate: Scalars['Boolean'];
  lastName?: Maybe<Scalars['String']>;
  locale: Locales;
  organization: OrganizationObject;
  organizationCountry: Scalars['String'];
  organizationId: Scalars['String'];
  paymentMethod?: Maybe<PaymentMethod>;
  postalCode?: Maybe<Scalars['String']>;
  productBids?: Maybe<Array<ProductBidObject>>;
  productDonation?: Maybe<ProductDonationObject>;
  productTickets?: Maybe<Array<ProductTicketObject>>;
  productsAmount: Scalars['Int'];
  region?: Maybe<Scalars['String']>;
  source: CommandSource;
  status: CommandStatus;
  tipAmount: Scalars['Int'];
  tipPercentage: Scalars['Int'];
  totalAmount: Scalars['Int'];
  transactions?: Maybe<Array<TransactionObject>>;
  user?: Maybe<UserObject>;
  userId?: Maybe<Scalars['String']>;
};

export type CommandObject6ou1b2r8 = {
  __typename?: 'CommandObject6ou1b2r8';
  error?: Maybe<CommandObject6ou1b2r8ErrorObject>;
  object?: Maybe<CommandObject>;
};

export type CommandObject6ou1b2r8ErrorObject = {
  __typename?: 'CommandObject6ou1b2r8ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type CommandObjectClGd5P08 = {
  __typename?: 'CommandObjectCLGd5P08';
  error?: Maybe<CommandObjectClGd5P08ErrorObject>;
  object?: Maybe<CommandObject>;
};

export type CommandObjectClGd5P08ErrorObject = {
  __typename?: 'CommandObjectCLGd5P08ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type CommandObjectEkF4Xpsa = {
  __typename?: 'CommandObjectEkF4XPSA';
  error?: Maybe<CommandObjectEkF4XpsaErrorObject>;
  object?: Maybe<CommandObject>;
};

export type CommandObjectEkF4XpsaErrorObject = {
  __typename?: 'CommandObjectEkF4XPSAErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type CommandObjectZmZvTwaj = {
  __typename?: 'CommandObjectZmZvTWAJ';
  error?: Maybe<CommandObjectZmZvTwajErrorObject>;
  object?: Maybe<CommandObject>;
};

export type CommandObjectZmZvTwajErrorObject = {
  __typename?: 'CommandObjectZmZvTWAJErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type CommandObjectgwD4jwCm = {
  __typename?: 'CommandObjectgwD4jwCM';
  error?: Maybe<CommandObjectgwD4jwCmErrorObject>;
  object?: Maybe<CommandObject>;
};

export type CommandObjectgwD4jwCmErrorObject = {
  __typename?: 'CommandObjectgwD4jwCMErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type CommandObjectpO11aS9w = {
  __typename?: 'CommandObjectpO11aS9w';
  error?: Maybe<CommandObjectpO11aS9wErrorObject>;
  items?: Maybe<Array<CommandObject>>;
};

export type CommandObjectpO11aS9wErrorObject = {
  __typename?: 'CommandObjectpO11aS9wErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type CommandResponseListObjectErrorObject = {
  __typename?: 'CommandResponseListObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type CommandResponseObject = {
  __typename?: 'CommandResponseObject';
  error?: Maybe<CommandResponseObjectErrorObject>;
  object?: Maybe<CommandObject>;
};

export type CommandResponseObjectErrorObject = {
  __typename?: 'CommandResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum CommandSource {
  FormSubmission = 'FormSubmission',
  Import = 'Import',
  ManualEntry = 'ManualEntry'
}

export enum CommandStatus {
  Archived = 'Archived',
  Complete = 'Complete',
  Expired = 'Expired',
  Open = 'Open',
  Submitted = 'Submitted'
}

export type CommandSubscriptionObject = {
  __typename?: 'CommandSubscriptionObject';
  id: Scalars['String'];
  recurrenceInterval: SubscriptionRecurrenceInterval;
  status?: Maybe<NewSubscriptionStatus>;
  stripeSubscriptionId: Scalars['String'];
};

export type CommandSubscriptionResponseObject = {
  __typename?: 'CommandSubscriptionResponseObject';
  error?: Maybe<CommandSubscriptionResponseObjectErrorObject>;
  object?: Maybe<CommandSubscriptionObject>;
};

export type CommandSubscriptionResponseObjectErrorObject = {
  __typename?: 'CommandSubscriptionResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum CompleteStripeAuthError {
  ImpossibleToCreateStripeAccount = 'ImpossibleToCreateStripeAccount'
}

export type CompleteStripeAuthResponseObjectErrorObject = {
  __typename?: 'CompleteStripeAuthResponseObjectErrorObject';
  code?: Maybe<CompleteStripeAuthError>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ConfirmPaymentIntentInput = {
  paymentIntentId: Scalars['String'];
};

export type ConfirmPaymentIntentObject = {
  __typename?: 'ConfirmPaymentIntentObject';
  nextAction?: Maybe<NextActionObject>;
};

export type ConfirmPaymentIntentObjectResponseObjectErrorObject = {
  __typename?: 'ConfirmPaymentIntentObjectResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ContactIdWithTagsObject = {
  __typename?: 'ContactIdWithTagsObject';
  id: Scalars['String'];
  tags: Array<TagObject>;
};

export type ContactIdWithTagsObjectRyquQmzt = {
  __typename?: 'ContactIdWithTagsObjectRyquQmzt';
  error?: Maybe<ContactIdWithTagsObjectRyquQmztErrorObject>;
  items?: Maybe<Array<ContactIdWithTagsObject>>;
};

export type ContactIdWithTagsObjectRyquQmztErrorObject = {
  __typename?: 'ContactIdWithTagsObjectRyquQmztErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ContactKeyInfoObject = {
  __typename?: 'ContactKeyInfoObject';
  contactSince?: Maybe<Scalars['Date']>;
  largestDonation?: Maybe<Scalars['Float']>;
  monthlyRecurringContribution?: Maybe<Scalars['Float']>;
  totalContribution?: Maybe<Scalars['Float']>;
  yearlyRecurringContribution?: Maybe<Scalars['Float']>;
};

export type ContactKeyInfoObjectsOr2JumH = {
  __typename?: 'ContactKeyInfoObjectsOr2JumH';
  error?: Maybe<ContactKeyInfoObjectsOr2JumHErrorObject>;
  object?: Maybe<ContactKeyInfoObject>;
};

export type ContactKeyInfoObjectsOr2JumHErrorObject = {
  __typename?: 'ContactKeyInfoObjectsOr2JumHErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ContactObject = {
  __typename?: 'ContactObject';
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  corporationName?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  donorType?: Maybe<DonorType>;
  firstName?: Maybe<Scalars['String']>;
  hasUnsubscribed?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  isSample?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationObject>;
  organizationId: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  unsubscribedEmailDeliveryId?: Maybe<Scalars['String']>;
  user?: Maybe<UserObject>;
  userId: Scalars['String'];
};

export type ContactObject2S9Cljis = {
  __typename?: 'ContactObject2S9Cljis';
  error?: Maybe<ContactObject2S9CljisErrorObject>;
  object?: Maybe<ContactObject>;
};

export type ContactObject2S9CljisErrorObject = {
  __typename?: 'ContactObject2S9CljisErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ContactObjectSuyyzfuQ = {
  __typename?: 'ContactObjectSuyyzfuQ';
  error?: Maybe<ContactObjectSuyyzfuQErrorObject>;
  items?: Maybe<Array<ContactObject>>;
};

export type ContactObjectSuyyzfuQErrorObject = {
  __typename?: 'ContactObjectSuyyzfuQErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ContactObjectjMQeShZ3 = {
  __typename?: 'ContactObjectjMQeShZ3';
  error?: Maybe<ContactObjectjMQeShZ3ErrorObject>;
  items?: Maybe<Array<ContactObject>>;
};

export type ContactObjectjMQeShZ3ErrorObject = {
  __typename?: 'ContactObjectjMQeShZ3ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ContactObjectyd52FnlF = {
  __typename?: 'ContactObjectyd52FnlF';
  error?: Maybe<ContactObjectyd52FnlFErrorObject>;
  object?: Maybe<ContactObject>;
};

export type ContactObjectyd52FnlFErrorObject = {
  __typename?: 'ContactObjectyd52FnlFErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ContactResponseObject = {
  __typename?: 'ContactResponseObject';
  error?: Maybe<ContactResponseObjectErrorObject>;
  object?: Maybe<ContactObject>;
};

export type ContactResponseObjectErrorObject = {
  __typename?: 'ContactResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type CoordinatesObject = {
  __typename?: 'CoordinatesObject';
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

export enum CountryCode {
  AD = 'AD',
  AE = 'AE',
  AF = 'AF',
  AG = 'AG',
  AI = 'AI',
  AL = 'AL',
  AM = 'AM',
  AO = 'AO',
  AQ = 'AQ',
  AR = 'AR',
  AS = 'AS',
  AT = 'AT',
  AU = 'AU',
  AW = 'AW',
  AX = 'AX',
  AZ = 'AZ',
  BA = 'BA',
  BB = 'BB',
  BD = 'BD',
  BE = 'BE',
  BF = 'BF',
  BG = 'BG',
  BH = 'BH',
  BI = 'BI',
  BJ = 'BJ',
  BL = 'BL',
  BM = 'BM',
  BN = 'BN',
  BO = 'BO',
  BQ = 'BQ',
  BR = 'BR',
  BS = 'BS',
  BT = 'BT',
  BV = 'BV',
  BW = 'BW',
  BY = 'BY',
  BZ = 'BZ',
  CA = 'CA',
  CC = 'CC',
  CD = 'CD',
  CF = 'CF',
  CG = 'CG',
  CH = 'CH',
  CI = 'CI',
  CK = 'CK',
  CL = 'CL',
  CM = 'CM',
  CN = 'CN',
  CO = 'CO',
  CR = 'CR',
  CU = 'CU',
  CV = 'CV',
  CW = 'CW',
  CX = 'CX',
  CY = 'CY',
  CZ = 'CZ',
  DE = 'DE',
  DJ = 'DJ',
  DK = 'DK',
  DM = 'DM',
  DO = 'DO',
  DZ = 'DZ',
  EC = 'EC',
  EE = 'EE',
  EG = 'EG',
  EH = 'EH',
  ER = 'ER',
  ES = 'ES',
  ET = 'ET',
  FI = 'FI',
  FJ = 'FJ',
  FK = 'FK',
  FM = 'FM',
  FO = 'FO',
  FR = 'FR',
  GA = 'GA',
  GB = 'GB',
  GD = 'GD',
  GE = 'GE',
  GF = 'GF',
  GG = 'GG',
  GH = 'GH',
  GI = 'GI',
  GL = 'GL',
  GM = 'GM',
  GN = 'GN',
  GP = 'GP',
  GQ = 'GQ',
  GR = 'GR',
  GS = 'GS',
  GT = 'GT',
  GU = 'GU',
  GW = 'GW',
  GY = 'GY',
  HK = 'HK',
  HM = 'HM',
  HN = 'HN',
  HR = 'HR',
  HT = 'HT',
  HU = 'HU',
  ID = 'ID',
  IE = 'IE',
  IL = 'IL',
  IM = 'IM',
  IN = 'IN',
  IO = 'IO',
  IQ = 'IQ',
  IR = 'IR',
  IS = 'IS',
  IT = 'IT',
  JE = 'JE',
  JM = 'JM',
  JO = 'JO',
  JP = 'JP',
  KE = 'KE',
  KG = 'KG',
  KH = 'KH',
  KI = 'KI',
  KM = 'KM',
  KN = 'KN',
  KP = 'KP',
  KR = 'KR',
  KW = 'KW',
  KY = 'KY',
  KZ = 'KZ',
  LA = 'LA',
  LB = 'LB',
  LC = 'LC',
  LI = 'LI',
  LK = 'LK',
  LR = 'LR',
  LS = 'LS',
  LT = 'LT',
  LU = 'LU',
  LV = 'LV',
  LY = 'LY',
  MA = 'MA',
  MC = 'MC',
  MD = 'MD',
  ME = 'ME',
  MF = 'MF',
  MG = 'MG',
  MH = 'MH',
  MK = 'MK',
  ML = 'ML',
  MM = 'MM',
  MN = 'MN',
  MO = 'MO',
  MP = 'MP',
  MQ = 'MQ',
  MR = 'MR',
  MS = 'MS',
  MT = 'MT',
  MU = 'MU',
  MV = 'MV',
  MW = 'MW',
  MX = 'MX',
  MY = 'MY',
  MZ = 'MZ',
  NA = 'NA',
  NC = 'NC',
  NE = 'NE',
  NF = 'NF',
  NG = 'NG',
  NI = 'NI',
  NL = 'NL',
  NO = 'NO',
  NP = 'NP',
  NR = 'NR',
  NU = 'NU',
  NZ = 'NZ',
  OM = 'OM',
  PA = 'PA',
  PE = 'PE',
  PF = 'PF',
  PG = 'PG',
  PH = 'PH',
  PK = 'PK',
  PL = 'PL',
  PM = 'PM',
  PN = 'PN',
  PR = 'PR',
  PS = 'PS',
  PT = 'PT',
  PW = 'PW',
  PY = 'PY',
  QA = 'QA',
  RE = 'RE',
  RO = 'RO',
  RS = 'RS',
  RU = 'RU',
  RW = 'RW',
  SA = 'SA',
  SB = 'SB',
  SC = 'SC',
  SD = 'SD',
  SE = 'SE',
  SG = 'SG',
  SH = 'SH',
  SI = 'SI',
  SJ = 'SJ',
  SK = 'SK',
  SL = 'SL',
  SM = 'SM',
  SN = 'SN',
  SO = 'SO',
  SR = 'SR',
  SS = 'SS',
  ST = 'ST',
  SV = 'SV',
  SX = 'SX',
  SY = 'SY',
  SZ = 'SZ',
  TC = 'TC',
  TD = 'TD',
  TF = 'TF',
  TG = 'TG',
  TH = 'TH',
  TJ = 'TJ',
  TK = 'TK',
  TL = 'TL',
  TM = 'TM',
  TN = 'TN',
  TO = 'TO',
  TR = 'TR',
  TT = 'TT',
  TV = 'TV',
  TW = 'TW',
  TZ = 'TZ',
  UA = 'UA',
  UG = 'UG',
  UM = 'UM',
  US = 'US',
  UY = 'UY',
  UZ = 'UZ',
  VA = 'VA',
  VC = 'VC',
  VE = 'VE',
  VG = 'VG',
  VI = 'VI',
  VN = 'VN',
  VU = 'VU',
  WF = 'WF',
  WS = 'WS',
  YE = 'YE',
  YT = 'YT',
  ZA = 'ZA',
  ZM = 'ZM',
  ZW = 'ZW'
}

export type CreateCardHolderInput = {
  address: Scalars['String'];
  agreedToStripeCardIssuingTermsAt: Scalars['Date'];
  city: Scalars['String'];
  countryCode: Scalars['String'];
  dateOfBirth: DateOfBirthInput;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber?: InputMaybe<Scalars['String']>;
  postalCode: Scalars['String'];
  region?: InputMaybe<Scalars['String']>;
};

export type CreateDonationAmountInput = {
  amount?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['String']>;
  isRecurrent?: InputMaybe<Scalars['Boolean']>;
  message?: InputMaybe<Scalars['String']>;
  recurrenceInterval?: InputMaybe<ProductDonationRecurrenceInterval>;
  sortIndex?: InputMaybe<Scalars['Float']>;
};

export type CreateDonationFormFieldInput = {
  chequeDescription?: InputMaybe<Scalars['String']>;
  createdAtUtc?: InputMaybe<Scalars['Date']>;
  description?: InputMaybe<Scalars['String']>;
  donationFormAmounts: Array<CreateDonationAmountInput>;
  emailAttachmentUrl?: InputMaybe<Scalars['String']>;
  emailContent?: InputMaybe<Scalars['String']>;
  emailObject?: InputMaybe<Scalars['String']>;
  fundraiserEmailBody?: InputMaybe<Scalars['String']>;
  fundraisingRegistrationWording?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Locales>;
  postDonationUrl?: InputMaybe<Scalars['String']>;
  sanitizedDescription?: InputMaybe<Scalars['String']>;
  ticketingRegistrationWording?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type CreateDraftDonationFormInput = {
  allowCheque?: InputMaybe<Scalars['Boolean']>;
  allowFundraiserCreation?: InputMaybe<Scalars['Boolean']>;
  allowInHonour?: InputMaybe<Scalars['Boolean']>;
  allowTeamCreation?: InputMaybe<Scalars['Boolean']>;
  askForAddress?: InputMaybe<Scalars['Boolean']>;
  banner?: InputMaybe<Scalars['String']>;
  bannerUrl?: InputMaybe<Scalars['String']>;
  bannerVideoUrl?: InputMaybe<Scalars['String']>;
  category: DonationFormCategory;
  color: Scalars['String'];
  createdAtUtc?: InputMaybe<Scalars['Date']>;
  displayAddressQuestion?: InputMaybe<Scalars['Boolean']>;
  donationFormFields: Array<CreateDonationFormFieldInput>;
  duplicateLogo?: InputMaybe<Scalars['Boolean']>;
  encourageMonthlyDonations?: InputMaybe<Scalars['Boolean']>;
  expectedAmountSize?: InputMaybe<ExpectedAmountSize>;
  hasReceipt?: InputMaybe<Scalars['Boolean']>;
  hideFundraiseButton?: InputMaybe<Scalars['Boolean']>;
  highlight?: InputMaybe<Scalars['Boolean']>;
  isAmountsAlreadySavedInCreation?: InputMaybe<Scalars['Boolean']>;
  isOnboardingForm?: Scalars['Boolean'];
  isPrimaryCampaign?: InputMaybe<Scalars['Boolean']>;
  isPrimaryTeam?: InputMaybe<Scalars['Boolean']>;
  linkedTicketingInput?: InputMaybe<CreateLinkedTicketingInput>;
  liveFormEditorStep?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Locales>;
  logo?: InputMaybe<Scalars['String']>;
  notificationEmails?: InputMaybe<Scalars['NotificationEmailsScalar']>;
  organizationId?: InputMaybe<Scalars['String']>;
  path?: InputMaybe<Scalars['String']>;
  questions?: InputMaybe<Array<QuestionInput>>;
  questionsToDelete?: InputMaybe<Array<Scalars['String']>>;
  redirectUrl?: InputMaybe<Scalars['String']>;
  registrationCommandId?: InputMaybe<Scalars['String']>;
  registrationIncreaseThermometer?: InputMaybe<Scalars['Boolean']>;
  registrationOrderId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<FormStatus>;
  stripeProductId?: InputMaybe<Scalars['String']>;
  target?: InputMaybe<Scalars['Float']>;
  teamId?: InputMaybe<Scalars['String']>;
  ticketingId?: InputMaybe<Scalars['String']>;
  volume?: InputMaybe<Scalars['Float']>;
};

export type CreateFrontendOrderResponseObjectErrorObject = {
  __typename?: 'CreateFrontendOrderResponseObjectErrorObject';
  code?: Maybe<CreateOrderResponseError>;
  data?: Maybe<CreateOrderErrorDataObject>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type CreateIssuingTopupInput = {
  amount: Scalars['Int'];
  plaidAccountId?: InputMaybe<Scalars['String']>;
  plaidPublicToken?: InputMaybe<Scalars['String']>;
};

export type CreateLinkedTicketingInput = {
  questionsPerParticipant?: InputMaybe<Array<QuestionInput>>;
  rates: Array<CreateRateInput>;
};

export type CreateMobileCommandInput = {
  discountId?: InputMaybe<Scalars['String']>;
  eligibleAmount: Scalars['Float'];
  extraDonation: Scalars['Float'];
  formId: Scalars['String'];
  locale: Locales;
  paymentIntentId: Scalars['String'];
  productDonation?: InputMaybe<CreateMobileProductDonationInput>;
  productTickets?: InputMaybe<Array<CreateMobileTicketInput>>;
  productsAmount: Scalars['Int'];
  tipAmount: Scalars['Float'];
  totalAmount: Scalars['Float'];
};

export type CreateMobileCommandObject = {
  __typename?: 'CreateMobileCommandObject';
  id: Scalars['String'];
};

export type CreateMobileCommandResponseObject = {
  __typename?: 'CreateMobileCommandResponseObject';
  error?: Maybe<CreateMobileCommandResponseObjectErrorObject>;
  object?: Maybe<CreateMobileCommandObject>;
};

export type CreateMobileCommandResponseObjectErrorObject = {
  __typename?: 'CreateMobileCommandResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type CreateMobileProductDonationInput = {
  amount?: InputMaybe<Scalars['Int']>;
  inHonourEmailAddress?: InputMaybe<Scalars['String']>;
  inHonourEmailBody?: InputMaybe<Scalars['String']>;
  inHonourName?: InputMaybe<Scalars['String']>;
  isAnonymous: Scalars['Boolean'];
  isInHonour: Scalars['Boolean'];
  recurrenceInterval: ProductDonationRecurrenceInterval;
  sendInHonourEmail: Scalars['Boolean'];
  thankYouComment?: InputMaybe<Scalars['String']>;
};

export type CreateMobileTicketInput = {
  answers?: InputMaybe<Array<AnswerInput>>;
  automaticRenewal: Scalars['Boolean'];
  quantity: Scalars['Int'];
  rateId: Scalars['String'];
};

export type CreateNewOrganizationInput = {
  country: OrganizationCountry;
  organizationName: Scalars['String'];
  referralQuestion?: InputMaybe<ReferralQuestionChoice>;
  referralQuestionOther: Scalars['String'];
  websiteUrl?: InputMaybe<Scalars['String']>;
};

export type CreateOrderErrorDataObject = {
  __typename?: 'CreateOrderErrorDataObject';
  expiredEarlyBirdRates?: Maybe<Array<ExpiredEarlyBirdErrorObject>>;
  fullChoices?: Maybe<Array<FullChoiceErrorObject>>;
  fullRates?: Maybe<Array<FullRateErrorObject>>;
  remainingTicketingSeats?: Maybe<Scalars['Float']>;
};

export type CreateOrderInput = {
  address?: InputMaybe<Scalars['String']>;
  amount: Scalars['Float'];
  answers: Array<AnswerInput>;
  city?: InputMaybe<Scalars['String']>;
  commandId?: InputMaybe<Scalars['String']>;
  companyName?: InputMaybe<Scalars['String']>;
  country: Scalars['String'];
  discountId?: InputMaybe<Scalars['String']>;
  eligibleAmount: Scalars['Float'];
  email?: InputMaybe<Scalars['String']>;
  extraDonation: Scalars['Float'];
  firstName?: InputMaybe<Scalars['String']>;
  isTapToPay?: InputMaybe<Scalars['Boolean']>;
  isTipMessageStayFree?: InputMaybe<Scalars['Boolean']>;
  lastName?: InputMaybe<Scalars['String']>;
  locale: Locales;
  occurrenceId?: InputMaybe<Scalars['String']>;
  organizationId: Scalars['String'];
  paymentIntentId: Scalars['String'];
  postalCode?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
  stripeSubscriptionId?: InputMaybe<Scalars['String']>;
  tip: Scalars['Float'];
  userId?: InputMaybe<Scalars['String']>;
};

export enum CreateOrderResponseError {
  ChoiceIsFull = 'ChoiceIsFull',
  ExpiredEarlyBirdRate = 'ExpiredEarlyBirdRate',
  NoTicketNorExtraDonation = 'NoTicketNorExtraDonation',
  NoTicketSelected = 'NoTicketSelected',
  OverpassedTicketingSeats = 'OverpassedTicketingSeats',
  RateIsFull = 'RateIsFull',
  ShouldSpecifyATicketingOccurrence = 'ShouldSpecifyATicketingOccurrence',
  TicketingIsClosed = 'TicketingIsClosed',
  TicketingIsFull = 'TicketingIsFull',
  TicketingOccurrenceNotFound = 'TicketingOccurrenceNotFound',
  UnknownError = 'UnknownError'
}

export type CreateOrderResponseObject = {
  __typename?: 'CreateOrderResponseObject';
  error?: Maybe<CreateOrderResponseError>;
  expiredEarlyBirdRates?: Maybe<Array<ExpiredEarlyBirdErrorObject>>;
  fullChoices?: Maybe<Array<FullChoiceErrorObject>>;
  fullRates?: Maybe<Array<FullRateErrorObject>>;
  order?: Maybe<OrderObject>;
  remainingTicketingSeats?: Maybe<Scalars['Float']>;
};

export type CreateRateFieldInput = {
  description?: InputMaybe<Scalars['String']>;
  locale: Locales;
  title: Scalars['String'];
};

export type CreateRateInput = {
  amount: Scalars['Float'];
  attendeesByTicket?: InputMaybe<Scalars['Float']>;
  bidIncrement?: InputMaybe<Scalars['Float']>;
  earlyBirdCloseDate?: InputMaybe<Scalars['Date']>;
  earlyBirdCloseDateTimezone?: InputMaybe<Scalars['String']>;
  eligibleAmount?: InputMaybe<Scalars['Float']>;
  id: Scalars['String'];
  maximumToBuy: Scalars['Float'];
  membershipValidityPeriod?: InputMaybe<MembershipValidityPeriod>;
  membershipValidityPeriodAtDate?: InputMaybe<Scalars['Date']>;
  minimumToBuy?: InputMaybe<Scalars['Float']>;
  photoUrls?: InputMaybe<Array<Scalars['String']>>;
  photos?: InputMaybe<Array<Scalars['String']>>;
  rateFields?: InputMaybe<Array<CreateRateFieldInput>>;
  seats?: InputMaybe<Scalars['Float']>;
  sortIndex: Scalars['Float'];
};

export type CreateReceiptInput = {
  address?: InputMaybe<Scalars['String']>;
  advantageAmount?: InputMaybe<Scalars['Float']>;
  advantageDescription?: InputMaybe<Scalars['String']>;
  amount?: InputMaybe<Scalars['Float']>;
  annotation?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  commandId?: InputMaybe<Scalars['String']>;
  companyName?: InputMaybe<Scalars['String']>;
  contactId?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  createdAtUtc?: InputMaybe<Scalars['Date']>;
  donationDate?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  fullName?: InputMaybe<Scalars['String']>;
  isImported?: InputMaybe<Scalars['Boolean']>;
  isSample?: InputMaybe<Scalars['Boolean']>;
  issuingAtUtc?: InputMaybe<Scalars['Date']>;
  lastName?: InputMaybe<Scalars['String']>;
  locale: Locales;
  postalCode?: InputMaybe<Scalars['String']>;
  receiptNumber?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<ReceiptStatus>;
  userId?: InputMaybe<Scalars['String']>;
};

export type CreateTagInput = {
  userId?: InputMaybe<Scalars['String']>;
  value: Scalars['String'];
};

export type CreateTicketInput = {
  answers?: InputMaybe<Array<AnswerInput>>;
  automaticRenewal?: InputMaybe<Scalars['Boolean']>;
  rateId: Scalars['String'];
};

export type CreateTicketingFieldInput = {
  bidLoserEmailContent?: InputMaybe<Scalars['String']>;
  bidLoserEmailObject?: InputMaybe<Scalars['String']>;
  description: Scalars['String'];
  emailAttachmentUrl?: InputMaybe<Scalars['String']>;
  emailContent: Scalars['String'];
  emailObject: Scalars['String'];
  locale: Locales;
  offlinePaymentWording?: InputMaybe<Scalars['String']>;
  postEventBody?: InputMaybe<Scalars['String']>;
  postEventSubject?: InputMaybe<Scalars['String']>;
  postTransactionUrl?: InputMaybe<Scalars['String']>;
  reminderBody?: InputMaybe<Scalars['String']>;
  reminderSubject?: InputMaybe<Scalars['String']>;
  sanitizedDescription?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};

export type CreateTicketingInput = {
  address?: InputMaybe<Scalars['String']>;
  allowCheque: Scalars['Boolean'];
  askForAddress: Scalars['Boolean'];
  banner?: InputMaybe<Scalars['String']>;
  bannerPdf?: InputMaybe<Scalars['String']>;
  bannerVideoUrl?: InputMaybe<Scalars['String']>;
  color: Scalars['String'];
  duplicateLogo?: InputMaybe<Scalars['Boolean']>;
  duplicatedFromId?: InputMaybe<Scalars['String']>;
  eventTimezone?: InputMaybe<Scalars['String']>;
  extraDonation: Scalars['Boolean'];
  formCategory: TicketingFormCategory;
  generateQrCode: Scalars['Boolean'];
  id: Scalars['String'];
  isOnboardingForm?: InputMaybe<Scalars['Boolean']>;
  liveFormEditorStep?: InputMaybe<Scalars['String']>;
  locale: Locales;
  logo?: InputMaybe<Scalars['String']>;
  notificationEmails?: InputMaybe<Scalars['NotificationEmailsScalar']>;
  questionsPerOrder: Array<QuestionInput>;
  questionsPerParticipant: Array<QuestionInput>;
  rates: Array<CreateRateInput>;
  recurrences?: InputMaybe<Array<TicketingRecurrenceInput>>;
  reminderSendDateOffset?: InputMaybe<Scalars['Float']>;
  seats?: InputMaybe<Scalars['Float']>;
  status?: InputMaybe<FormStatus>;
  ticketingFields: Array<CreateTicketingFieldInput>;
};

export type CreatedCampaignsObject = {
  __typename?: 'CreatedCampaignsObject';
  individualCampaigns?: Maybe<Array<FundraiserDonationFormObject>>;
  team?: Maybe<FundraiserDonationFormObject>;
};

export type CreatedIssuingPayoutInput = {
  amount: Scalars['Float'];
};

export type CreatedIssuingPayoutObject = {
  __typename?: 'CreatedIssuingPayoutObject';
  amount: Scalars['Float'];
  id: Scalars['String'];
};

export type CreatedIssuingPayoutObjectKprQZf4Q = {
  __typename?: 'CreatedIssuingPayoutObjectKprQZf4Q';
  error?: Maybe<CreatedIssuingPayoutObjectKprQZf4QErrorObject>;
  object?: Maybe<CreatedIssuingPayoutObject>;
};

export type CreatedIssuingPayoutObjectKprQZf4QErrorObject = {
  __typename?: 'CreatedIssuingPayoutObjectKprQZf4QErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum CronQueues {
  AuctionsPaymentProcessor = 'AuctionsPaymentProcessor',
  CloseAuctions = 'CloseAuctions',
  GenerateBankStatement = 'GenerateBankStatement',
  GenerateSitemap = 'GenerateSitemap',
  HubspotNurturingDealCreation = 'HubspotNurturingDealCreation',
  InvitationReminder = 'InvitationReminder',
  MarkStaleTransactionsAsFailed = 'MarkStaleTransactionsAsFailed',
  MembershipReminders = 'MembershipReminders',
  NotifyActiveOrganizationsWithoutBankInfo = 'NotifyActiveOrganizationsWithoutBankInfo',
  ProcessBalanceTransfersToIssuing = 'ProcessBalanceTransfersToIssuing',
  ProcessIssuingRewards = 'ProcessIssuingRewards',
  ProcessReferralTransfers = 'ProcessReferralTransfers',
  ProcessScheduledPayouts = 'ProcessScheduledPayouts',
  Reminders = 'Reminders',
  RetryFailedTransfer = 'RetryFailedTransfer',
  SendDailyVisitorReport = 'SendDailyVisitorReport',
  SendEventManagementEmails = 'SendEventManagementEmails',
  SendMonthlyBalanceTransactionsExport = 'SendMonthlyBalanceTransactionsExport',
  SendMonthlyChurnedReport = 'SendMonthlyChurnedReport',
  SendPostEventEmailDeliveries = 'SendPostEventEmailDeliveries',
  SendScheduledEmailDeliveries = 'SendScheduledEmailDeliveries',
  SendSuggestDesktopCompletionEmail = 'SendSuggestDesktopCompletionEmail',
  SendWeeklyChurnedReport = 'SendWeeklyChurnedReport',
  TriggerAwaitingWithdrawalRefunds = 'TriggerAwaitingWithdrawalRefunds',
  UpdateFraudulentTransaction = 'UpdateFraudulentTransaction',
  UpdateMissingIrsInfo = 'UpdateMissingIrsInfo'
}

export type CurrentlyActiveOrganizationObject = {
  __typename?: 'CurrentlyActiveOrganizationObject';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type CurrentlyActiveOrganizationResponseListObject = {
  __typename?: 'CurrentlyActiveOrganizationResponseListObject';
  error?: Maybe<OrganizationResponseListObjectErrorObject>;
  items?: Maybe<Array<CurrentlyActiveOrganizationObject>>;
};

export enum CursorType {
  Organization = 'Organization',
  Ticketing = 'Ticketing'
}

export type DashboardCommandFormListResponse = {
  __typename?: 'DashboardCommandFormListResponse';
  error?: Maybe<DashboardCommandFormListResponseErrorObject>;
  items?: Maybe<Array<FormObject>>;
};

export type DashboardCommandFormListResponseErrorObject = {
  __typename?: 'DashboardCommandFormListResponseErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DashboardCommandRateObject = {
  __typename?: 'DashboardCommandRateObject';
  quantity: Scalars['Int'];
  rateId: Scalars['String'];
  seats?: Maybe<Scalars['Int']>;
  title: Scalars['String'];
};

export type DashboardCommandRatesListResponseObject = {
  __typename?: 'DashboardCommandRatesListResponseObject';
  error?: Maybe<DashboardCommandRatesListResponseObjectErrorObject>;
  items?: Maybe<Array<DashboardCommandRatesObject>>;
};

export type DashboardCommandRatesListResponseObjectErrorObject = {
  __typename?: 'DashboardCommandRatesListResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DashboardCommandRatesObject = {
  __typename?: 'DashboardCommandRatesObject';
  commandId: Scalars['String'];
  rates: Array<DashboardCommandRateObject>;
};

export type DashboardCountObject = {
  __typename?: 'DashboardCountObject';
  count: Scalars['Float'];
};

export type DashboardCountObjectW4qidmYz = {
  __typename?: 'DashboardCountObjectW4qidmYZ';
  error?: Maybe<DashboardCountObjectW4qidmYzErrorObject>;
  object?: Maybe<DashboardCountObject>;
};

export type DashboardCountObjectW4qidmYzErrorObject = {
  __typename?: 'DashboardCountObjectW4qidmYZErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DashboardCountResponseObjectErrorObject = {
  __typename?: 'DashboardCountResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum DashboardPaymentStatus {
  DisputeLost = 'DisputeLost',
  Disputed = 'Disputed',
  Failed = 'Failed',
  Incomplete = 'Incomplete',
  Monthly = 'Monthly',
  OnHold = 'OnHold',
  PastDue = 'PastDue',
  Processing = 'Processing',
  Stopped = 'Stopped',
  Succeeded = 'Succeeded',
  Unprocessed = 'Unprocessed',
  Yearly = 'Yearly'
}

export type DashboardTransactionListResponseObjectErrorObject = {
  __typename?: 'DashboardTransactionListResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DashboardTransactionObject = {
  __typename?: 'DashboardTransactionObject';
  commandId?: Maybe<Scalars['String']>;
  contactId?: Maybe<Scalars['String']>;
  corporationName?: Maybe<Scalars['String']>;
  createdAtUtc: Scalars['Date'];
  currency: AvailablePaymentCurrency;
  displayStatus: DashboardPaymentStatus;
  email?: Maybe<Scalars['String']>;
  eventEndAt?: Maybe<Scalars['Date']>;
  eventStartAt?: Maybe<Scalars['Date']>;
  extraDonation?: Maybe<Scalars['Int']>;
  firstName?: Maybe<Scalars['String']>;
  formId: Scalars['String'];
  formType: FormType;
  hasBeenThanked: Scalars['Boolean'];
  hasRadarWarning?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  inProgressRefundAmount: Scalars['Int'];
  isAnonymous?: Maybe<Scalars['Boolean']>;
  isNewRecurringDonation?: Maybe<Scalars['Boolean']>;
  isSample?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  nextRenewalAt?: Maybe<Scalars['Date']>;
  paymentMethod?: Maybe<PaymentMethod>;
  payoutDate?: Maybe<Scalars['Date']>;
  receiptId?: Maybe<Scalars['String']>;
  recurrenceInterval?: Maybe<SubscriptionRecurrenceInterval>;
  refundCreatedAtUtc?: Maybe<Scalars['Date']>;
  refundableAmount?: Maybe<Scalars['Int']>;
  refundableTipAmount?: Maybe<Scalars['Int']>;
  refundedAmount: Scalars['Int'];
  refundedTipAmount?: Maybe<Scalars['Int']>;
  status: PaymentStatus;
  stoppedAt?: Maybe<Scalars['Date']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionStatus?: Maybe<NewSubscriptionStatus>;
  thankYouComment?: Maybe<Scalars['String']>;
  transferrableAmount: Scalars['Int'];
  userId?: Maybe<Scalars['String']>;
};

export type DashboardTransactionObjectOaqywskA = {
  __typename?: 'DashboardTransactionObjectOaqywskA';
  error?: Maybe<DashboardTransactionObjectOaqywskAErrorObject>;
  object?: Maybe<DashboardTransactionObject>;
};

export type DashboardTransactionObjectOaqywskAErrorObject = {
  __typename?: 'DashboardTransactionObjectOaqywskAErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DashboardTransactionObjectXrrAglI3 = {
  __typename?: 'DashboardTransactionObjectXrrAglI3';
  error?: Maybe<DashboardTransactionObjectXrrAglI3ErrorObject>;
  object?: Maybe<DashboardTransactionObject>;
};

export type DashboardTransactionObjectXrrAglI3ErrorObject = {
  __typename?: 'DashboardTransactionObjectXrrAglI3ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DateOfBirthInput = {
  day: Scalars['Float'];
  month: Scalars['Float'];
  year: Scalars['Float'];
};

export type DeactivateAccountInput = {
  reason: Scalars['String'];
};

export type DeactiveTwoFactorAuthInput = {
  password: Scalars['String'];
};

export type DeleteUserAccountInput = {
  reason: Scalars['String'];
};

export type DiscountObject = {
  __typename?: 'DiscountObject';
  amount: Scalars['Float'];
  body: Scalars['String'];
  id: Scalars['String'];
  limit?: Maybe<Scalars['Float']>;
  remainingUses?: Maybe<Scalars['Float']>;
  sortIndex: Scalars['Float'];
  ticketingId: Scalars['String'];
  type: DiscountType;
};

export type DiscountResponseObject = {
  __typename?: 'DiscountResponseObject';
  error?: Maybe<DiscountResponseObjectErrorObject>;
  object?: Maybe<DiscountObject>;
};

export type DiscountResponseObjectErrorObject = {
  __typename?: 'DiscountResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum DiscountType {
  Dollars = 'Dollars',
  Percentage = 'Percentage'
}

export type DonationFormAmountInput = {
  amount: Scalars['Float'];
  id: Scalars['String'];
  isRecurrent: Scalars['Boolean'];
  message?: InputMaybe<Scalars['String']>;
  recurrenceInterval: ProductDonationRecurrenceInterval;
  sortIndex: Scalars['Float'];
};

export type DonationFormAmountObject = {
  __typename?: 'DonationFormAmountObject';
  amount: Scalars['Float'];
  id: Scalars['String'];
  isRecurrent: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  recurrenceInterval: ProductDonationRecurrenceInterval;
  sortIndex: Scalars['Float'];
};

export type DonationFormCampaignFilterInput = {
  limit?: InputMaybe<Scalars['Int']>;
  page: Scalars['Int'];
  search: Scalars['String'];
};

export enum DonationFormCategory {
  DonationCampaign = 'DonationCampaign',
  DonationForm = 'DonationForm',
  PeerToPeerCampaign = 'PeerToPeerCampaign'
}

export type DonationFormFieldFromCampaignObject = {
  __typename?: 'DonationFormFieldFromCampaignObject';
  id: Scalars['String'];
  locale: Locales;
  title: Scalars['String'];
};

export type DonationFormFieldObject = {
  __typename?: 'DonationFormFieldObject';
  chequeDescription?: Maybe<Scalars['String']>;
  createdAtUtc: Scalars['Date'];
  description: Scalars['String'];
  donationFormAmounts?: Maybe<Array<DonationFormAmountObject>>;
  emailAttachmentUrl?: Maybe<Scalars['String']>;
  emailContent?: Maybe<Scalars['String']>;
  emailObject?: Maybe<Scalars['String']>;
  fundraiserEmailBody?: Maybe<Scalars['String']>;
  fundraisingRegistrationWording?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  locale: Locales;
  postDonationUrl?: Maybe<Scalars['String']>;
  sanitizedDescription?: Maybe<Scalars['String']>;
  ticketingRegistrationWording?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type DonationFormFromCampaignObject = {
  __typename?: 'DonationFormFromCampaignObject';
  campaignId?: Maybe<Scalars['String']>;
  category: DonationFormCategory;
  color: Scalars['String'];
  donationFormFields?: Maybe<Array<DonationFormFieldFromCampaignObject>>;
  id: Scalars['String'];
  isPrimaryCampaign: Scalars['Boolean'];
  isPrimaryTeam: Scalars['Boolean'];
  logoUrl?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  target?: Maybe<Scalars['Float']>;
  teamId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  volume?: Maybe<Scalars['Float']>;
};

export type DonationFormObject = {
  __typename?: 'DonationFormObject';
  allowCheque: Scalars['Boolean'];
  allowFundraiserCreation?: Maybe<Scalars['Boolean']>;
  allowInHonour: Scalars['Boolean'];
  allowTeamCreation?: Maybe<Scalars['Boolean']>;
  askForAddress: Scalars['Boolean'];
  bannerUrl?: Maybe<Scalars['String']>;
  bannerVideoUrl?: Maybe<Scalars['String']>;
  campaign?: Maybe<DonationFormObject>;
  campaignId?: Maybe<Scalars['String']>;
  category: DonationFormCategory;
  color: Scalars['String'];
  contact?: Maybe<ContactObject>;
  createdAtUtc: Scalars['Date'];
  displayAddressQuestion?: Maybe<Scalars['Boolean']>;
  donationFormFields?: Maybe<Array<DonationFormFieldObject>>;
  donations?: Maybe<Array<DonationObject>>;
  encourageMonthlyDonations: Scalars['Boolean'];
  expectedAmountSize?: Maybe<ExpectedAmountSize>;
  featureFlagTreatments?: Maybe<Array<FeatureFlagTreatmentObject>>;
  formVolume?: Maybe<Scalars['Float']>;
  hasReceipt: Scalars['Boolean'];
  hideFundraiseButton: Scalars['Boolean'];
  highlight: Scalars['Boolean'];
  id: Scalars['String'];
  isAmountsAlreadySavedInCreation?: Maybe<Scalars['Boolean']>;
  isArchived: Scalars['Boolean'];
  isPrimaryCampaign: Scalars['Boolean'];
  isPrimaryTeam: Scalars['Boolean'];
  liveFormEditorStep?: Maybe<Scalars['String']>;
  locale: Locales;
  logoUrl?: Maybe<Scalars['String']>;
  missingTranslations: Scalars['Boolean'];
  notificationEmails?: Maybe<Scalars['NotificationEmailsScalar']>;
  organization?: Maybe<OrganizationObject>;
  organizationId?: Maybe<Scalars['String']>;
  path: Scalars['String'];
  questions?: Maybe<Array<QuestionObject>>;
  recurrence: DonationFormRecurrence;
  redirectUrl?: Maybe<Scalars['String']>;
  registrationCommandId?: Maybe<Scalars['String']>;
  registrationIncreaseThermometer: Scalars['Boolean'];
  registrationOrderId?: Maybe<Scalars['String']>;
  status?: Maybe<FormStatus>;
  stripeProductId?: Maybe<Scalars['String']>;
  target?: Maybe<Scalars['Float']>;
  team?: Maybe<DonationFormObject>;
  teamId?: Maybe<Scalars['String']>;
  ticketing?: Maybe<TicketingObject>;
  ticketingId?: Maybe<Scalars['String']>;
  user?: Maybe<UserObject>;
  volume?: Maybe<Scalars['Float']>;
};

export type DonationFormObject1vaoV6iC = {
  __typename?: 'DonationFormObject1vaoV6iC';
  error?: Maybe<DonationFormObject1vaoV6iCErrorObject>;
  object?: Maybe<DonationFormObject>;
};

export type DonationFormObject1vaoV6iCErrorObject = {
  __typename?: 'DonationFormObject1vaoV6iCErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DonationFormObject2nIYlt89 = {
  __typename?: 'DonationFormObject2nIYlt89';
  error?: Maybe<DonationFormObject2nIYlt89ErrorObject>;
  object?: Maybe<DonationFormObject>;
};

export type DonationFormObject2nIYlt89ErrorObject = {
  __typename?: 'DonationFormObject2nIYlt89ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DonationFormObject7FVwVpfe = {
  __typename?: 'DonationFormObject7FVwVPFE';
  error?: Maybe<DonationFormObject7FVwVpfeErrorObject>;
  object?: Maybe<DonationFormObject>;
};

export type DonationFormObject7FVwVpfeErrorObject = {
  __typename?: 'DonationFormObject7FVwVPFEErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DonationFormObject8wSj1y7m = {
  __typename?: 'DonationFormObject8wSj1y7m';
  error?: Maybe<DonationFormObject8wSj1y7mErrorObject>;
  items?: Maybe<Array<DonationFormObject>>;
};

export type DonationFormObject8wSj1y7mErrorObject = {
  __typename?: 'DonationFormObject8wSj1y7mErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DonationFormObject68kkMiJh = {
  __typename?: 'DonationFormObject68kkMiJh';
  error?: Maybe<DonationFormObject68kkMiJhErrorObject>;
  object?: Maybe<DonationFormObject>;
};

export type DonationFormObject68kkMiJhErrorObject = {
  __typename?: 'DonationFormObject68kkMiJhErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DonationFormObjectFYySa2Hc = {
  __typename?: 'DonationFormObjectFYySA2HC';
  error?: Maybe<DonationFormObjectFYySa2HcErrorObject>;
  items?: Maybe<Array<DonationFormObject>>;
};

export type DonationFormObjectFYySa2HcErrorObject = {
  __typename?: 'DonationFormObjectFYySA2HCErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DonationFormObjectHvmeo63P = {
  __typename?: 'DonationFormObjectHVMEO63P';
  error?: Maybe<DonationFormObjectHvmeo63PErrorObject>;
  object?: Maybe<DonationFormObject>;
};

export type DonationFormObjectHvmeo63PErrorObject = {
  __typename?: 'DonationFormObjectHVMEO63PErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DonationFormObjectKpndQcPt = {
  __typename?: 'DonationFormObjectKPNDQcPT';
  error?: Maybe<DonationFormObjectKpndQcPtErrorObject>;
  object?: Maybe<DonationFormObject>;
};

export type DonationFormObjectKpndQcPtErrorObject = {
  __typename?: 'DonationFormObjectKPNDQcPTErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DonationFormObjectT8zdfs9n = {
  __typename?: 'DonationFormObjectT8zdfs9n';
  error?: Maybe<DonationFormObjectT8zdfs9nErrorObject>;
  object?: Maybe<DonationFormObject>;
};

export type DonationFormObjectT8zdfs9nErrorObject = {
  __typename?: 'DonationFormObjectT8zdfs9nErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DonationFormObjectWUjIRr53 = {
  __typename?: 'DonationFormObjectWUjIRr53';
  error?: Maybe<DonationFormObjectWUjIRr53ErrorObject>;
  object?: Maybe<DonationFormObject>;
};

export type DonationFormObjectWUjIRr53ErrorObject = {
  __typename?: 'DonationFormObjectWUjIRr53ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DonationFormObjectZcd8CkSz = {
  __typename?: 'DonationFormObjectZcd8CkSZ';
  error?: Maybe<DonationFormObjectZcd8CkSzErrorObject>;
  object?: Maybe<DonationFormObject>;
};

export type DonationFormObjectZcd8CkSzErrorObject = {
  __typename?: 'DonationFormObjectZcd8CkSZErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DonationFormObjectZjEx8C8j = {
  __typename?: 'DonationFormObjectZjEx8C8j';
  error?: Maybe<DonationFormObjectZjEx8C8jErrorObject>;
  object?: Maybe<DonationFormObject>;
};

export type DonationFormObjectZjEx8C8jErrorObject = {
  __typename?: 'DonationFormObjectZjEx8C8jErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DonationFormObjectdXGd0uE5 = {
  __typename?: 'DonationFormObjectdXGd0uE5';
  error?: Maybe<DonationFormObjectdXGd0uE5ErrorObject>;
  object?: Maybe<DonationFormObject>;
};

export type DonationFormObjectdXGd0uE5ErrorObject = {
  __typename?: 'DonationFormObjectdXGd0uE5ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DonationFormObjectkoQDopSo = {
  __typename?: 'DonationFormObjectkoQDopSo';
  error?: Maybe<DonationFormObjectkoQDopSoErrorObject>;
  object?: Maybe<DonationFormObject>;
};

export type DonationFormObjectkoQDopSoErrorObject = {
  __typename?: 'DonationFormObjectkoQDopSoErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DonationFormObjectlR4bxdIr = {
  __typename?: 'DonationFormObjectlR4bxdIr';
  error?: Maybe<DonationFormObjectlR4bxdIrErrorObject>;
  object?: Maybe<DonationFormObject>;
};

export type DonationFormObjectlR4bxdIrErrorObject = {
  __typename?: 'DonationFormObjectlR4bxdIrErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DonationFormObjectnszkstDa = {
  __typename?: 'DonationFormObjectnszkstDA';
  error?: Maybe<DonationFormObjectnszkstDaErrorObject>;
  object?: Maybe<DonationFormObject>;
};

export type DonationFormObjectnszkstDaErrorObject = {
  __typename?: 'DonationFormObjectnszkstDAErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DonationFormObjectrVwsSbQx = {
  __typename?: 'DonationFormObjectrVwsSbQx';
  error?: Maybe<DonationFormObjectrVwsSbQxErrorObject>;
  object?: Maybe<DonationFormObject>;
};

export type DonationFormObjectrVwsSbQxErrorObject = {
  __typename?: 'DonationFormObjectrVwsSbQxErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DonationFormObjectrmYFwVrv = {
  __typename?: 'DonationFormObjectrmYFwVrv';
  error?: Maybe<DonationFormObjectrmYFwVrvErrorObject>;
  object?: Maybe<DonationFormObject>;
};

export type DonationFormObjectrmYFwVrvErrorObject = {
  __typename?: 'DonationFormObjectrmYFwVrvErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DonationFormObjectt1Pz0erf = {
  __typename?: 'DonationFormObjectt1Pz0erf';
  error?: Maybe<DonationFormObjectt1Pz0erfErrorObject>;
  object?: Maybe<DonationFormObject>;
};

export type DonationFormObjectt1Pz0erfErrorObject = {
  __typename?: 'DonationFormObjectt1Pz0erfErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DonationFormObjectuFw4Os0k = {
  __typename?: 'DonationFormObjectuFW4Os0k';
  error?: Maybe<DonationFormObjectuFw4Os0kErrorObject>;
  object?: Maybe<DonationFormObject>;
};

export type DonationFormObjectuFw4Os0kErrorObject = {
  __typename?: 'DonationFormObjectuFW4Os0kErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DonationFormObjectwtKa3FjY = {
  __typename?: 'DonationFormObjectwtKa3FjY';
  error?: Maybe<DonationFormObjectwtKa3FjYErrorObject>;
  object?: Maybe<DonationFormObject>;
};

export type DonationFormObjectwtKa3FjYErrorObject = {
  __typename?: 'DonationFormObjectwtKa3FjYErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DonationFormObjectyuPwxgmf = {
  __typename?: 'DonationFormObjectyuPwxgmf';
  error?: Maybe<DonationFormObjectyuPwxgmfErrorObject>;
  object?: Maybe<DonationFormObject>;
};

export type DonationFormObjectyuPwxgmfErrorObject = {
  __typename?: 'DonationFormObjectyuPwxgmfErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum DonationFormRecurrence {
  monthly = 'monthly',
  weekly = 'weekly'
}

export type DonationFormResponseObject = {
  __typename?: 'DonationFormResponseObject';
  error?: Maybe<DonationFormResponseObjectErrorObject>;
  object?: Maybe<DonationFormObject>;
};

export type DonationFormResponseObjectErrorObject = {
  __typename?: 'DonationFormResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DonationFormSummaryObject = {
  __typename?: 'DonationFormSummaryObject';
  description: Scalars['String'];
  id: Scalars['String'];
};

export type DonationFormTableResponseListObjectErrorObject = {
  __typename?: 'DonationFormTableResponseListObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum DonationFormTemplateType {
  givingTuesday = 'givingTuesday',
  onboarding = 'onboarding'
}

export type DonationObject = {
  __typename?: 'DonationObject';
  address?: Maybe<Scalars['String']>;
  amount: Scalars['Float'];
  annotation?: Maybe<Scalars['String']>;
  answers?: Maybe<Array<AnswerObject>>;
  city?: Maybe<Scalars['String']>;
  commandId?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  country: Scalars['String'];
  createdAtUtc: Scalars['Date'];
  donationForm: DonationFormObject;
  email?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['Date']>;
  firstName: Scalars['String'];
  id: Scalars['String'];
  inHonourName?: Maybe<Scalars['String']>;
  isAnonymous: Scalars['Boolean'];
  isArchived: Scalars['Boolean'];
  isImported: Scalars['Boolean'];
  lastName: Scalars['String'];
  lastTransactionId?: Maybe<Scalars['String']>;
  nextPaymentDate?: Maybe<Scalars['Date']>;
  postalCode?: Maybe<Scalars['String']>;
  recurrence?: Maybe<DonationFormRecurrence>;
  recurrenceInterval: ProductDonationRecurrenceInterval;
  region?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionStatus?: Maybe<SubscriptionStatus>;
  thankYouComment?: Maybe<Scalars['String']>;
  tip: Scalars['Float'];
  transactions?: Maybe<Array<TransactionObject>>;
  user?: Maybe<UserObject>;
  userId?: Maybe<Scalars['String']>;
};

export type DonationResponseListObjectErrorObject = {
  __typename?: 'DonationResponseListObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DonationResponseObjectErrorObject = {
  __typename?: 'DonationResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DonorInput = {
  email?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  locale: Locales;
  organizationCountry: OrganizationCountry;
};

export enum DonorType {
  Member = 'Member',
  Monthly = 'Monthly',
  OneTime = 'OneTime',
  Prospect = 'Prospect',
  Returning = 'Returning',
  Yearly = 'Yearly'
}

export type DonorVolumeListResponseObject = {
  __typename?: 'DonorVolumeListResponseObject';
  error?: Maybe<DonorVolumeListResponseObjectErrorObject>;
  items?: Maybe<Array<DonorVolumeObject>>;
};

export type DonorVolumeListResponseObjectErrorObject = {
  __typename?: 'DonorVolumeListResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DonorVolumeObject = {
  __typename?: 'DonorVolumeObject';
  amount: Scalars['Float'];
  currency: AvailablePaymentCurrency;
};

export type EditContactInput = {
  address?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  corporationName?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<CountryCode>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
};

export type EditDiscountInput = {
  amount: Scalars['Float'];
  body: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Float']>;
  sortIndex: Scalars['Float'];
  type: DiscountType;
};

export type EditDonationFormAmountInput = {
  toDelete: Array<DonationFormAmountInput>;
  toSave: Array<UpdateDonationFormAmountInput>;
};

export type EditDonationFormAmountTranslationInput = {
  amount: Scalars['Float'];
  id: Scalars['String'];
  isRecurrent: Scalars['Boolean'];
  message?: InputMaybe<Scalars['String']>;
  recurrenceInterval: ProductDonationRecurrenceInterval;
  sortIndex: Scalars['Float'];
};

export type EditDonationFormFieldInput = {
  chequeDescription?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  donationFormAmounts?: InputMaybe<EditDonationFormAmountInput>;
  emailAttachmentUrl?: InputMaybe<Scalars['String']>;
  emailContent?: InputMaybe<Scalars['String']>;
  emailObject?: InputMaybe<Scalars['String']>;
  fundraiserEmailBody?: InputMaybe<Scalars['String']>;
  fundraisingRegistrationWording?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  message?: InputMaybe<Scalars['String']>;
  ticketingRegistrationWording?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type EditDonationFormInput = {
  allowCheque?: InputMaybe<Scalars['Boolean']>;
  allowFundraiserCreation?: InputMaybe<Scalars['Boolean']>;
  allowInHonour?: InputMaybe<Scalars['Boolean']>;
  allowTeamCreation?: InputMaybe<Scalars['Boolean']>;
  askForAddress?: InputMaybe<Scalars['Boolean']>;
  banner?: InputMaybe<Scalars['String']>;
  bannerVideoUrl?: InputMaybe<Scalars['String']>;
  choicesToDelete?: InputMaybe<Array<Scalars['String']>>;
  color?: InputMaybe<Scalars['String']>;
  donationFormAmounts?: InputMaybe<EditDonationFormAmountInput>;
  donationFormFields: Array<EditDonationFormFieldInput>;
  encourageMonthlyDonations?: InputMaybe<Scalars['Boolean']>;
  expectedAmountSize?: InputMaybe<ExpectedAmountSize>;
  hasReceipt?: InputMaybe<Scalars['Boolean']>;
  hideFundraiseButton?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['String'];
  isAmountsAlreadySavedInCreation?: InputMaybe<Scalars['Boolean']>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  linkedTicketingInput?: InputMaybe<EditLinkedTicketingInput>;
  locale?: InputMaybe<Locales>;
  logo?: InputMaybe<Scalars['String']>;
  notificationEmails?: InputMaybe<Scalars['NotificationEmailsScalar']>;
  questions?: InputMaybe<Array<QuestionInput>>;
  questionsToDelete?: InputMaybe<Array<Scalars['String']>>;
  recurrence?: InputMaybe<DonationFormRecurrence>;
  registrationIncreaseThermometer?: InputMaybe<Scalars['Boolean']>;
  target?: InputMaybe<Scalars['Float']>;
  toDeleteBanner?: InputMaybe<Scalars['Boolean']>;
  toDeleteBannerVideo?: InputMaybe<Scalars['Boolean']>;
  toDeleteLogo?: InputMaybe<Scalars['Boolean']>;
};

export type EditDonationFormTranslationsInput = {
  fields: TranslationsDonationFormFieldInput;
  translations: TranslationsDonationFormFieldInput;
};

export type EditLinkedTicketingInput = {
  choicesToDelete?: InputMaybe<Array<Scalars['String']>>;
  questionsPerParticipant?: InputMaybe<Array<QuestionInput>>;
  questionsToDelete?: InputMaybe<Array<Scalars['String']>>;
  rates: Array<EditRateInput>;
  ratesToDelete?: InputMaybe<Array<Scalars['String']>>;
};

export type EditPasswordInput = {
  newPassword: Scalars['String'];
  oldPassword: Scalars['String'];
};

export type EditRateFieldInput = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type EditRateInput = {
  allowAutomaticRenewal?: InputMaybe<Scalars['Boolean']>;
  amount?: InputMaybe<Scalars['Float']>;
  attendeesByTicket?: InputMaybe<Scalars['Float']>;
  bidIncrement?: InputMaybe<Scalars['Float']>;
  earlyBirdCloseDate?: InputMaybe<Scalars['Date']>;
  earlyBirdCloseDateTimezone?: InputMaybe<Scalars['String']>;
  eligibleAmount?: InputMaybe<Scalars['Float']>;
  id: Scalars['String'];
  maximumToBuy: Scalars['Float'];
  membershipValidityPeriod?: InputMaybe<MembershipValidityPeriod>;
  membershipValidityPeriodAtDate?: InputMaybe<Scalars['Date']>;
  minimumToBuy?: InputMaybe<Scalars['Float']>;
  photos?: InputMaybe<Array<Scalars['String']>>;
  rateFields: Array<EditRateFieldInput>;
  seats?: InputMaybe<Scalars['Float']>;
  sortIndex: Scalars['Float'];
};

export type EditReminderDataInput = {
  id: Scalars['String'];
  reminderBody: Scalars['String'];
  reminderSubject: Scalars['String'];
};

export type EditSessionOnSubmission = {
  durationOnPage: Scalars['Float'];
  sessionId?: InputMaybe<Scalars['String']>;
};

export type EditTagInput = {
  tagId: Scalars['String'];
  value: Scalars['String'];
};

export type EditTicketingFieldInput = {
  bidLoserEmailContent?: InputMaybe<Scalars['String']>;
  bidLoserEmailObject?: InputMaybe<Scalars['String']>;
  chequeDescription?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  emailAttachmentUrl?: InputMaybe<Scalars['String']>;
  emailContent?: InputMaybe<Scalars['String']>;
  emailObject?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
  offlinePaymentWording?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type EditTicketingInput = {
  address?: InputMaybe<Scalars['String']>;
  allowCheque?: InputMaybe<Scalars['Boolean']>;
  askForAddress?: InputMaybe<Scalars['Boolean']>;
  banner?: InputMaybe<Scalars['String']>;
  bannerPdf?: InputMaybe<Scalars['String']>;
  bannerVideoUrl?: InputMaybe<Scalars['String']>;
  choicesToDelete?: InputMaybe<Array<Scalars['String']>>;
  color?: InputMaybe<Scalars['String']>;
  discounts?: InputMaybe<Array<EditDiscountInput>>;
  discountsToDelete?: InputMaybe<Array<Scalars['String']>>;
  eventTimezone?: InputMaybe<Scalars['String']>;
  extraDonation?: InputMaybe<Scalars['Boolean']>;
  generateExtraDonationReceipt?: InputMaybe<Scalars['Boolean']>;
  generateQrCode?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['String'];
  liveFormEditorStep?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Locales>;
  logo?: InputMaybe<Scalars['String']>;
  notificationEmails?: InputMaybe<Scalars['NotificationEmailsScalar']>;
  questionsPerOrder?: InputMaybe<Array<QuestionInput>>;
  questionsPerParticipant?: InputMaybe<Array<QuestionInput>>;
  questionsToDelete?: InputMaybe<Array<Scalars['String']>>;
  rates?: InputMaybe<Array<EditRateInput>>;
  ratesToDelete?: InputMaybe<Array<Scalars['String']>>;
  recurrences?: InputMaybe<Array<TicketingRecurrenceInput>>;
  seats?: InputMaybe<Scalars['Float']>;
  thermometerTarget?: InputMaybe<Scalars['Float']>;
  ticketingFields?: InputMaybe<Array<EditTicketingFieldInput>>;
  toDeleteBanner?: InputMaybe<Scalars['Boolean']>;
  toDeleteBannerPdf?: InputMaybe<Scalars['Boolean']>;
  toDeleteBannerVideo?: InputMaybe<Scalars['Boolean']>;
  toDeleteLogo?: InputMaybe<Scalars['Boolean']>;
};

export enum EmailContentFlow {
  Builder = 'Builder',
  Custom = 'Custom',
  Quick = 'Quick'
}

export type EmailContentObject = {
  __typename?: 'EmailContentObject';
  attachmentUrl?: Maybe<Scalars['String']>;
  body: Scalars['String'];
  emailType?: Maybe<EmailContentType>;
  flow?: Maybe<EmailContentFlow>;
  id: Scalars['String'];
  recipientEmails?: Maybe<Array<Scalars['String']>>;
  subject: Scalars['String'];
};

export enum EmailContentType {
  BLAST = 'BLAST',
  FOLLOW_UP = 'FOLLOW_UP',
  INVITATION = 'INVITATION',
  POST_EVENT = 'POST_EVENT',
  REMINDER = 'REMINDER'
}

export type EmailDeliveryObject = {
  __typename?: 'EmailDeliveryObject';
  body: Scalars['String'];
  clickedRatio: Scalars['Float'];
  emailAttachmentUrl?: Maybe<Scalars['String']>;
  emailContent?: Maybe<EmailContentObject>;
  emailContentId?: Maybe<Scalars['String']>;
  emailsCount: Scalars['Float'];
  id: Scalars['String'];
  isPrefill: Scalars['Boolean'];
  locale: Locales;
  openedRatio: Scalars['Float'];
  organization: OrganizationObject;
  recipientEmailStatus?: Maybe<EmailStatus>;
  recipientEmails?: Maybe<Array<Scalars['String']>>;
  recipients?: Maybe<Array<RecipientObject>>;
  scheduledFor?: Maybe<Scalars['Date']>;
  sender: UserObject;
  sentAt?: Maybe<Scalars['Date']>;
  status: EmailDeliveryStatus;
  subject: Scalars['String'];
};

export type EmailDeliveryObjectak4wjxHb = {
  __typename?: 'EmailDeliveryObjectak4wjxHB';
  error?: Maybe<EmailDeliveryObjectak4wjxHbErrorObject>;
  object?: Maybe<EmailDeliveryObject>;
};

export type EmailDeliveryObjectak4wjxHbErrorObject = {
  __typename?: 'EmailDeliveryObjectak4wjxHBErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type EmailDeliveryResponseObjectErrorObject = {
  __typename?: 'EmailDeliveryResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum EmailDeliveryStatus {
  Completed = 'Completed',
  Draft = 'Draft',
  Failed = 'Failed',
  Pending = 'Pending',
  Processing = 'Processing'
}

export type EmailDeliveryTableListResponseObjectErrorObject = {
  __typename?: 'EmailDeliveryTableListResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum EmailStatus {
  AwaitingProcessing = 'AwaitingProcessing',
  Bounced = 'Bounced',
  Clicked = 'Clicked',
  Delivered = 'Delivered',
  Invalid = 'Invalid',
  Opened = 'Opened',
  Processed = 'Processed',
  Processing = 'Processing'
}

export enum EmailTemplateErrorCode {
  NotFound = 'NotFound'
}

export type EmailTemplateObject = {
  __typename?: 'EmailTemplateObject';
  body: Scalars['String'];
  flow?: Maybe<EmailContentFlow>;
  id: Scalars['String'];
  subject: Scalars['String'];
  title: Scalars['String'];
};

export type EmailTemplateResponseListObject = {
  __typename?: 'EmailTemplateResponseListObject';
  error?: Maybe<EmailTemplateResponseListObjectErrorObject>;
  items?: Maybe<Array<EmailTemplateObject>>;
};

export type EmailTemplateResponseListObjectErrorObject = {
  __typename?: 'EmailTemplateResponseListObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type EmailTemplateResponseObject = {
  __typename?: 'EmailTemplateResponseObject';
  error?: Maybe<EmailTemplateResponseObjectErrorObject>;
  object?: Maybe<EmailTemplateObject>;
};

export type EmailTemplateResponseObjectErrorObject = {
  __typename?: 'EmailTemplateResponseObjectErrorObject';
  code?: Maybe<EmailTemplateErrorCode>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum EmailType {
  VisitorDailyEmail = 'VisitorDailyEmail',
  VisitorMonthlyEmail = 'VisitorMonthlyEmail',
  VisitorWeeklyEmail = 'VisitorWeeklyEmail'
}

export type EmailsCountByStatusObject = {
  __typename?: 'EmailsCountByStatusObject';
  count: Scalars['Float'];
  status: Scalars['String'];
};

export type EmailsCountByStatusTableListResponseObject = {
  __typename?: 'EmailsCountByStatusTableListResponseObject';
  error?: Maybe<EmailsCountByStatusTableListResponseObjectErrorObject>;
  filteredCount: Scalars['Float'];
  hasMore: Scalars['Boolean'];
  items?: Maybe<Array<EmailsCountByStatusObject>>;
  totalCount: Scalars['Float'];
};

export type EmailsCountByStatusTableListResponseObjectErrorObject = {
  __typename?: 'EmailsCountByStatusTableListResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type EncryptedPaymentIntentObject = {
  __typename?: 'EncryptedPaymentIntentObject';
  clientSecret: Scalars['String'];
  encryptedPaymentIntentId: Scalars['String'];
};

export type EncryptedPaymentIntentResponseObjectErrorObject = {
  __typename?: 'EncryptedPaymentIntentResponseObjectErrorObject';
  code?: Maybe<PaymentIntentError>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type EventDateRange = {
  __typename?: 'EventDateRange';
  endDateUtc: Scalars['Date'];
  startDateUtc: Scalars['Date'];
};

export enum ExpectedAmountSize {
  Big = 'Big',
  Medium = 'Medium',
  Small = 'Small'
}

export type ExpiredEarlyBirdErrorObject = {
  __typename?: 'ExpiredEarlyBirdErrorObject';
  earlyBirdCloseDate?: Maybe<Scalars['Date']>;
  earlyBirdCloseDateTimezone?: Maybe<Scalars['String']>;
  id: Scalars['String'];
};

export type ExportObject = {
  __typename?: 'ExportObject';
  export?: Maybe<Scalars['String']>;
  isExportSentByEmail: Scalars['Boolean'];
};

export type ExportObjectaaFk2vPb = {
  __typename?: 'ExportObjectaaFK2vPb';
  error?: Maybe<ExportObjectaaFk2vPbErrorObject>;
  object?: Maybe<ExportObject>;
};

export type ExportObjectaaFk2vPbErrorObject = {
  __typename?: 'ExportObjectaaFK2vPbErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ExportParametersInput = {
  locale: Locales;
  timezone: Scalars['String'];
};

export type ExportResponseObject = {
  __typename?: 'ExportResponseObject';
  error?: Maybe<ExportResponseObjectErrorObject>;
  object?: Maybe<ExportObject>;
};

export type ExportResponseObjectErrorObject = {
  __typename?: 'ExportResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ExternalAccountInput = {
  accountNumber: Scalars['String'];
  routingNumber: Scalars['String'];
};

export type ExternalAccountObject = {
  __typename?: 'ExternalAccountObject';
  accountName?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
  country?: Maybe<CountryCode>;
  currency?: Maybe<AvailablePaymentCurrency>;
  holderName?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  last4?: Maybe<Scalars['String']>;
  routingNumber?: Maybe<Scalars['String']>;
  status?: Maybe<BankAccountStatus>;
};

export type ExternalAccountObjectl4800Bss = {
  __typename?: 'ExternalAccountObjectl4800Bss';
  error?: Maybe<ExternalAccountObjectl4800BssErrorObject>;
  object?: Maybe<ExternalAccountObject>;
};

export type ExternalAccountObjectl4800BssErrorObject = {
  __typename?: 'ExternalAccountObjectl4800BssErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum FeatureFlagName {
  FeatureToggleBadTipperDisclaimer = 'FeatureToggleBadTipperDisclaimer',
  IsAlgoliaForFormSearch = 'IsAlgoliaForFormSearch',
  IsAuctionRebiddingDisabled = 'IsAuctionRebiddingDisabled',
  IsBookADemoDisplayedWebflow = 'IsBookADemoDisplayedWebflow',
  IsCloudflareApiEnabled = 'IsCloudflareApiEnabled',
  IsFundraiserFormEditDisabled = 'IsFundraiserFormEditDisabled',
  IsHubspotActiveInStage = 'IsHubspotActiveInStage',
  IsNewMobileFormCreationFlow = 'IsNewMobileFormCreationFlow',
  IsPaymentByPadAllowed = 'IsPaymentByPadAllowed',
  IsZeffyUnderAttackFromCardTesting = 'IsZeffyUnderAttackFromCardTesting',
  LearnMoreOnTipMessage = 'LearnMoreOnTipMessage',
  Test = 'Test',
  TipSuggestionBigAmountsLargerThan400 = 'TipSuggestionBigAmountsLargerThan400',
  TipSuggestionBigAmountsLargerThan1000 = 'TipSuggestionBigAmountsLargerThan1000',
  TipSuggestionExperience = 'TipSuggestionExperience',
  TipSuggestionSmallAmounts = 'TipSuggestionSmallAmounts'
}

export type FeatureFlagTreatmentObject = {
  __typename?: 'FeatureFlagTreatmentObject';
  name: FeatureFlagName;
  treatment: Scalars['Boolean'];
};

export enum FeatureFlagValue {
  A = 'A',
  B = 'B',
  C = 'C',
  Control = 'Control',
  D = 'D',
  E = 'E',
  F = 'F'
}

export type FirstFormObject = {
  __typename?: 'FirstFormObject';
  formCategory: FormCategory;
  formStatus: FormStatus;
  formType: FormType;
  id: Scalars['String'];
  path: Scalars['String'];
};

export enum FormCategory {
  Auction = 'Auction',
  Custom = 'Custom',
  DonationCampaign = 'DonationCampaign',
  DonationForm = 'DonationForm',
  Event = 'Event',
  Lottery = 'Lottery',
  Membership = 'Membership',
  MembershipV2 = 'MembershipV2',
  PeerToPeerCampaign = 'PeerToPeerCampaign',
  Shop = 'Shop'
}

export type FormField = {
  __typename?: 'FormField';
  id: Scalars['String'];
  locale: Locales;
  title: Scalars['String'];
};

export type FormInput = {
  formType: FormType;
  id: Scalars['String'];
};

export type FormObject = {
  __typename?: 'FormObject';
  fields: Array<FormField>;
  formLink: Scalars['String'];
  formType: FormType;
  id: Scalars['String'];
  volume: Scalars['Float'];
};

export enum FormStatus {
  Active = 'Active',
  Draft = 'Draft'
}

export type FormTitle = {
  __typename?: 'FormTitle';
  fields: Array<FormField>;
  id: Scalars['String'];
};

export type FormTitleListResponse = {
  __typename?: 'FormTitleListResponse';
  error?: Maybe<FormTitleListResponseErrorObject>;
  items?: Maybe<Array<FormTitle>>;
};

export type FormTitleListResponseErrorObject = {
  __typename?: 'FormTitleListResponseErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum FormType {
  DonationForm = 'DonationForm',
  Ticketing = 'Ticketing'
}

export enum FrequencyFilter {
  Daily = 'Daily',
  Monthly = 'Monthly',
  Weekly = 'Weekly'
}

export type FrontendFormObject = {
  __typename?: 'FrontendFormObject';
  auctionEndDate?: Maybe<Scalars['Date']>;
  auctionSoldDate?: Maybe<Scalars['Date']>;
  bannerUrl?: Maybe<Scalars['String']>;
  campaignId?: Maybe<Scalars['String']>;
  closed?: Maybe<Scalars['Boolean']>;
  color?: Maybe<Scalars['String']>;
  fields: Array<FormField>;
  formCategory: FormCategory;
  formType: FormType;
  generateQrCode?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  isPrimaryCampaign?: Maybe<Scalars['Boolean']>;
  isPrimaryTeam?: Maybe<Scalars['Boolean']>;
  isRegistration?: Maybe<Scalars['Boolean']>;
  locale: Locales;
  logoUrl?: Maybe<Scalars['String']>;
  path: Scalars['String'];
  rates?: Maybe<Array<TicketingDetailsRate>>;
  seats?: Maybe<Scalars['Int']>;
  status: FormStatus;
  teamId?: Maybe<Scalars['String']>;
};

export type FrontendFormObject8k6VsyTe = {
  __typename?: 'FrontendFormObject8k6VsyTe';
  error?: Maybe<FrontendFormObject8k6VsyTeErrorObject>;
  items?: Maybe<Array<FrontendFormObject>>;
};

export type FrontendFormObject8k6VsyTeErrorObject = {
  __typename?: 'FrontendFormObject8k6VsyTeErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type FrontendFormObjectFormObjectResponseObjectErrorObject = {
  __typename?: 'FrontendFormObjectFormObjectResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type FrontendFormObjectResponseListObject = {
  __typename?: 'FrontendFormObjectResponseListObject';
  error?: Maybe<FrontendFormObjectResponseListObjectErrorObject>;
  items?: Maybe<Array<FrontendFormObject>>;
};

export type FrontendFormObjectResponseListObjectErrorObject = {
  __typename?: 'FrontendFormObjectResponseListObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type FrontendFormObjectResponseObject = {
  __typename?: 'FrontendFormObjectResponseObject';
  error?: Maybe<FrontendFormObjectFormObjectResponseObjectErrorObject>;
  object?: Maybe<FrontendFormObject>;
};

export type FullChoiceErrorObject = {
  __typename?: 'FullChoiceErrorObject';
  id: Scalars['String'];
  remainingAnswers: Scalars['Float'];
};

export type FullRateErrorObject = {
  __typename?: 'FullRateErrorObject';
  id: Scalars['String'];
  remainingTickets: Scalars['Float'];
};

export type FundraiserDonationFormObject = {
  __typename?: 'FundraiserDonationFormObject';
  allowCheque: Scalars['Boolean'];
  allowFundraiserCreation?: Maybe<Scalars['Boolean']>;
  allowInHonour: Scalars['Boolean'];
  allowTeamCreation?: Maybe<Scalars['Boolean']>;
  askForAddress: Scalars['Boolean'];
  bannerUrl?: Maybe<Scalars['String']>;
  bannerVideoUrl?: Maybe<Scalars['String']>;
  campaign?: Maybe<DonationFormObject>;
  campaignId?: Maybe<Scalars['String']>;
  category: DonationFormCategory;
  color: Scalars['String'];
  contact?: Maybe<ContactObject>;
  createdAtUtc: Scalars['Date'];
  displayAddressQuestion?: Maybe<Scalars['Boolean']>;
  donationFormFields?: Maybe<Array<DonationFormFieldObject>>;
  donations?: Maybe<Array<DonationObject>>;
  encourageMonthlyDonations: Scalars['Boolean'];
  expectedAmountSize?: Maybe<ExpectedAmountSize>;
  featureFlagTreatments?: Maybe<Array<FeatureFlagTreatmentObject>>;
  formVolume?: Maybe<Scalars['Float']>;
  hasReceipt: Scalars['Boolean'];
  hideFundraiseButton: Scalars['Boolean'];
  highlight: Scalars['Boolean'];
  id: Scalars['String'];
  isAmountsAlreadySavedInCreation?: Maybe<Scalars['Boolean']>;
  isArchived: Scalars['Boolean'];
  isFundraiserFormEditDisabled: Scalars['Boolean'];
  isPrimaryCampaign: Scalars['Boolean'];
  isPrimaryTeam: Scalars['Boolean'];
  liveFormEditorStep?: Maybe<Scalars['String']>;
  locale: Locales;
  logoUrl?: Maybe<Scalars['String']>;
  missingTranslations: Scalars['Boolean'];
  notificationEmails?: Maybe<Scalars['NotificationEmailsScalar']>;
  organization?: Maybe<OrganizationObject>;
  organizationId?: Maybe<Scalars['String']>;
  path: Scalars['String'];
  questions?: Maybe<Array<QuestionObject>>;
  recurrence: DonationFormRecurrence;
  redirectUrl?: Maybe<Scalars['String']>;
  registrationCommandId?: Maybe<Scalars['String']>;
  registrationIncreaseThermometer: Scalars['Boolean'];
  registrationOrderId?: Maybe<Scalars['String']>;
  status?: Maybe<FormStatus>;
  stripeProductId?: Maybe<Scalars['String']>;
  target?: Maybe<Scalars['Float']>;
  team?: Maybe<DonationFormObject>;
  teamId?: Maybe<Scalars['String']>;
  ticketing?: Maybe<TicketingObject>;
  ticketingId?: Maybe<Scalars['String']>;
  token: Scalars['String'];
  user?: Maybe<UserObject>;
  volume?: Maybe<Scalars['Float']>;
};

export type GetBalanceTransactionDetailsObject = {
  __typename?: 'GetBalanceTransactionDetailsObject';
  id: Scalars['String'];
  sourceIssuingTransaction?: Maybe<GetBalanceTransactionIssuingTransactionObject>;
  sourcePayment?: Maybe<GetBalanceTransactionPaymentObject>;
  sourcePayout?: Maybe<GetBalanceTransactionPayoutObject>;
};

export type GetBalanceTransactionDetailsObjectsQRsINwD = {
  __typename?: 'GetBalanceTransactionDetailsObjectsQRsINwD';
  error?: Maybe<GetBalanceTransactionDetailsObjectsQRsINwDErrorObject>;
  object?: Maybe<GetBalanceTransactionDetailsObject>;
};

export type GetBalanceTransactionDetailsObjectsQRsINwDErrorObject = {
  __typename?: 'GetBalanceTransactionDetailsObjectsQRsINwDErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type GetBalanceTransactionIssuingTransactionObject = {
  __typename?: 'GetBalanceTransactionIssuingTransactionObject';
  authorizationCreatedAtUtc: Scalars['Date'];
  city?: Maybe<Scalars['String']>;
  createdAtUtc: Scalars['Date'];
  merchantName: Scalars['String'];
  state?: Maybe<Scalars['String']>;
};

export type GetBalanceTransactionPaymentObject = {
  __typename?: 'GetBalanceTransactionPaymentObject';
  createdAtUtc: Scalars['Date'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  formId: Scalars['String'];
  formName: Scalars['String'];
  formType: FormType;
  lastName: Scalars['String'];
  paymentMethod: PaymentMethod;
};

export type GetBalanceTransactionPayoutObject = {
  __typename?: 'GetBalanceTransactionPayoutObject';
  arrivalDate: Scalars['Date'];
};

export type GetCurrentUserFrontendObject = {
  __typename?: 'GetCurrentUserFrontendObject';
  connectedAsAdministrator: Scalars['Boolean'];
  createdAtUtc: Scalars['Date'];
  currentOrganization?: Maybe<OrganizationObject>;
  currentOrganizationDetails?: Maybe<OrganizationDetailsObject>;
  deprecatedMongoId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailVerified: Scalars['Boolean'];
  firstName: Scalars['String'];
  fullName: Scalars['String'];
  hasAnyBankPermission: Scalars['Boolean'];
  hasDismissedEmailInfobox: Scalars['Boolean'];
  hasDismissedEmailTemplatePrompt: Scalars['Boolean'];
  hasDismissedReferralPromptAtFirstPayout: Scalars['Boolean'];
  hasSeenReferralPage: Scalars['Boolean'];
  id: Scalars['String'];
  isAuthenticatedWithGoogleSSO: Scalars['Boolean'];
  isBankChangeSmsMfaActivated: Scalars['Boolean'];
  isLoginSmsMfaActivated: Scalars['Boolean'];
  isMigratedOnStytch: Scalars['Boolean'];
  isOnboardedOnCheckinManager: Scalars['Boolean'];
  isTwoFactorActivated: Scalars['Boolean'];
  lastName: Scalars['String'];
  locale: Locales;
  maskedPhoneNumber?: Maybe<Scalars['String']>;
  organizationGroups: Array<OrganizationGroupObject>;
  phone?: Maybe<Scalars['String']>;
  role: UserRoles;
  stripeCustomers?: Maybe<Array<StripeCustomerObject>>;
  zeffyAdministrator: Scalars['Boolean'];
};

export type GetCurrentUserFrontendObjectotwZLjOi = {
  __typename?: 'GetCurrentUserFrontendObjectotwZLjOi';
  error?: Maybe<GetCurrentUserFrontendObjectotwZLjOiErrorObject>;
  object?: Maybe<GetCurrentUserFrontendObject>;
};

export type GetCurrentUserFrontendObjectotwZLjOiErrorObject = {
  __typename?: 'GetCurrentUserFrontendObjectotwZLjOiErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type GetCurrentUserObject = {
  __typename?: 'GetCurrentUserObject';
  createdAtUtc: Scalars['Date'];
  currentOrganization?: Maybe<OrganizationObject>;
  deprecatedMongoId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailVerified: Scalars['Boolean'];
  firstName: Scalars['String'];
  fullName: Scalars['String'];
  hasSeenReferralPage: Scalars['Boolean'];
  id: Scalars['String'];
  isAdministrator: Scalars['Boolean'];
  lastName: Scalars['String'];
  locale: Locales;
  organizationGroups: Array<OrganizationGroupObject>;
  role: UserRoles;
  stripeCustomers?: Maybe<Array<StripeCustomerObject>>;
};

export type GetCurrentUserResponseObjectErrorObject = {
  __typename?: 'GetCurrentUserResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type GetDonationFormsFromCampaignObject = {
  __typename?: 'GetDonationFormsFromCampaignObject';
  hasMore: Scalars['Boolean'];
  individual: Array<DonationFormFromCampaignObject>;
  team: Array<DonationFormFromCampaignObject>;
};

export type GetDonationFormsFromCampaignObject9qJJxen8 = {
  __typename?: 'GetDonationFormsFromCampaignObject9qJJxen8';
  error?: Maybe<GetDonationFormsFromCampaignObject9qJJxen8ErrorObject>;
  object?: Maybe<GetDonationFormsFromCampaignObject>;
};

export type GetDonationFormsFromCampaignObject9qJJxen8ErrorObject = {
  __typename?: 'GetDonationFormsFromCampaignObject9qJJxen8ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum GetEmailDeliveryError {
  BelongToMyOtherOrganization = 'BelongToMyOtherOrganization'
}

export type GetEmailDeliveryResponseObjectErrorObject = {
  __typename?: 'GetEmailDeliveryResponseObjectErrorObject';
  code?: Maybe<GetEmailDeliveryError>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type GetEphemeralKeyInput = {
  nonce: Scalars['String'];
};

export type GetEphemeralKeyObject = {
  __typename?: 'GetEphemeralKeyObject';
  ephemeralKeySecret: Scalars['String'];
};

export type GetEphemeralKeyObjectW6hrp0zR = {
  __typename?: 'GetEphemeralKeyObjectW6hrp0zR';
  error?: Maybe<GetEphemeralKeyObjectW6hrp0zRErrorObject>;
  object?: Maybe<GetEphemeralKeyObject>;
};

export type GetEphemeralKeyObjectW6hrp0zRErrorObject = {
  __typename?: 'GetEphemeralKeyObjectW6hrp0zRErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type GetFirstTransactionCreatedAtUtcResponseObject = {
  __typename?: 'GetFirstTransactionCreatedAtUtcResponseObject';
  createdAtUtc?: Maybe<Scalars['Date']>;
  id?: Maybe<Scalars['String']>;
};

export type GetFirstTransactionCreatedAtUtcResponseObjecti9AoifeB = {
  __typename?: 'GetFirstTransactionCreatedAtUtcResponseObjecti9AoifeB';
  error?: Maybe<GetFirstTransactionCreatedAtUtcResponseObjecti9AoifeBErrorObject>;
  object?: Maybe<GetFirstTransactionCreatedAtUtcResponseObject>;
};

export type GetFirstTransactionCreatedAtUtcResponseObjecti9AoifeBErrorObject = {
  __typename?: 'GetFirstTransactionCreatedAtUtcResponseObjecti9AoifeBErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type GetIssuingCardObject = {
  __typename?: 'GetIssuingCardObject';
  issuingCard?: Maybe<IssuingCardObject>;
};

export type GetIssuingCardObjectycQMi12P = {
  __typename?: 'GetIssuingCardObjectycQMi12P';
  error?: Maybe<GetIssuingCardObjectycQMi12PErrorObject>;
  object?: Maybe<GetIssuingCardObject>;
};

export type GetIssuingCardObjectycQMi12PErrorObject = {
  __typename?: 'GetIssuingCardObjectycQMi12PErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type GetIssuingRewardTransactionsObject = {
  __typename?: 'GetIssuingRewardTransactionsObject';
  amount: Scalars['Float'];
  disputeCount: Scalars['Float'];
  transactionCount: Scalars['Float'];
};

export type GetIssuingRewardTransactionsObjectWx6hNvvb = {
  __typename?: 'GetIssuingRewardTransactionsObjectWX6hNVVB';
  error?: Maybe<GetIssuingRewardTransactionsObjectWx6hNvvbErrorObject>;
  object?: Maybe<GetIssuingRewardTransactionsObject>;
};

export type GetIssuingRewardTransactionsObjectWx6hNvvbErrorObject = {
  __typename?: 'GetIssuingRewardTransactionsObjectWX6hNVVBErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type GetMarkerHoverDetailsInput = {
  cursorType: CursorType;
  id: Scalars['String'];
};

export type GetMarkersInput = {
  lat: LatLngBoundariesInput;
  lng: LatLngBoundariesInput;
};

export type GetOrganizationSummaryObject = {
  __typename?: 'GetOrganizationSummaryObject';
  address: Scalars['String'];
  lastDonationForm?: Maybe<DonationFormSummaryObject>;
  lastTicketing?: Maybe<TicketingSummaryObject>;
  url?: Maybe<Scalars['String']>;
};

export type GetOrganizationSummaryResponseObject = {
  __typename?: 'GetOrganizationSummaryResponseObject';
  error?: Maybe<GetOrganizationSummaryResponseObjectErrorObject>;
  object?: Maybe<GetOrganizationSummaryObject>;
};

export type GetOrganizationSummaryResponseObjectErrorObject = {
  __typename?: 'GetOrganizationSummaryResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type GetReferredOrganizationInput = {
  organizationId: Scalars['String'];
};

export type GetScheduledPayoutObject = {
  __typename?: 'GetScheduledPayoutObject';
  payout?: Maybe<ScheduledPayoutObject>;
};

export type GetScheduledPayoutObjectdcffSnx3 = {
  __typename?: 'GetScheduledPayoutObjectdcffSnx3';
  error?: Maybe<GetScheduledPayoutObjectdcffSnx3ErrorObject>;
  object?: Maybe<GetScheduledPayoutObject>;
};

export type GetScheduledPayoutObjectdcffSnx3ErrorObject = {
  __typename?: 'GetScheduledPayoutObjectdcffSnx3ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type GetTipSuggestionsInput = {
  amount: Scalars['Float'];
  tipModelExperience?: InputMaybe<TipModelExperience>;
};

export type GetTransactionsForIssuingBalanceTransferObject = {
  __typename?: 'GetTransactionsForIssuingBalanceTransferObject';
  endDate?: Maybe<Scalars['Date']>;
  volume: Scalars['Float'];
};

export type GetTransactionsForIssuingBalanceTransferObjectA9K8FtPh = {
  __typename?: 'GetTransactionsForIssuingBalanceTransferObjectA9K8FtPh';
  error?: Maybe<GetTransactionsForIssuingBalanceTransferObjectA9K8FtPhErrorObject>;
  object?: Maybe<GetTransactionsForIssuingBalanceTransferObject>;
};

export type GetTransactionsForIssuingBalanceTransferObjectA9K8FtPhErrorObject = {
  __typename?: 'GetTransactionsForIssuingBalanceTransferObjectA9K8FtPhErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type HasEmailDeliveryObject = {
  __typename?: 'HasEmailDeliveryObject';
  draft: Scalars['Boolean'];
  pending: Scalars['Boolean'];
  sent: Scalars['Boolean'];
};

export type HasEmailDeliveryObjectzcdBMjsR = {
  __typename?: 'HasEmailDeliveryObjectzcdBMjsR';
  error?: Maybe<HasEmailDeliveryObjectzcdBMjsRErrorObject>;
  object?: Maybe<HasEmailDeliveryObject>;
};

export type HasEmailDeliveryObjectzcdBMjsRErrorObject = {
  __typename?: 'HasEmailDeliveryObjectzcdBMjsRErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type HomeGraphInput = {
  endDate: Scalars['Date'];
  formIds: Array<Scalars['String']>;
  frequency: FrequencyFilter;
  startDate: Scalars['Date'];
};

export type HomeGraphObject = {
  __typename?: 'HomeGraphObject';
  date: Scalars['Date'];
  volume: Scalars['Int'];
};

export type HomeGraphObjectResponseObjectErrorObject = {
  __typename?: 'HomeGraphObjectResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type HomeGraphResponseListObject = {
  __typename?: 'HomeGraphResponseListObject';
  error?: Maybe<HomeGraphResponseListObjectErrorObject>;
  items?: Maybe<Array<HomeGraphObject>>;
};

export type HomeGraphResponseListObjectErrorObject = {
  __typename?: 'HomeGraphResponseListObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type HomeMetricsInput = {
  endDate: Scalars['Date'];
  formIds: Array<Scalars['String']>;
  startDate: Scalars['Date'];
};

export type HubspotProperty = {
  propertyName: Scalars['String'];
  propertyValue: Scalars['String'];
};

export type ImportCheckErrorObject = {
  __typename?: 'ImportCheckErrorObject';
  emptyColumns: Array<Scalars['String']>;
  invalidColumns: Array<Scalars['String']>;
  row: Scalars['Float'];
};

export enum ImportStatus {
  Created = 'Created',
  Failed = 'Failed',
  Processing = 'Processing',
  Succeeded = 'Succeeded'
}

export enum ImportType {
  Contact = 'Contact',
  Payment = 'Payment'
}

export type ImportUsersGoogleSheetObject = {
  __typename?: 'ImportUsersGoogleSheetObject';
  addedContactCount?: Maybe<Scalars['Float']>;
  addedPaymentCount?: Maybe<Scalars['Float']>;
  errors?: Maybe<Array<ImportCheckErrorObject>>;
  id?: Maybe<Scalars['String']>;
  isAsync?: Maybe<Scalars['Boolean']>;
  status?: Maybe<ImportStatus>;
};

export type ImportUsersGoogleSheetObject8PJm5RZp = {
  __typename?: 'ImportUsersGoogleSheetObject8PJm5RZp';
  error?: Maybe<ImportUsersGoogleSheetObject8PJm5RZpErrorObject>;
  object?: Maybe<ImportUsersGoogleSheetObject>;
};

export type ImportUsersGoogleSheetObject8PJm5RZpErrorObject = {
  __typename?: 'ImportUsersGoogleSheetObject8PJm5RZpErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ImportUsersGoogleSheetObject9Gawj94e = {
  __typename?: 'ImportUsersGoogleSheetObject9GAWJ94e';
  error?: Maybe<ImportUsersGoogleSheetObject9Gawj94eErrorObject>;
  object?: Maybe<ImportUsersGoogleSheetObject>;
};

export type ImportUsersGoogleSheetObject9Gawj94eErrorObject = {
  __typename?: 'ImportUsersGoogleSheetObject9GAWJ94eErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ImportUsersGoogleSheetResponseObjectErrorObject = {
  __typename?: 'ImportUsersGoogleSheetResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type IncreasePayoutAmountInput = {
  amount: Scalars['Float'];
  payoutId: Scalars['String'];
};

export type IncreasePayoutAmountObject = {
  __typename?: 'IncreasePayoutAmountObject';
  amount: Scalars['Float'];
  id: Scalars['String'];
};

export type IncreasePayoutAmountObjectmUfDl9Az = {
  __typename?: 'IncreasePayoutAmountObjectmUFDl9Az';
  error?: Maybe<IncreasePayoutAmountObjectmUfDl9AzErrorObject>;
  object?: Maybe<IncreasePayoutAmountObject>;
};

export type IncreasePayoutAmountObjectmUfDl9AzErrorObject = {
  __typename?: 'IncreasePayoutAmountObjectmUFDl9AzErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type InsertTagInput = {
  tagId: Scalars['String'];
  userId: Scalars['String'];
};

export type IntResponseObject = {
  __typename?: 'IntResponseObject';
  error?: Maybe<IntegerResponseObjectErrorObject>;
  object?: Maybe<Scalars['Int']>;
};

export type IntegerResponseObjectErrorObject = {
  __typename?: 'IntegerResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type IsEmailRegisteredInput = {
  email: Scalars['String'];
  locale: Locales;
  recaptchaToken?: InputMaybe<Scalars['String']>;
  redirectionUrl?: InputMaybe<Scalars['String']>;
};

export type IsEmailRegisteredSsoInput = {
  googleToken: Scalars['String'];
  locale: Locales;
  recaptchaToken?: InputMaybe<Scalars['String']>;
};

export type IsUserEmailRegisteredObject = {
  __typename?: 'IsUserEmailRegisteredObject';
  fundraiserCreationToken?: Maybe<Scalars['String']>;
  hadPasswordInSimplykV1: Scalars['Boolean'];
  hasPassword: Scalars['Boolean'];
  hasResetPasswordToken: Scalars['Boolean'];
  shouldAuthenticateWithGoogle?: Maybe<Scalars['Boolean']>;
  userId?: Maybe<Scalars['String']>;
  userRegistrationStatus: UserRegistrationStatus;
};

export type IsUserEmailRegisteredResponseObject = {
  __typename?: 'IsUserEmailRegisteredResponseObject';
  error?: Maybe<IsUserEmailRegisteredResponseObjectErrorObject>;
  object?: Maybe<IsUserEmailRegisteredObject>;
};

export type IsUserEmailRegisteredResponseObjectErrorObject = {
  __typename?: 'IsUserEmailRegisteredResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type IssuingCardObject = {
  __typename?: 'IssuingCardObject';
  cardHolderStatus: CardHolderStatus;
  id: Scalars['String'];
  isCardHolder: Scalars['Boolean'];
  last4?: Maybe<Scalars['String']>;
  status: IssuingCardStatus;
  stripeIssuingCardId?: Maybe<Scalars['String']>;
  treasuryFinancialAccountId?: Maybe<Scalars['String']>;
};

export enum IssuingCardStatus {
  Active = 'Active',
  Inactive = 'Inactive',
  Pending = 'Pending'
}

export type LatLngBoundariesInput = {
  max: Scalars['Float'];
  min: Scalars['Float'];
};

export type ListTransactionTicketsObject = {
  __typename?: 'ListTransactionTicketsObject';
  allCanceled: Scalars['Boolean'];
  productTickets: Array<ProductTicketObject>;
};

export type ListTransactionTicketsObjectErrorObject = {
  __typename?: 'ListTransactionTicketsObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ListTransactionTicketsResponse = {
  __typename?: 'ListTransactionTicketsResponse';
  error?: Maybe<ListTransactionTicketsObjectErrorObject>;
  object?: Maybe<ListTransactionTicketsObject>;
};

export enum Locales {
  EN = 'EN',
  ES = 'ES',
  FR = 'FR'
}

export type ManualContactInput = {
  address?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  corporationName?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<CountryCode>;
  email?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  locale: Locales;
  note?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
  tagIds?: InputMaybe<Array<Scalars['String']>>;
};

export type MarkerHoverDetailsObject = {
  __typename?: 'MarkerHoverDetailsObject';
  color?: Maybe<Scalars['String']>;
  cursorType: CursorType;
  dates: Array<EventDateRange>;
  id: Scalars['String'];
  logoUrl?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type MarkerPositionObject = {
  __typename?: 'MarkerPositionObject';
  coordinates: CoordinatesObject;
  cursorType: CursorType;
  id: Scalars['String'];
};

export type MarkerPositionResponseObjectErrorObject = {
  __typename?: 'MarkerPositionResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum MembershipValidityPeriod {
  AtDate = 'AtDate',
  NoExpiration = 'NoExpiration',
  OneMonthAfterPayment = 'OneMonthAfterPayment',
  OneYearAfterPayment = 'OneYearAfterPayment'
}

export enum MergeUsersError {
  ErrorCreatingAccessToken = 'ErrorCreatingAccessToken',
  TokenDoesNotMatch = 'TokenDoesNotMatch',
  UserNotFound = 'UserNotFound'
}

export type MergeUsersObject = {
  __typename?: 'MergeUsersObject';
  accessToken?: Maybe<Scalars['String']>;
  locale?: Maybe<Locales>;
  role?: Maybe<UserRoles>;
};

export type MergeUsersObjectfzF7EsZl = {
  __typename?: 'MergeUsersObjectfzF7ESZl';
  error?: Maybe<MergeUsersObjectfzF7EsZlErrorObject>;
  object?: Maybe<MergeUsersObject>;
};

export type MergeUsersObjectfzF7EsZlErrorObject = {
  __typename?: 'MergeUsersObjectfzF7ESZlErrorObject';
  code?: Maybe<MergeUsersError>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type MultiselectLabelObject = {
  __typename?: 'MultiselectLabelObject';
  label: Scalars['String'];
  locale: Locales;
};

export type MultiselectOccurrenceValueObject = {
  __typename?: 'MultiselectOccurrenceValueObject';
  endUtc: Scalars['Date'];
  isArchived: Scalars['Boolean'];
  startUtc: Scalars['Date'];
  value: Scalars['String'];
};

export type MultiselectOccurrenceValueObjectYjBtm2Ox = {
  __typename?: 'MultiselectOccurrenceValueObjectYjBtm2OX';
  error?: Maybe<MultiselectOccurrenceValueObjectYjBtm2OxErrorObject>;
  items?: Maybe<Array<MultiselectOccurrenceValueObject>>;
};

export type MultiselectOccurrenceValueObjectYjBtm2OxErrorObject = {
  __typename?: 'MultiselectOccurrenceValueObjectYjBtm2OXErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type MultiselectValueObject = {
  __typename?: 'MultiselectValueObject';
  labels: Array<MultiselectLabelObject>;
  value: Scalars['String'];
};

export type MultiselectValueObjectGnJKsr0d = {
  __typename?: 'MultiselectValueObjectGnJKsr0d';
  error?: Maybe<MultiselectValueObjectGnJKsr0dErrorObject>;
  items?: Maybe<Array<MultiselectValueObject>>;
};

export type MultiselectValueObjectGnJKsr0dErrorObject = {
  __typename?: 'MultiselectValueObjectGnJKsr0dErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type MultiselectValueObjecteYYaMVsn = {
  __typename?: 'MultiselectValueObjecteYYaMVsn';
  error?: Maybe<MultiselectValueObjecteYYaMVsnErrorObject>;
  items?: Maybe<Array<MultiselectValueObject>>;
};

export type MultiselectValueObjecteYYaMVsnErrorObject = {
  __typename?: 'MultiselectValueObjecteYYaMVsnErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type MultiselectValueResponseListObject = {
  __typename?: 'MultiselectValueResponseListObject';
  error?: Maybe<MultiselectValueResponseListObjectErrorObject>;
  items?: Maybe<Array<MultiselectValueObject>>;
};

export type MultiselectValueResponseListObjectErrorObject = {
  __typename?: 'MultiselectValueResponseListObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  activateCardIssuing: BooleanxJq9M1pz;
  activateDonationForm: DonationFormObjectlR4bxdIr;
  activateTicketing: TicketingObjecterB2vEr2;
  activeTwoFactorAuth: BooleanSg02wFux;
  addEngagementStepChecked: BooleanJ8e34ujp;
  addManualContact: StringCuFAjg8i;
  addRetroActiveReferral: BooleanD0niaLYp;
  addTicketingOffset: BooleanWDsKs1Bb;
  archiveCampaign: Number56NTfXhi;
  archiveDonationForm: BooleanuJaHxAbe;
  archiveTeam: Boolean7ffvdsXp;
  archiveTicketing: BooleanGqe3Bijd;
  cancelCommandSubscription: CommandObjectEkF4Xpsa;
  cancelDonorCommandSubscription: CommandObject6ou1b2r8;
  cancelReceipt: ReceiptObjectZyiBdk9B;
  capturePaymentIntent: BooleanResponseObject;
  changeContactEmailSubscription: BooleanT7er1OoZ;
  checkinProductTicket: Booleanud4nUlUf;
  checkinProductTicketByCommandId: BooleanEEfW0wz8;
  closeTicketing: BooleandbicoUgB;
  confirmPaymentIntent: CommandObjectZmZvTwaj;
  createBalanceTransferToIssuing: Boolean0pGjq6k5;
  createDonationFormFromTemplate: StringHl8K84Lq;
  createDonorFormTranslations: DonationFormObjectZjEx8C8j;
  createDraftCampaign: DonationFormObjectT8zdfs9n;
  createDraftDonationForm: DonationFormObjectuFw4Os0k;
  createDraftTicketing: StringF9y3WLeA;
  createFormTranslations: DonationFormObjectWUjIRr53;
  createFrontendDonor: UserResponseObject;
  createFundraiserFormTranslations: DonationFormObject1vaoV6iC;
  createIssuingPayout: CreatedIssuingPayoutObjectKprQZf4Q;
  createIssuingTopup: BooleanxUy1AJdx;
  createNewOrganization: AccessTokenObjectjrEixuYp;
  createNote: NoteResponseObject;
  createPlaidLinkToken: StringavrJn9Ni;
  createPreviewReceipt: StringGWxzmVlj;
  createReceipt: ReceiptResponseObject;
  createSampleContact: ContactObjectyd52FnlF;
  createSampleTransaction: TransactionObjectSiiMrhql;
  createStripeCheckoutSession: CheckoutSessionResponseObject;
  createStripeCustomExternalAccount: ExternalAccountObjectl4800Bss;
  createTags: StringListResponseObject;
  createTicketingCardPaymentIntent: PaymentIntentResponseObject;
  deactivateOrganization: BooleanResponseObject;
  deactiveTwoFactorAuth: Boolean4aVi8Xr9;
  deleteCampaign: NumberFg0qsYAy;
  deleteContact: BooleanResponseObject;
  deleteDonationForm: BooleanS1CqJuGs;
  deleteEmailTemplate: EmailTemplateResponseObject;
  deleteMemberToOrganizationAdministrators: UserObjectaD0e4cDg;
  deleteNote?: Maybe<StringResponseObject>;
  deleteSampleContact: BooleanZMmein5A;
  deleteSampleTransaction: BooleanxRfKmRzq;
  deleteTagForAllUsers: BooleanResponseObject;
  deleteTags: BooleanResponseObject;
  deleteTeam: BooleanlcOfm0Yl;
  deleteTicketing: BooleanqcqDQzb2;
  deleteUser: Booleanj1lPsDKf;
  disableGoogleSsoLogin: BooleanZSm7l5tV;
  dismissActivationPayoutSuccessCard: Booleanvhk1u516;
  displayEngagementChecklist: BooleanAhHy1jxB;
  editAnswer: AnswerResponseObject;
  editCommandSubscriptionAmount: CommandResponseObject;
  editContact: ContactResponseObject;
  editContactEmail: UserObject1jx9IfMp;
  editDonationCommandForm: CommandResponseObject;
  editDonorCommandSubscriptionAmount: CommandResponseObject;
  editDonorFormAdvancedParameters: DonationFormObject68kkMiJh;
  editDonorFormCustomization: DonationFormObject2nIYlt89;
  editDonorFormGeneralInformations: DonationFormObjectwtKa3FjY;
  editFormAdvancedParameters: DonationFormObjectrVwsSbQx;
  editFormCustomization: DonationFormObjectrmYFwVrv;
  editFormGeneralInformations: DonationFormObject7FVwVpfe;
  editFundraiserFormAdvancedParameters: DonationFormObjectyuPwxgmf;
  editFundraiserFormCustomization: DonationFormObjectHvmeo63P;
  editFundraiserFormGeneralInformations: DonationFormObjectkoQDopSo;
  editNote?: Maybe<NoteResponseObject>;
  editPassword: UserObjectkNfuLhOk;
  editTag: BooleanResponseObject;
  editTicketingAdvancedParameters: TicketingObjectY6kQ288u;
  editTicketingEventCustomization: TicketingObject6T08gEpK;
  editTicketingGeneralInformation: TicketingObject4W03Nj1G;
  editTicketingRates: TicketingObject4zf4p3Jo;
  editTicketingReminderData: TicketingObjectrJ40AiYs;
  editUnsubscribePreferences: BooleanBnCcfQxb;
  exportBalanceTransactionsTable: ExportResponseObject;
  exportDonorReceiptTable: ExportResponseObject;
  exportFundraiserList: ExportObjectaaFk2vPb;
  exportPayoutsTable: ExportResponseObject;
  exportTransactionsTable: ExportResponseObject;
  generatePreviewETicket: StringResponseObject;
  getCannyToken: StringResponseObject;
  getDiscountCodeFromTicketingIdAndBody: DiscountResponseObject;
  increasePayoutAmount: IncreasePayoutAmountObjectmUfDl9Az;
  insertTags: BooleanResponseObject;
  isUserEmailRegistered: IsUserEmailRegisteredResponseObject;
  isUserEmailRegisteredSSO: AccessTokenObjectLfVabbXo;
  launchImport: ImportUsersGoogleSheetObject9Gawj94e;
  mergeTeams: BooleanNtcomtMh;
  mergeUsers: MergeUsersObjectfzF7EsZl;
  moveParticipantToCampaign: Boolean4p7qk46m;
  openTicketing: TicketingObjectTVj6zdU3;
  postSubmitCommand: PostSubmitCommandObjectnY98Hjfo;
  processIssuingRewards: Boolean18sl8Nlt;
  processPayout: Boolean0akIasJz;
  purgeAllCache: Boolean0HWmY9Te;
  reOpenCommand: Booleanx3yfMSqa;
  refundTip: TransactionObjectx6YQbGtn;
  refundTransaction: RefundResponseObject;
  removeEngagementChecklist: BooleanxrK0yVzg;
  requestIssuingCard: Boolean2eqBxl4u;
  resendTransactionReceipt?: Maybe<StringResponseObject>;
  resendTwoFactorCode: BooleanS2KzqG5J;
  resetEngagementStepChecked: BooleanYDoHd8La;
  resetPassword: AccessTokenObjectrJhfKpmU;
  reverseDonationAnonymous: BooleannFDzo2Bp;
  saveCardOrder: CreateOrderResponseObject;
  saveDonationThankYouComment: CommandResponseObject;
  saveV1MobileCommand: CreateMobileCommandResponseObject;
  scanTicket: ProductTicketObjectqSw63vCa;
  sendMergeUserRequest: SendMergeUsersRequestResponseObject;
  sendMonthlyBalanceTransactionExport: BooleanbPeYspzJ;
  sendResetPasswordRequest: ResetPasswordLinkResponseObject;
  sendResetPasswordRequestMobile: ResetPasswordLinkResponseObject;
  sendTransactionReceiptForExistingCommand: CommandResponseObject;
  sendTwoFactorCode: BooleanjF5Hz1Hx;
  sendVerifyEmailNotification: BooleanvqqoDmRt;
  setHasDismissedEmailInfobox: Boolean3kow4dYe;
  setHasDismissedEmailTemplatePrompt: Boolean0W03O2W7;
  setHasDismissedReferralPrompt: Boolean3PlgjQ9s;
  setHasSeenReferralPage: BooleanResponseObject;
  setUserAsOnboardedOnCheckinManager: BooleanfFVyQt09;
  setUserCurrentOrganization: AccessTokenObjectmSxynZpj;
  setUserCurrentOrganizationMobile: AccessTokenObject;
  setVolumeThresholdReached: OrganizationResponseObject;
  signIn: AccessTokenObjectZuA5AgzL;
  signInAsAdministrator: AccessTokenObjectd5UcOYtl;
  signInMobile?: Maybe<AccessTokenObject>;
  signInMobileWithGoogle?: Maybe<AccessTokenObject>;
  signOut: Boolean6iQzU8kP;
  signUp: AccessTokenObjectBjc0GueY;
  switchOrgAsAdministrator: AccessTokenResponseObject;
  testModeAutomateOnboardingToStripe: BooleanResponseObject;
  trackDownload?: Maybe<Scalars['Boolean']>;
  triggerManualCron: BooleanVtPd9gIj;
  unarchiveCampaign: NumberxuzLHb05;
  unarchiveDonationForm: BooleanoxKUyLvw;
  unarchiveTicketing: BooleanCaIovkw8;
  unsubscribe: ContactResponseObject;
  unsubscribeUserFromEmails: BooleansJtAy9Bc;
  updateAccessTokenWithTargetedOrganization: Booleanyz50Vfwm;
  updateAdministrativeInformation: OrganizationObjectpLwaHBvr;
  updateAdministrativeMoreInformation: OrganizationObjectNtoAexP2;
  updateAmountsFromToken: StringvaYhEzPp;
  updateCompanyOnboardingQualification: BooleanResponseObject;
  updateHubspotContactProperty: BooleanX4rePyHt;
  updateOrganizationCategory: BooleanResponseObject;
  updateOrganizationEngagementFlags: BooleanResponseObject;
  updateOrganizationFlags: BooleanGnd6Zteu;
  updateOrganizationHubspotProperty: BooleanResponseObject;
  updateOrganizationOnboardedFormCategory: BooleanF4ryRcon;
  updateOrganizationWithStripeCustomFlowHasStarted: BooleanTqEsFe5B;
  updatePaymentIntent: UpdatePaymentIntentResponse;
  updatePayoutInterval: StripeAccountResponseObject;
  updateProductDonationAnnotation: BooleanfNhQdRIv;
  updateProductDonationAnonymous: BooleantBt59btI;
  updateProductTicketAnnotation: BooleanResponseObject;
  uploadChunkForImport: ChunkImportObject75mftgFj;
  upsertSession: SessionResponseObject;
  verifyEmail: VerifyEmailObjectughfeaZr;
  verifyTwoFactorCode: Booleancu2sQ1tm;
};


export type MutationActivateDonationFormArgs = {
  activateDonationFormInput: ActivateDonationFormInput;
};


export type MutationActivateTicketingArgs = {
  activateTicketingInput: ActivateTicketingInput;
};


export type MutationAddEngagementStepCheckedArgs = {
  activationStepId: Scalars['String'];
};


export type MutationAddManualContactArgs = {
  manualContactInput: ManualContactInput;
};


export type MutationAddRetroActiveReferralArgs = {
  retroActiveReferralInput: RetroActiveReferralInput;
};


export type MutationAddTicketingOffsetArgs = {
  offset: Scalars['Int'];
  ticketingIdOrPath: Scalars['String'];
};


export type MutationArchiveCampaignArgs = {
  campaignId: Scalars['String'];
};


export type MutationArchiveDonationFormArgs = {
  donationFormId: Scalars['String'];
};


export type MutationArchiveTeamArgs = {
  teamId: Scalars['String'];
};


export type MutationArchiveTicketingArgs = {
  ticketingId: Scalars['String'];
};


export type MutationCancelCommandSubscriptionArgs = {
  id: Scalars['String'];
};


export type MutationCancelDonorCommandSubscriptionArgs = {
  id: Scalars['String'];
};


export type MutationCancelReceiptArgs = {
  receiptId: Scalars['String'];
};


export type MutationCapturePaymentIntentArgs = {
  paymentIntentId: Scalars['String'];
};


export type MutationChangeContactEmailSubscriptionArgs = {
  unsubscribe: Scalars['Boolean'];
  userEmails: Array<Scalars['String']>;
};


export type MutationCheckinProductTicketArgs = {
  isCheckin: Scalars['Boolean'];
  productTicketId: Scalars['String'];
  token: Scalars['String'];
};


export type MutationCheckinProductTicketByCommandIdArgs = {
  commandId: Scalars['String'];
  isCheckin: Scalars['Boolean'];
  token: Scalars['String'];
};


export type MutationCloseTicketingArgs = {
  ticketingId: Scalars['String'];
};


export type MutationConfirmPaymentIntentArgs = {
  confirmPaymentIntentInput: ConfirmPaymentIntentInput;
};


export type MutationCreateBalanceTransferToIssuingArgs = {
  input: ProcessBalanceTransferInput;
};


export type MutationCreateDonationFormFromTemplateArgs = {
  template: DonationFormTemplateType;
};


export type MutationCreateDonorFormTranslationsArgs = {
  donationFormId: Scalars['String'];
  donationFormTranslations: EditDonationFormTranslationsInput;
};


export type MutationCreateDraftCampaignArgs = {
  donationForm: CreateDraftDonationFormInput;
};


export type MutationCreateDraftDonationFormArgs = {
  donationForm: CreateDraftDonationFormInput;
};


export type MutationCreateDraftTicketingArgs = {
  ticketing: CreateTicketingInput;
};


export type MutationCreateFormTranslationsArgs = {
  donationFormId: Scalars['String'];
  donationFormTranslations: EditDonationFormTranslationsInput;
};


export type MutationCreateFrontendDonorArgs = {
  donor: DonorInput;
};


export type MutationCreateFundraiserFormTranslationsArgs = {
  donationFormId: Scalars['String'];
  donationFormTranslations: EditDonationFormTranslationsInput;
  token: Scalars['String'];
};


export type MutationCreateIssuingPayoutArgs = {
  input: CreatedIssuingPayoutInput;
};


export type MutationCreateIssuingTopupArgs = {
  input: CreateIssuingTopupInput;
};


export type MutationCreateNewOrganizationArgs = {
  newOrganizationInput: CreateNewOrganizationInput;
};


export type MutationCreateNoteArgs = {
  content: Scalars['String'];
  userId: Scalars['String'];
};


export type MutationCreatePreviewReceiptArgs = {
  previewReceiptParams: PreviewReceiptParams;
};


export type MutationCreateReceiptArgs = {
  receipt: CreateReceiptInput;
};


export type MutationCreateStripeCheckoutSessionArgs = {
  customerId: Scalars['String'];
  subscriptionId: Scalars['String'];
};


export type MutationCreateStripeCustomExternalAccountArgs = {
  externalAccountInput: ExternalAccountInput;
};


export type MutationCreateTagsArgs = {
  tag: CreateTagInput;
};


export type MutationCreateTicketingCardPaymentIntentArgs = {
  paymentIntent: TicketingPaymentIntentInput;
};


export type MutationDeactivateOrganizationArgs = {
  input: DeactivateAccountInput;
};


export type MutationDeactiveTwoFactorAuthArgs = {
  param: DeactiveTwoFactorAuthInput;
};


export type MutationDeleteCampaignArgs = {
  campaignId: Scalars['String'];
};


export type MutationDeleteContactArgs = {
  userId: Scalars['String'];
};


export type MutationDeleteDonationFormArgs = {
  donationFormId: Scalars['String'];
  onlyDraft?: InputMaybe<Scalars['Boolean']>;
};


export type MutationDeleteEmailTemplateArgs = {
  id: Scalars['String'];
};


export type MutationDeleteMemberToOrganizationAdministratorsArgs = {
  email: Scalars['String'];
};


export type MutationDeleteNoteArgs = {
  noteId: Scalars['String'];
};


export type MutationDeleteSampleContactArgs = {
  contactId?: InputMaybe<Scalars['String']>;
};


export type MutationDeleteSampleTransactionArgs = {
  transactionId?: InputMaybe<Scalars['String']>;
};


export type MutationDeleteTagForAllUsersArgs = {
  tagId: Scalars['String'];
};


export type MutationDeleteTagsArgs = {
  tags: Array<InsertTagInput>;
};


export type MutationDeleteTeamArgs = {
  teamId: Scalars['String'];
};


export type MutationDeleteTicketingArgs = {
  onlyDraft?: InputMaybe<Scalars['Boolean']>;
  ticketingId: Scalars['String'];
};


export type MutationDeleteUserArgs = {
  input: DeleteUserAccountInput;
};


export type MutationDisableGoogleSsoLoginArgs = {
  userEmail: Scalars['String'];
};


export type MutationEditAnswerArgs = {
  answerInput: AnswerInput;
};


export type MutationEditCommandSubscriptionAmountArgs = {
  amount: Scalars['Float'];
  id: Scalars['String'];
};


export type MutationEditContactArgs = {
  editContactInput: EditContactInput;
  userId: Scalars['String'];
};


export type MutationEditContactEmailArgs = {
  email: Scalars['String'];
  userId: Scalars['String'];
};


export type MutationEditDonationCommandFormArgs = {
  donationFormId: Scalars['String'];
  id: Scalars['String'];
};


export type MutationEditDonorCommandSubscriptionAmountArgs = {
  amount: Scalars['Float'];
  id: Scalars['String'];
};


export type MutationEditDonorFormAdvancedParametersArgs = {
  donationForm: EditDonationFormInput;
};


export type MutationEditDonorFormCustomizationArgs = {
  donationForm: EditDonationFormInput;
};


export type MutationEditDonorFormGeneralInformationsArgs = {
  donationForm: EditDonationFormInput;
};


export type MutationEditFormAdvancedParametersArgs = {
  donationForm: EditDonationFormInput;
};


export type MutationEditFormCustomizationArgs = {
  donationForm: EditDonationFormInput;
};


export type MutationEditFormGeneralInformationsArgs = {
  donationForm: EditDonationFormInput;
};


export type MutationEditFundraiserFormAdvancedParametersArgs = {
  donationForm: EditDonationFormInput;
  token: Scalars['String'];
};


export type MutationEditFundraiserFormCustomizationArgs = {
  donationForm: EditDonationFormInput;
  token: Scalars['String'];
};


export type MutationEditFundraiserFormGeneralInformationsArgs = {
  donationForm: EditDonationFormInput;
  token: Scalars['String'];
};


export type MutationEditNoteArgs = {
  content: Scalars['String'];
  noteId: Scalars['String'];
};


export type MutationEditPasswordArgs = {
  editPasswordInput: EditPasswordInput;
};


export type MutationEditTagArgs = {
  tag: EditTagInput;
};


export type MutationEditTicketingAdvancedParametersArgs = {
  ticketing: EditTicketingInput;
};


export type MutationEditTicketingEventCustomizationArgs = {
  ticketing: EditTicketingInput;
};


export type MutationEditTicketingGeneralInformationArgs = {
  ticketing: EditTicketingInput;
};


export type MutationEditTicketingRatesArgs = {
  ticketing: EditTicketingInput;
};


export type MutationEditTicketingReminderDataArgs = {
  data: EditReminderDataInput;
};


export type MutationEditUnsubscribePreferencesArgs = {
  unsubscribePreferenceInput: UnsubscribePreferenceInput;
};


export type MutationExportBalanceTransactionsTableArgs = {
  exportParameters: ExportParametersInput;
  parameters?: InputMaybe<BalanceTransactionExportFilter>;
};


export type MutationExportDonorReceiptTableArgs = {
  exportParameters: ExportParametersInput;
  year: Scalars['Float'];
};


export type MutationExportFundraiserListArgs = {
  donationFormId: Scalars['String'];
  exportParameters: ExportParametersInput;
};


export type MutationExportPayoutsTableArgs = {
  exportParameters: ExportParametersInput;
};


export type MutationExportTransactionsTableArgs = {
  exportParameters: ExportParametersInput;
  parameters?: InputMaybe<TransactionExportFilter>;
};


export type MutationGeneratePreviewETicketArgs = {
  ticketPreviewParams: PreviewTicketParams;
};


export type MutationGetDiscountCodeFromTicketingIdAndBodyArgs = {
  discountCodeBody: Scalars['String'];
  occurrenceId?: InputMaybe<Scalars['String']>;
  ticketingId: Scalars['String'];
};


export type MutationIncreasePayoutAmountArgs = {
  input: IncreasePayoutAmountInput;
};


export type MutationInsertTagsArgs = {
  tags: Array<InsertTagInput>;
};


export type MutationIsUserEmailRegisteredArgs = {
  isEmailRegisteredInput: IsEmailRegisteredInput;
};


export type MutationIsUserEmailRegisteredSsoArgs = {
  isEmailRegisteredSSOInput: IsEmailRegisteredSsoInput;
};


export type MutationLaunchImportArgs = {
  base64: Scalars['String'];
  rowNum: Scalars['Float'];
  type: ImportType;
};


export type MutationMergeTeamsArgs = {
  teamToBeMergedIdOrPath: Scalars['String'];
  teamToMergeInIdOrPath: Scalars['String'];
};


export type MutationMergeUsersArgs = {
  encryptedToken: Scalars['String'];
  newEmail: Scalars['String'];
  oldEmail: Scalars['String'];
};


export type MutationMoveParticipantToCampaignArgs = {
  participantIdOrPath: Scalars['String'];
  targetedTeamIdOrPath: Scalars['String'];
};


export type MutationOpenTicketingArgs = {
  ticketingId: Scalars['String'];
};


export type MutationPostSubmitCommandArgs = {
  commandId: Scalars['String'];
};


export type MutationProcessPayoutArgs = {
  payoutId: Scalars['String'];
};


export type MutationReOpenCommandArgs = {
  commandId: Scalars['String'];
};


export type MutationRefundTipArgs = {
  amountToRefund: Scalars['Float'];
  id: Scalars['String'];
};


export type MutationRefundTransactionArgs = {
  amountToRefund: Scalars['Float'];
  cancelTaxReceipt: Scalars['Boolean'];
  id: Scalars['String'];
};


export type MutationRequestIssuingCardArgs = {
  cardHolderInput: CreateCardHolderInput;
};


export type MutationResendTransactionReceiptArgs = {
  email: Scalars['String'];
  transactionId: Scalars['String'];
};


export type MutationResendTwoFactorCodeArgs = {
  signInInput: SignInFrontendInput;
};


export type MutationResetPasswordArgs = {
  resetPasswordInput: ResetPasswordInput;
};


export type MutationReverseDonationAnonymousArgs = {
  donationFormIdOrPath: Scalars['String'];
  donorEmail: Scalars['String'];
};


export type MutationSaveCardOrderArgs = {
  editSessionInput: EditSessionOnSubmission;
  order: CreateOrderInput;
  ticketingId: Scalars['String'];
  tickets: Array<CreateTicketInput>;
};


export type MutationSaveDonationThankYouCommentArgs = {
  commandId: Scalars['String'];
  thankYouComment: Scalars['String'];
};


export type MutationSaveV1MobileCommandArgs = {
  createMobileCommandInput: CreateMobileCommandInput;
};


export type MutationScanTicketArgs = {
  ticketIdInput: UuidInput;
};


export type MutationSendMergeUserRequestArgs = {
  newEmail: Scalars['String'];
  oldEmail: Scalars['String'];
};


export type MutationSendResetPasswordRequestArgs = {
  sendResetPasswordRequestInput: SendResetPasswordRequestInput;
};


export type MutationSendResetPasswordRequestMobileArgs = {
  sendResetPasswordRequestInput: SendResetPasswordRequestInput;
};


export type MutationSendTransactionReceiptForExistingCommandArgs = {
  commandId: Scalars['String'];
  email: Scalars['String'];
};


export type MutationSendVerifyEmailNotificationArgs = {
  redirectionUrl?: InputMaybe<Scalars['String']>;
};


export type MutationSetUserCurrentOrganizationArgs = {
  organizationId: Scalars['String'];
};


export type MutationSetUserCurrentOrganizationMobileArgs = {
  organizationId: Scalars['String'];
};


export type MutationSetVolumeThresholdReachedArgs = {
  volumeThresholdReachedInput: UpdateVolumeThresholdReached;
};


export type MutationSignInArgs = {
  signInInput: SignInFrontendInput;
};


export type MutationSignInAsAdministratorArgs = {
  signInAsAdministratorInput: SignInAsAdministratorInput;
};


export type MutationSignInMobileArgs = {
  signInInput: SignInInput;
};


export type MutationSignInMobileWithGoogleArgs = {
  googleToken: Scalars['String'];
};


export type MutationSignUpArgs = {
  signUpInput: SignUpInput;
};


export type MutationSwitchOrgAsAdministratorArgs = {
  switchOrgAsAdministratorInput: SwitchOrgAsAdministratorInput;
};


export type MutationTrackDownloadArgs = {
  location: Scalars['String'];
};


export type MutationTriggerManualCronArgs = {
  cronName: CronQueues;
  delay?: InputMaybe<Scalars['Int']>;
  organizationId?: InputMaybe<Scalars['String']>;
};


export type MutationUnarchiveCampaignArgs = {
  campaignId: Scalars['String'];
};


export type MutationUnarchiveDonationFormArgs = {
  donationFormId: Scalars['String'];
};


export type MutationUnarchiveTicketingArgs = {
  ticketingId: Scalars['String'];
};


export type MutationUnsubscribeArgs = {
  contactId: Scalars['String'];
  emailDeliveryId: Scalars['String'];
};


export type MutationUnsubscribeUserFromEmailsArgs = {
  organizationId: Scalars['String'];
  unsubscribePreferenceInput: UnsubscribePreferenceInput;
  userId: Scalars['String'];
};


export type MutationUpdateAccessTokenWithTargetedOrganizationArgs = {
  targetOrganizationId?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateAdministrativeInformationArgs = {
  updateAdministrativeInformationInput: UpdateAdministrativeInformationInput;
};


export type MutationUpdateAdministrativeMoreInformationArgs = {
  updateAdministrativeMoreInformationInput: UpdateAdministrativeMoreInformationInput;
};


export type MutationUpdateAmountsFromTokenArgs = {
  token: Scalars['String'];
};


export type MutationUpdateCompanyOnboardingQualificationArgs = {
  qualificationInput: OnboardingQualificationInput;
};


export type MutationUpdateHubspotContactPropertyArgs = {
  payload: HubspotProperty;
};


export type MutationUpdateOrganizationCategoryArgs = {
  category: OrganizationCategory;
};


export type MutationUpdateOrganizationEngagementFlagsArgs = {
  updateOrganizationEngagementFlagsInput: UpdateOrganizationEngagementFlagsInput;
};


export type MutationUpdateOrganizationFlagsArgs = {
  input: OrganizationFlagsInput;
};


export type MutationUpdateOrganizationHubspotPropertyArgs = {
  input: UpdateOrganizationHubspotPropertyInput;
};


export type MutationUpdateOrganizationOnboardedFormCategoryArgs = {
  onboardedFormCategory: FormCategory;
};


export type MutationUpdatePaymentIntentArgs = {
  params: UpdatePaymentIntentInput;
};


export type MutationUpdatePayoutIntervalArgs = {
  updatePayoutInterval: UpdatePayoutInterval;
};


export type MutationUpdateProductDonationAnnotationArgs = {
  annotation?: InputMaybe<Scalars['String']>;
  productDonationId: Scalars['String'];
};


export type MutationUpdateProductDonationAnonymousArgs = {
  productDonationId: Scalars['String'];
  value?: InputMaybe<Scalars['Boolean']>;
};


export type MutationUpdateProductTicketAnnotationArgs = {
  annotation?: InputMaybe<Scalars['String']>;
  productTicketId: Scalars['String'];
};


export type MutationUploadChunkForImportArgs = {
  chunk: Scalars['String'];
  chunkIndex: Scalars['Float'];
  importId?: InputMaybe<Scalars['String']>;
  isLastChunk: Scalars['Boolean'];
  type: ImportType;
};


export type MutationUpsertSessionArgs = {
  sessionInput: UpsertSessionInput;
};


export type MutationVerifyEmailArgs = {
  email: Scalars['String'];
  verifyEmailToken: Scalars['String'];
};


export type MutationVerifyTwoFactorCodeArgs = {
  value: Scalars['String'];
};

export type MyReferralOrganizationObject = {
  __typename?: 'MyReferralOrganizationObject';
  count: Scalars['Float'];
  email: Scalars['String'];
  id: Scalars['String'];
  isStripeCustomAccountActive: Scalars['Boolean'];
  name: Scalars['String'];
};

export type MyReferralOrganizationObjectLsxMzK6b = {
  __typename?: 'MyReferralOrganizationObjectLsxMzK6b';
  error?: Maybe<MyReferralOrganizationObjectLsxMzK6bErrorObject>;
  items?: Maybe<Array<MyReferralOrganizationObject>>;
};

export type MyReferralOrganizationObjectLsxMzK6bErrorObject = {
  __typename?: 'MyReferralOrganizationObjectLsxMzK6bErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type MyReferrerOrganizationObject = {
  __typename?: 'MyReferrerOrganizationObject';
  moneyGathered: Scalars['Float'];
  organization: OrganizationObject;
};

export type MyReferrerOrganizationResponseObject = {
  __typename?: 'MyReferrerOrganizationResponseObject';
  error?: Maybe<MyReferrerOrganizationResponseObjectErrorObject>;
  object?: Maybe<MyReferrerOrganizationObject>;
};

export type MyReferrerOrganizationResponseObjectErrorObject = {
  __typename?: 'MyReferrerOrganizationResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type NewNonprofitObject = {
  __typename?: 'NewNonprofitObject';
  city?: Maybe<Scalars['String']>;
  country: OrganizationCountry;
  einBn: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  state?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};

export type NewNonprofitObject0nKfSltb = {
  __typename?: 'NewNonprofitObject0nKfSltb';
  error?: Maybe<NewNonprofitObject0nKfSltbErrorObject>;
  items?: Maybe<Array<NewNonprofitObject>>;
};

export type NewNonprofitObject0nKfSltbErrorObject = {
  __typename?: 'NewNonprofitObject0nKfSltbErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum NewSubscriptionStatus {
  Active = 'Active',
  Canceled = 'Canceled',
  Created = 'Created',
  PastDue = 'PastDue'
}

export type NextActionObject = {
  __typename?: 'NextActionObject';
  paymentIntentSecret?: Maybe<Scalars['String']>;
  redirectToUrl?: Maybe<RedirectToUrlObject>;
  type?: Maybe<NextActionType>;
};

export enum NextActionType {
  OpenAchModal = 'OpenAchModal',
  OpenPadModal = 'OpenPadModal',
  ThreeDSecureRedirect = 'ThreeDSecureRedirect'
}

export type NoteObject = {
  __typename?: 'NoteObject';
  contactId: Scalars['String'];
  content: Scalars['String'];
  createdAtUtc: Scalars['Date'];
  id: Scalars['String'];
  writer?: Maybe<UserObject>;
  writerId: Scalars['String'];
};

export type NoteResponseListObject = {
  __typename?: 'NoteResponseListObject';
  error?: Maybe<NoteResponseListObjectErrorObject>;
  items?: Maybe<Array<NoteObject>>;
};

export type NoteResponseListObjectErrorObject = {
  __typename?: 'NoteResponseListObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type NoteResponseObject = {
  __typename?: 'NoteResponseObject';
  error?: Maybe<NoteResponseObjectErrorObject>;
  object?: Maybe<NoteObject>;
};

export type NoteResponseObjectErrorObject = {
  __typename?: 'NoteResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type Number56NTfXhi = {
  __typename?: 'Number56NTfXhi';
  error?: Maybe<Number56NTfXhiErrorObject>;
  object?: Maybe<Scalars['Float']>;
};

export type Number56NTfXhiErrorObject = {
  __typename?: 'Number56NTfXhiErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type NumberFg0qsYAy = {
  __typename?: 'NumberFg0qsYAy';
  error?: Maybe<NumberFg0qsYAyErrorObject>;
  object?: Maybe<Scalars['Float']>;
};

export type NumberFg0qsYAyErrorObject = {
  __typename?: 'NumberFg0qsYAyErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type NumberResponseObject = {
  __typename?: 'NumberResponseObject';
  error?: Maybe<NumberResponseObjectErrorObject>;
  object?: Maybe<Scalars['Float']>;
};

export type NumberResponseObject0nfzAo7E = {
  __typename?: 'NumberResponseObject0nfzAO7E';
  error?: Maybe<NumberResponseObject0nfzAo7EErrorObject>;
  object?: Maybe<NumberResponseObject>;
};

export type NumberResponseObject0nfzAo7EErrorObject = {
  __typename?: 'NumberResponseObject0nfzAO7EErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type NumberResponseObjectErrorObject = {
  __typename?: 'NumberResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type NumberaBviMgLe = {
  __typename?: 'NumberaBviMgLe';
  error?: Maybe<NumberaBviMgLeErrorObject>;
  object?: Maybe<Scalars['Float']>;
};

export type NumberaBviMgLeErrorObject = {
  __typename?: 'NumberaBviMgLeErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type NumberxuzLHb05 = {
  __typename?: 'NumberxuzLHb05';
  error?: Maybe<NumberxuzLHb05ErrorObject>;
  object?: Maybe<Scalars['Float']>;
};

export type NumberxuzLHb05ErrorObject = {
  __typename?: 'NumberxuzLHb05ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type NumberzzDsE4oI = {
  __typename?: 'NumberzzDsE4oI';
  error?: Maybe<NumberzzDsE4oIErrorObject>;
  object?: Maybe<Scalars['Float']>;
};

export type NumberzzDsE4oIErrorObject = {
  __typename?: 'NumberzzDsE4oIErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type OccurrenceWithRatesListResponseObjectErrorObject = {
  __typename?: 'OccurrenceWithRatesListResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type OccurrenceWithRatesObject = {
  __typename?: 'OccurrenceWithRatesObject';
  occurrence?: Maybe<TicketingOccurrenceObject>;
  rates: Array<RateByOccurrenceObject>;
};

export enum OnboardingQualification {
  Large = 'Large',
  Medium = 'Medium',
  Other = 'Other',
  Small = 'Small'
}

export type OnboardingQualificationInput = {
  onboardingQualification: OnboardingQualification;
};

export type OrderObject = {
  __typename?: 'OrderObject';
  address?: Maybe<Scalars['String']>;
  amount: Scalars['Float'];
  answers?: Maybe<Array<AnswerObject>>;
  city?: Maybe<Scalars['String']>;
  commandId?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  contact?: Maybe<ContactObject>;
  country: Scalars['String'];
  createdAtUtc: Scalars['Date'];
  createdCampaigns?: Maybe<CreatedCampaignsObject>;
  description?: Maybe<Scalars['String']>;
  discount?: Maybe<DiscountObject>;
  discountId?: Maybe<Scalars['String']>;
  eTicketUrl?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  extraDonation?: Maybe<Scalars['Float']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isImported: Scalars['Boolean'];
  lastName?: Maybe<Scalars['String']>;
  lastTransactionId?: Maybe<Scalars['String']>;
  locale: Locales;
  occurrence?: Maybe<TicketingOccurrenceObject>;
  occurrenceId?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  ticketing: TicketingObject;
  ticketingId: Scalars['String'];
  tickets?: Maybe<Array<TicketObject>>;
  tip: Scalars['Float'];
  transactions?: Maybe<Array<TransactionObject>>;
  user?: Maybe<UserObject>;
  userId?: Maybe<Scalars['String']>;
};

export type OrderResponseObjectErrorObject = {
  __typename?: 'OrderResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type OrganizationAndOwnerObject = {
  __typename?: 'OrganizationAndOwnerObject';
  email: Scalars['String'];
  name: Scalars['String'];
  organizationId: Scalars['String'];
  role: Scalars['String'];
  userId: Scalars['String'];
};

export type OrganizationAndOwnerObjectK4KOkkWd = {
  __typename?: 'OrganizationAndOwnerObjectK4KOkkWd';
  error?: Maybe<OrganizationAndOwnerObjectK4KOkkWdErrorObject>;
  items?: Maybe<Array<OrganizationAndOwnerObject>>;
};

export type OrganizationAndOwnerObjectK4KOkkWdErrorObject = {
  __typename?: 'OrganizationAndOwnerObjectK4KOkkWdErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum OrganizationCategory {
  AnimalWelfare = 'AnimalWelfare',
  CommunityService = 'CommunityService',
  Culture = 'Culture',
  Education = 'Education',
  Environment = 'Environment',
  Health = 'Health',
  Humanitarian = 'Humanitarian',
  Other = 'Other',
  Politic = 'Politic',
  Religion = 'Religion',
  SocialService = 'SocialService',
  SportsAndLeisure = 'SportsAndLeisure',
  StudentGroup = 'StudentGroup',
  Veterans = 'Veterans'
}

export enum OrganizationCause {
  Education = 'Education',
  Nature = 'Nature',
  Solidarity = 'Solidarity',
  SportAndCulture = 'SportAndCulture'
}

export enum OrganizationCountry {
  Canada = 'Canada',
  GreatBritain = 'GreatBritain',
  UnitedStates = 'UnitedStates'
}

export type OrganizationDetailsObject = {
  __typename?: 'OrganizationDetailsObject';
  closingDate?: Maybe<Scalars['Date']>;
  fundsRaised: Scalars['Float'];
  numberContacts: Scalars['Float'];
  numberForms: Scalars['Float'];
  numberOfReferrals: Scalars['Float'];
  numberPayments: Scalars['Float'];
  numberUserAccounts: Scalars['Float'];
};

export type OrganizationEngagementFlagsObject = {
  __typename?: 'OrganizationEngagementFlagsObject';
  engagementAddContactTour: Scalars['Boolean'];
  engagementContactNotesTour: Scalars['Boolean'];
  engagementContactPaymentsTour: Scalars['Boolean'];
  engagementContactTagsTour: Scalars['Boolean'];
  engagementContactsBulkTour: Scalars['Boolean'];
  engagementEmailRecipientsTour: Scalars['Boolean'];
  engagementEmailStatsTour: Scalars['Boolean'];
  engagementEmailTemplatesTour: Scalars['Boolean'];
  engagementPaymentDetailsTour: Scalars['Boolean'];
  engagementPaymentTour: Scalars['Boolean'];
  engagementPaymentsExportTour: Scalars['Boolean'];
  id: Scalars['String'];
};

export type OrganizationEngagementFlagsObjectKqT5BcIx = {
  __typename?: 'OrganizationEngagementFlagsObjectKqT5BcIX';
  error?: Maybe<OrganizationEngagementFlagsObjectKqT5BcIxErrorObject>;
  object?: Maybe<OrganizationEngagementFlagsObject>;
};

export type OrganizationEngagementFlagsObjectKqT5BcIxErrorObject = {
  __typename?: 'OrganizationEngagementFlagsObjectKqT5BcIXErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type OrganizationFlagsInput = {
  demotivateEmbeds?: InputMaybe<AmplitudeVariantValue>;
};

export type OrganizationGroupObject = {
  __typename?: 'OrganizationGroupObject';
  id: Scalars['String'];
  organization: OrganizationObject;
  organizationId: Scalars['String'];
  permissions: Array<UserPermissions>;
  role: UserRoles;
  user: UserObject;
};

export type OrganizationObject = {
  __typename?: 'OrganizationObject';
  activationChecklistExperiment?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  badTipperHubspotFlag?: Maybe<Scalars['Boolean']>;
  balanceTransferDelayDay: Scalars['Float'];
  bankDescriptor?: Maybe<Scalars['String']>;
  blockPayment: Scalars['Boolean'];
  canGenerateReceipt: Scalars['Boolean'];
  cardMaximumAmount?: Maybe<Scalars['Int']>;
  category?: Maybe<OrganizationCategory>;
  cause?: Maybe<OrganizationCause>;
  charityNumber?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  country: OrganizationCountry;
  createdAtUtc?: Maybe<Scalars['Date']>;
  demotivateEmbeds?: Maybe<AmplitudeVariantValue>;
  deprecatedMongoId?: Maybe<Scalars['String']>;
  dismissOnboardingOnFormPageAt?: Maybe<Scalars['Date']>;
  engagementCheckListStepChecked?: Maybe<Array<Scalars['String']>>;
  engagementChecklistExperiment?: Maybe<AmplitudeVariantValue>;
  featureIssuingEnabled: Scalars['Boolean'];
  featureTreasuryEnabled: Scalars['Boolean'];
  firstPaymentDate?: Maybe<Scalars['Date']>;
  fourthPaymentDate?: Maybe<Scalars['Date']>;
  fraudulent: Scalars['Boolean'];
  fraudulentAtUtc?: Maybe<Scalars['Date']>;
  hasAdministrativeInformation: Scalars['Boolean'];
  hasAtLeastOnePayment?: Maybe<Scalars['Boolean']>;
  hasCharityNumber: Scalars['Boolean'];
  hasDismissActivationPayoutSuccessCard?: Maybe<Scalars['Boolean']>;
  hasFinishZeffyOnboarding: Scalars['Boolean'];
  hasImportGmailContacts?: Maybe<Scalars['Boolean']>;
  hasImportMailchimpContacts?: Maybe<Scalars['Boolean']>;
  hasStripeCustomFlowStarted: Scalars['Boolean'];
  hasVisitorReports?: Maybe<Scalars['Boolean']>;
  hideFromZeffyMap: Scalars['Boolean'];
  hubspotNpoStarterDealId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isOnboarded?: Maybe<Scalars['Boolean']>;
  isPaymentDisabled?: Maybe<Scalars['Boolean']>;
  isPoBoxAddress?: Maybe<Scalars['Boolean']>;
  isStripeAccountDeprecated?: Maybe<Scalars['Boolean']>;
  isStripeCustomAccountActive: Scalars['Boolean'];
  lastPaymentDate?: Maybe<Scalars['Date']>;
  legalName?: Maybe<Scalars['String']>;
  logoUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  npoStarterStateCertificateUrl?: Maybe<Scalars['String']>;
  npoStarterToDoListStepChecked?: Maybe<Array<Scalars['String']>>;
  onboardedFormCategory?: Maybe<FormCategory>;
  organizationGroups?: Maybe<Array<OrganizationGroupObject>>;
  owner?: Maybe<UserObject>;
  ownerEmail?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  previewFirstFormAt?: Maybe<Scalars['Date']>;
  propublicaCCode?: Maybe<Scalars['Float']>;
  referralAmountLimit: Scalars['Float'];
  referralQuestion?: Maybe<ReferralQuestionChoice>;
  referralQuestionOther?: Maybe<Scalars['String']>;
  referredByOrganizationId?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  signatureUrl?: Maybe<Scalars['String']>;
  stripeCustomAccountActiveAtUtc?: Maybe<Scalars['Date']>;
  stripeCustomAccountId?: Maybe<Scalars['String']>;
  stripeTopupSourceId?: Maybe<Scalars['String']>;
  stripeUserId?: Maybe<Scalars['String']>;
  stripeVerificationSucceededBannerShown?: Maybe<Scalars['Boolean']>;
  tags?: Maybe<Array<TagObject>>;
  type?: Maybe<OrganizationType>;
  volumeThresholdReached?: Maybe<Scalars['Float']>;
  website?: Maybe<Scalars['String']>;
};

export type OrganizationObjectNtoAexP2 = {
  __typename?: 'OrganizationObjectNTOAexP2';
  error?: Maybe<OrganizationObjectNtoAexP2ErrorObject>;
  object?: Maybe<OrganizationObject>;
};

export type OrganizationObjectNtoAexP2ErrorObject = {
  __typename?: 'OrganizationObjectNTOAexP2ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type OrganizationObjectpLwaHBvr = {
  __typename?: 'OrganizationObjectpLwaHBvr';
  error?: Maybe<OrganizationObjectpLwaHBvrErrorObject>;
  object?: Maybe<OrganizationObject>;
};

export type OrganizationObjectpLwaHBvrErrorObject = {
  __typename?: 'OrganizationObjectpLwaHBvrErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type OrganizationPublicObject = {
  __typename?: 'OrganizationPublicObject';
  country: OrganizationCountry;
  id: Scalars['String'];
  logoUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  owner?: Maybe<OwnerObject>;
  phoneNumber?: Maybe<Scalars['String']>;
};

export type OrganizationPublicResponseListObjectErrorObject = {
  __typename?: 'OrganizationPublicResponseListObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type OrganizationPublicResponseObject = {
  __typename?: 'OrganizationPublicResponseObject';
  error?: Maybe<OrganizationPublicResponseObjectErrorObject>;
  object?: Maybe<OrganizationPublicObject>;
};

export type OrganizationPublicResponseObjectErrorObject = {
  __typename?: 'OrganizationPublicResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type OrganizationResponseListObjectErrorObject = {
  __typename?: 'OrganizationResponseListObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type OrganizationResponseObject = {
  __typename?: 'OrganizationResponseObject';
  error?: Maybe<OrganizationResponseObjectErrorObject>;
  object?: Maybe<OrganizationObject>;
};

export type OrganizationResponseObjectErrorObject = {
  __typename?: 'OrganizationResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum OrganizationType {
  Charity = 'Charity',
  Other = 'Other',
  Other501C = 'Other501C',
  Private = 'Private',
  Social = 'Social'
}

export type OverTimeFilterInput = {
  slot: OverTimeSlot;
};

export enum OverTimeSlot {
  fourteenDays = 'fourteenDays',
  oneMonth = 'oneMonth',
  sevenDays = 'sevenDays',
  seventyTwoHours = 'seventyTwoHours',
  twentyFourHours = 'twentyFourHours',
  twoHours = 'twoHours'
}

export type OwnerObject = {
  __typename?: 'OwnerObject';
  email?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
};

export type PageViewsSummaryItemObject = {
  __typename?: 'PageViewsSummaryItemObject';
  count: Scalars['Float'];
  time: Scalars['String'];
};

export type PageViewsSummaryObject = {
  __typename?: 'PageViewsSummaryObject';
  data?: Maybe<Array<PageViewsSummaryItemObject>>;
};

export type PageViewsSummaryResponseObject = {
  __typename?: 'PageViewsSummaryResponseObject';
  error?: Maybe<PageViewsSummaryResponseObjectErrorObject>;
  object?: Maybe<PageViewsSummaryObject>;
};

export type PageViewsSummaryResponseObjectErrorObject = {
  __typename?: 'PageViewsSummaryResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum PaymentIntentError {
  CardDeclined = 'CardDeclined',
  FormNotFound = 'FormNotFound',
  InsufficientCapabilitiesForTransfer = 'InsufficientCapabilitiesForTransfer',
  InsufficientFunds = 'InsufficientFunds',
  OwnerNotFound = 'OwnerNotFound',
  UnknownError = 'UnknownError'
}

export type PaymentIntentObject = {
  __typename?: 'PaymentIntentObject';
  clientSecret: Scalars['String'];
  paymentIntentId: Scalars['String'];
};

export type PaymentIntentResponseObject = {
  __typename?: 'PaymentIntentResponseObject';
  error?: Maybe<PaymentIntentResponseObjectErrorObject>;
  object?: Maybe<PaymentIntentObject>;
};

export type PaymentIntentResponseObjectErrorObject = {
  __typename?: 'PaymentIntentResponseObjectErrorObject';
  code?: Maybe<PaymentIntentError>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum PaymentMethod {
  Ach = 'Ach',
  ApplePayOrGooglePay = 'ApplePayOrGooglePay',
  Card = 'Card',
  Cash = 'Cash',
  CashApp = 'CashApp',
  Cheque = 'Cheque',
  Free = 'Free',
  InKind = 'InKind',
  Manual = 'Manual',
  Other = 'Other',
  Pad = 'Pad',
  Stocks = 'Stocks',
  TapToPay = 'TapToPay',
  Transfer = 'Transfer',
  Unknown = 'Unknown'
}

export enum PaymentStatus {
  AwaitingTransfer = 'AwaitingTransfer',
  Created = 'Created',
  Failed = 'Failed',
  Processing = 'Processing',
  Succeeded = 'Succeeded'
}

export enum PayoutInterval {
  Daily = 'Daily',
  Manual = 'Manual',
  Monthly = 'Monthly',
  Weekly = 'Weekly'
}

export type PayoutObject = {
  __typename?: 'PayoutObject';
  amount: Scalars['Float'];
  arrivalDate: Scalars['Date'];
  createdAt: Scalars['Date'];
  currency: AvailablePaymentCurrency;
  externalAccount: ExternalAccountObject;
  failureCode?: Maybe<Scalars['String']>;
  failureMessage?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  statementDescriptor?: Maybe<Scalars['String']>;
  status: StripePayoutStatus;
};

export type PayoutResponseObject = {
  __typename?: 'PayoutResponseObject';
  error?: Maybe<PayoutResponseObjectErrorObject>;
  object?: Maybe<PayoutObject>;
};

export type PayoutResponseObjectErrorObject = {
  __typename?: 'PayoutResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type PayoutsInfiniteListResponseObjectErrorObject = {
  __typename?: 'PayoutsInfiniteListResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type PostSubmitCommandObject = {
  __typename?: 'PostSubmitCommandObject';
  createFundraisingHandler?: Maybe<CreatedCampaignsObject>;
};

export type PostSubmitCommandObjectnY98Hjfo = {
  __typename?: 'PostSubmitCommandObjectnY98Hjfo';
  error?: Maybe<PostSubmitCommandObjectnY98HjfoErrorObject>;
  object?: Maybe<PostSubmitCommandObject>;
};

export type PostSubmitCommandObjectnY98HjfoErrorObject = {
  __typename?: 'PostSubmitCommandObjectnY98HjfoErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type PreviewReceiptParams = {
  amount?: InputMaybe<Scalars['Float']>;
  eligibleAmount?: InputMaybe<Scalars['Float']>;
  formType: FormType;
  locale?: InputMaybe<Scalars['String']>;
  rateName?: InputMaybe<Scalars['String']>;
  ticketingName?: InputMaybe<Scalars['String']>;
};

export type PreviewTicketParams = {
  address?: InputMaybe<Scalars['String']>;
  bannerPdf?: InputMaybe<Scalars['String']>;
  bannerUrl?: InputMaybe<Scalars['String']>;
  endUtc?: InputMaybe<Scalars['Date']>;
  eventTimezone?: InputMaybe<Scalars['String']>;
  formTitle?: InputMaybe<Scalars['String']>;
  organizationName?: InputMaybe<Scalars['String']>;
  rateTitle?: InputMaybe<Scalars['String']>;
  startUtc?: InputMaybe<Scalars['Date']>;
  ticketingColor?: InputMaybe<Scalars['String']>;
};

export type ProcessBalanceTransferInput = {
  offsetDay: Scalars['Float'];
};

export type ProductBidObject = {
  __typename?: 'ProductBidObject';
  amount: Scalars['Int'];
  createdAtUtc: Scalars['Date'];
  id: Scalars['String'];
  rate?: Maybe<RateObject>;
  rateId: Scalars['String'];
  status: ProductBidStatus;
};

export type ProductBidObjects8X3I3wU = {
  __typename?: 'ProductBidObjects8X3I3wU';
  error?: Maybe<ProductBidObjects8X3I3wUErrorObject>;
  items?: Maybe<Array<ProductBidObject>>;
};

export type ProductBidObjects8X3I3wUErrorObject = {
  __typename?: 'ProductBidObjects8X3I3wUErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum ProductBidStatus {
  Lost = 'Lost',
  Submitted = 'Submitted',
  Won = 'Won'
}

export type ProductDonationObject = {
  __typename?: 'ProductDonationObject';
  amount: Scalars['Int'];
  annotation?: Maybe<Scalars['String']>;
  commandId: Scalars['String'];
  currency: AvailablePaymentCurrency;
  id: Scalars['String'];
  inHonourEmailAddress?: Maybe<Scalars['String']>;
  inHonourEmailBody?: Maybe<Scalars['String']>;
  inHonourName?: Maybe<Scalars['String']>;
  isAnonymous: Scalars['Boolean'];
  isInHonour: Scalars['Boolean'];
  isRecurrent: Scalars['Boolean'];
  organizationId: Scalars['String'];
  recurrenceInterval: ProductDonationRecurrenceInterval;
  sendInHonourEmail: Scalars['Boolean'];
  thankYouComment?: Maybe<Scalars['String']>;
};

export enum ProductDonationRecurrenceInterval {
  Monthly = 'Monthly',
  OneTime = 'OneTime',
  Yearly = 'Yearly'
}

export type ProductDonationResponseObjectErrorObject = {
  __typename?: 'ProductDonationResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ProductTicketCountsObject = {
  __typename?: 'ProductTicketCountsObject';
  id: Scalars['String'];
  productTicketCheckedIn: Scalars['Int'];
  productTicketTotal: Scalars['Int'];
};

export type ProductTicketCountsObject2Wdr4Bv8 = {
  __typename?: 'ProductTicketCountsObject2Wdr4Bv8';
  error?: Maybe<ProductTicketCountsObject2Wdr4Bv8ErrorObject>;
  object?: Maybe<ProductTicketCountsObject>;
};

export type ProductTicketCountsObject2Wdr4Bv8ErrorObject = {
  __typename?: 'ProductTicketCountsObject2Wdr4Bv8ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ProductTicketListResponseObject = {
  __typename?: 'ProductTicketListResponseObject';
  error?: Maybe<ProductTicketListResponseObjectErrorObject>;
  items?: Maybe<Array<ProductTicketObject>>;
};

export type ProductTicketListResponseObjectErrorObject = {
  __typename?: 'ProductTicketListResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ProductTicketObject = {
  __typename?: 'ProductTicketObject';
  amount: Scalars['Int'];
  annotation?: Maybe<Scalars['String']>;
  answers?: Maybe<Array<AnswerObject>>;
  automaticRenewal?: Maybe<Scalars['Boolean']>;
  command?: Maybe<CommandObject>;
  commandId: Scalars['String'];
  currency: AvailablePaymentCurrency;
  expirationDate?: Maybe<Scalars['Date']>;
  groupTicketId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  organizationId: Scalars['String'];
  rate: RateObject;
  rateId: Scalars['String'];
  scanDates?: Maybe<Array<Scalars['Date']>>;
  status: ProductTicketStatus;
  ticketingIndex?: Maybe<Scalars['Float']>;
  transactionId?: Maybe<Scalars['String']>;
};

export type ProductTicketObjectqSw63vCa = {
  __typename?: 'ProductTicketObjectqSw63vCA';
  error?: Maybe<ProductTicketObjectqSw63vCaErrorObject>;
  object?: Maybe<ProductTicketObject>;
};

export type ProductTicketObjectqSw63vCaErrorObject = {
  __typename?: 'ProductTicketObjectqSw63vCAErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ProductTicketResponseObjectErrorObject = {
  __typename?: 'ProductTicketResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum ProductTicketStatus {
  Active = 'Active',
  Canceled = 'Canceled',
  CheckedIn = 'CheckedIn',
  Pending = 'Pending'
}

export type ProductTicketStatusCountPerTicketingPerOccurrenceObject = {
  __typename?: 'ProductTicketStatusCountPerTicketingPerOccurrenceObject';
  cancelledCount: Scalars['String'];
  checkedInCount: Scalars['String'];
  filteredRowsEmails: Array<Scalars['String']>;
  notCheckedInCount: Scalars['String'];
};

export type ProductTicketStatusCountPerTicketingPerOccurrenceObjectxmEq88md = {
  __typename?: 'ProductTicketStatusCountPerTicketingPerOccurrenceObjectxmEQ88md';
  error?: Maybe<ProductTicketStatusCountPerTicketingPerOccurrenceObjectxmEq88mdErrorObject>;
  object?: Maybe<ProductTicketStatusCountPerTicketingPerOccurrenceObject>;
};

export type ProductTicketStatusCountPerTicketingPerOccurrenceObjectxmEq88mdErrorObject = {
  __typename?: 'ProductTicketStatusCountPerTicketingPerOccurrenceObjectxmEQ88mdErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  checkIfResetPasswordIsValid: Boolean4ABkaUal;
  defaultQuery: Scalars['Boolean'];
  doesOrganizationHaveProductBids: BooleanCkcJEji7;
  donationForm: DonationFormObjectdXGd0uE5;
  donationFormWithRestrictedAccess: DonationFormObjectZcd8CkSz;
  fetchAndSetSkipAIChat: BooleangnXmrTyc;
  generateCheckinManagerUrl: String1mX11tU5;
  getActiveTicketingOccurrenceIds: Stringxibsuuhu;
  getAggregateProductTicketStatusCountPerTicketingPerOccurrence: ProductTicketStatusCountPerTicketingPerOccurrenceObjectxmEq88md;
  getAggregateRateFieldsByCommand: AggregateRateFieldsObjectGjO0L7qt;
  getApplicationInitialStatus: ApplicationInitialStatusResponseObject;
  getBalanceTransactionDetails: GetBalanceTransactionDetailsObjectsQRsINwD;
  getCampaignFormsCount: NumberaBviMgLe;
  getCampaignParticipantContacts: ContactObjectjMQeShZ3;
  getCampaignSubFormsCount: NumberResponseObject;
  getCampaignWithVolume?: Maybe<DonationFormResponseObject>;
  getCardVolume: CardVolumeResponseObject;
  getCommandAnswers: AnswerObjectHuYzUBzw;
  getContactCountByTag: NumberResponseObject;
  getContactDetails: ContactObject2S9Cljis;
  getContactKeyInfo: ContactKeyInfoObjectsOr2JumH;
  getContactsFormRecipientIds: ContactObjectSuyyzfuQ;
  getCurrentUser?: Maybe<GetCurrentUserObject>;
  getCurrentlyActiveOrganizations: CurrentlyActiveOrganizationResponseListObject;
  getDiscount: DiscountResponseObject;
  getDonationFormForManualDonation: DonationFormObjectt1Pz0erf;
  getDonationFormQuestions: QuestionListResponseObject;
  getDonationFormWithFundraiserAccess: DonationFormObjectnszkstDa;
  getDonationFormsFromCampaign: GetDonationFormsFromCampaignObject9qJJxen8;
  getDonationFormsList: DonationFormObject8wSj1y7m;
  getDonorVolume: DonorVolumeListResponseObject;
  getEmailDelivery: EmailDeliveryObjectak4wjxHb;
  getEmailTemplates: EmailTemplateResponseListObject;
  getEmailsCountByStatus: EmailsCountByStatusTableListResponseObject;
  getEphemeralKey: GetEphemeralKeyObjectW6hrp0zR;
  getFirstTransactionCreatedAtUtc: GetFirstTransactionCreatedAtUtcResponseObjecti9AoifeB;
  getFormVolume: NumberzzDsE4oI;
  getFrontendCurrentUser: GetCurrentUserFrontendObjectotwZLjOi;
  getFundraisingOrganization: OrganizationResponseObject;
  getHomeGraphData: HomeGraphResponseListObject;
  getImportStatus: ImportUsersGoogleSheetObject8PJm5RZp;
  getIsActivatedFeatureToggleBadTipperDisclaimer: BooleanpCa6Rq4M;
  getIsNewMobileFormCreationFlow: Boolean2wbedRfz;
  getIssuingCard: GetIssuingCardObjectycQMi12P;
  getIssuingRewardTransactions: GetIssuingRewardTransactionsObjectWx6hNvvb;
  getMarkerHoverDetails: MarkerHoverDetailsObject;
  getMarkerPositions: ClusterResponseObject;
  getMyReferralOrganizations: MyReferralOrganizationObjectLsxMzK6b;
  getMyReferrerInformation: MyReferrerOrganizationResponseObject;
  getNextReferralTransferAmount: NumberResponseObject;
  getNotes: NoteResponseListObject;
  getOrganizationAdmin: UserObjectyCErfFnd;
  getOrganizationEngagementFlags: OrganizationEngagementFlagsObjectKqT5BcIx;
  getOrganizationForms: FrontendFormObjectResponseListObject;
  getOrganizationHasAnyTag: NumberResponseObject;
  getOrganizationListByInput: OrganizationAndOwnerObjectK4KOkkWd;
  getOrganizationMembers: UserListResponseObject;
  getOrganizationOwner: UserResponseObject;
  getOrganizationSummary: GetOrganizationSummaryResponseObject;
  getOrganizationTags: TagListResponseObject;
  getPageViewsSummary: PageViewsSummaryResponseObject;
  getPayout: PayoutResponseObject;
  getProductTicketCountsForCheckin: ProductTicketCountsObject2Wdr4Bv8;
  getProductTicketsCount: NumberResponseObject0nfzAo7E;
  getReferredOrganization: OrganizationResponseObject;
  getScheduledPayout: GetScheduledPayoutObjectdcffSnx3;
  getSentryIssues: SentryIssueListResponseObject;
  getStripeAccountBalance: AccountBalancesObjectoZ8Y8waw;
  getStripeAccountLink: StringWpyJfmDt;
  getStripeCustomAccount: StripeAccountResponseObject;
  getTags: TagListResponseObject;
  getTagsByContactIds: ContactIdWithTagsObjectRyquQmzt;
  getTeamMembers: DonationFormObjectFYySa2Hc;
  getTeamWithVolume: DonationFormObjectKpndQcPt;
  getTeamsFromCampaignId: Array<DonationFormObject>;
  getTicketing: TicketingObjectC9kMfkHb;
  getTicketingCategory: TicketingCategoryObjectopPSrO9D;
  getTicketingForManualOrder: TicketingObjectUflkSwQq;
  getTicketingInfoForCheckin: TicketingObjectb8hBr8ic;
  getTicketingList: TicketingObjectqveAeKfB;
  getTicketingReminderData: TicketingFieldObjectYxCxUzAs;
  getTipAndFeeAndRefundTipVolumeFromTransaction: TipNetVolumeResponseObject;
  getTipSuggestions: Array<TipSuggestionObject>;
  getTransaction: TransactionResponseObject;
  getTransactionCountOverTime: TransactionsOverTimeListResponseObject;
  getTransactionWithCommand: TransactionResponseObject;
  getTransactionsForIssuingBalanceTransfer: GetTransactionsForIssuingBalanceTransferObjectA9K8FtPh;
  getUnsubscribePreferences: UnsubscribePreferencesObjectOcGgtxX0;
  getVolumeAtDate: NumberResponseObject;
  getWebAnalytics: WebAnalyticsResponseObject;
  getWebhooksCountOverTime: WebhooksOverTimeListResponseObject;
  hasAnOccurrenceBefore: BooleanzH9HuK1a;
  hasAnyReceipt: BooleanbKOlaGTl;
  hasContacts: BooleanResponseObject;
  hasEmailDelivery: HasEmailDeliveryObjectzcdBMjsR;
  isAnExistingUser: Boolean67kg3kNa;
  listCampaignFormTitles: FormTitleListResponse;
  listCommandsFromByOccurrenceId: CommandObjectpO11aS9w;
  listDashboardCommandBidRates: BidRateObject0Hr8Ral5;
  listDashboardCommandForms: DashboardCommandFormListResponse;
  listDashboardCommandRates: DashboardCommandRatesListResponseObject;
  listDashboardContactTransactionsCount: DashboardCountObjectW4qidmYz;
  listDashboardTransactionsRates: TransactionRatesWithQuantityObjectQxNpghx4;
  listDonorDashboardCommandForms: DashboardCommandFormListResponse;
  listDonorDashboardTransactionsCount: IntResponseObject;
  listExpiringTransactionTickets: ProductTicketListResponseObject;
  listFormsForManualCommand: FrontendFormObject8k6VsyTe;
  listOrganizationFormTitles: FormTitleListResponse;
  listQuestionsWithChoices: QuestionListResponseObject;
  listTransactionBids: ProductBidObjects8X3I3wU;
  listTransactionTickets: ListTransactionTicketsResponse;
  listTransactionsWithFullyCanceledTickets: StringListResponseObject;
  organizationFormsAsMultiselectValues: MultiselectValueObjecteYYaMVsn;
  organizationOccurrencesAsMultiselectValues: MultiselectOccurrenceValueObjectYjBtm2Ox;
  organizationTagsAsMultiselectValues: MultiselectValueResponseListObject;
  retrieveCommand: CommandObjectgwD4jwCm;
  retrieveCommandAnswers: AnswerResponseListObject;
  retrieveContact: ContactResponseObject;
  retrieveDashboardTransaction: DashboardTransactionObjectOaqywskA;
  retrieveDonorCommand: CommandObjectClGd5P08;
  retrieveDonorDashboardSubscription: CommandSubscriptionResponseObject;
  retrieveDonorDashboardTransaction: DashboardTransactionObjectXrrAglI3;
  retrieveDonorReceipt: ReceiptResponseObject;
  retrieveForm: FrontendFormObjectResponseObject;
  retrieveOrganization: OrganizationPublicResponseObject;
  retrieveProductTicketAnswers: AnswerObjectuIjnRHnT;
  retrieveProductTicketAnswersForCheckin: AnswerObjectkJvfdHWv;
  retrieveReceipt: ReceiptResponseObject;
  retrieveTransactionReceipts: ReceiptObjectnDhEiMoa;
  retrieveUnsubscribeContactInformations: ContactResponseObject;
  searchNonprofit: NewNonprofitObject0nKfSltb;
  searchUnsplashImages: SearchUnsplashImagesResponseObject;
  shouldDisplayPostOccurrenceThankYouEmail: BooleandcJHbjeA;
  ticketingRatesAsMultiselectValues: MultiselectValueObjectGnJKsr0d;
};


export type QueryCheckIfResetPasswordIsValidArgs = {
  checkIfResetPasswordIsValidInput: CheckIfResetPasswordIsValidInput;
};


export type QueryDonationFormArgs = {
  donationFormIdOrUrlPath: Scalars['String'];
};


export type QueryDonationFormWithRestrictedAccessArgs = {
  donationFormIdInput: UuidInput;
};


export type QueryGenerateCheckinManagerUrlArgs = {
  occurrenceId?: InputMaybe<Scalars['String']>;
  ticketingId: Scalars['String'];
};


export type QueryGetActiveTicketingOccurrenceIdsArgs = {
  ticketingId: Scalars['String'];
};


export type QueryGetAggregateProductTicketStatusCountPerTicketingPerOccurrenceArgs = {
  occurrenceId?: InputMaybe<Scalars['String']>;
  ticketingId: Scalars['String'];
};


export type QueryGetAggregateRateFieldsByCommandArgs = {
  commandIds: Array<Scalars['String']>;
};


export type QueryGetBalanceTransactionDetailsArgs = {
  balanceTransactionId: Scalars['String'];
  type: BalanceTransactionType;
};


export type QueryGetCampaignFormsCountArgs = {
  campaignId: Scalars['String'];
};


export type QueryGetCampaignParticipantContactsArgs = {
  campaignId: Scalars['String'];
};


export type QueryGetCampaignSubFormsCountArgs = {
  campaignId: Scalars['String'];
};


export type QueryGetCampaignWithVolumeArgs = {
  campaignId: Scalars['String'];
};


export type QueryGetCardVolumeArgs = {
  parameters?: InputMaybe<HomeMetricsInput>;
};


export type QueryGetCommandAnswersArgs = {
  commandIds: Array<Scalars['String']>;
};


export type QueryGetContactCountByTagArgs = {
  tagId: Scalars['String'];
};


export type QueryGetContactDetailsArgs = {
  contactId: Scalars['String'];
};


export type QueryGetContactKeyInfoArgs = {
  contactId: Scalars['String'];
};


export type QueryGetContactsFormRecipientIdsArgs = {
  recipientIds: Array<Scalars['String']>;
};


export type QueryGetCurrentlyActiveOrganizationsArgs = {
  input?: InputMaybe<Scalars['String']>;
};


export type QueryGetDiscountArgs = {
  id: Scalars['String'];
};


export type QueryGetDonationFormForManualDonationArgs = {
  donationFormId: Scalars['String'];
};


export type QueryGetDonationFormQuestionsArgs = {
  donationFormId: Scalars['String'];
};


export type QueryGetDonationFormWithFundraiserAccessArgs = {
  donationFormIdInput: UuidInput;
  token: Scalars['String'];
};


export type QueryGetDonationFormsFromCampaignArgs = {
  campaignId: Scalars['String'];
  filters: DonationFormCampaignFilterInput;
};


export type QueryGetDonationFormsListArgs = {
  withArchived: Scalars['Boolean'];
};


export type QueryGetEmailDeliveryArgs = {
  emailDeliveryIdInput: UuidInput;
};


export type QueryGetEmailsCountByStatusArgs = {
  emailDeliveryId: Scalars['String'];
};


export type QueryGetEphemeralKeyArgs = {
  input: GetEphemeralKeyInput;
};


export type QueryGetFormVolumeArgs = {
  formInput: FormInput;
};


export type QueryGetFundraisingOrganizationArgs = {
  donationFormId: Scalars['String'];
};


export type QueryGetHomeGraphDataArgs = {
  parameters: HomeGraphInput;
};


export type QueryGetImportStatusArgs = {
  importId: Scalars['String'];
};


export type QueryGetMarkerHoverDetailsArgs = {
  getMarkerHoverDetailsInput: GetMarkerHoverDetailsInput;
};


export type QueryGetMarkerPositionsArgs = {
  getMarkerInput: GetMarkersInput;
};


export type QueryGetNotesArgs = {
  userId: Scalars['String'];
};


export type QueryGetOrganizationAdminArgs = {
  organizationId: Scalars['String'];
};


export type QueryGetOrganizationFormsArgs = {
  organizationId: Scalars['String'];
};


export type QueryGetOrganizationListByInputArgs = {
  searchOragnizationInput: Scalars['String'];
};


export type QueryGetOrganizationOwnerArgs = {
  organizationId: Scalars['String'];
};


export type QueryGetOrganizationSummaryArgs = {
  locale: Locales;
  organizationId: Scalars['String'];
};


export type QueryGetPageViewsSummaryArgs = {
  filters: OverTimeFilterInput;
};


export type QueryGetPayoutArgs = {
  id: Scalars['String'];
};


export type QueryGetProductTicketCountsForCheckinArgs = {
  token: Scalars['String'];
};


export type QueryGetProductTicketsCountArgs = {
  commandId: Scalars['String'];
};


export type QueryGetReferredOrganizationArgs = {
  params: GetReferredOrganizationInput;
};


export type QueryGetSentryIssuesArgs = {
  slot: SentrySlot;
};


export type QueryGetTagsArgs = {
  userId: Scalars['String'];
};


export type QueryGetTagsByContactIdsArgs = {
  contactIds: Array<Scalars['String']>;
};


export type QueryGetTeamMembersArgs = {
  teamId: Scalars['String'];
};


export type QueryGetTeamWithVolumeArgs = {
  teamId: Scalars['String'];
};


export type QueryGetTeamsFromCampaignIdArgs = {
  campaignId: Scalars['String'];
};


export type QueryGetTicketingArgs = {
  ticketingId: Scalars['String'];
};


export type QueryGetTicketingCategoryArgs = {
  params: UuidInput;
};


export type QueryGetTicketingForManualOrderArgs = {
  occurrenceId?: InputMaybe<Scalars['String']>;
  ticketingId: Scalars['String'];
};


export type QueryGetTicketingInfoForCheckinArgs = {
  token: Scalars['String'];
};


export type QueryGetTicketingReminderDataArgs = {
  ticketingId: Scalars['String'];
};


export type QueryGetTipSuggestionsArgs = {
  getTipSuggestionsInput: GetTipSuggestionsInput;
};


export type QueryGetTransactionArgs = {
  id: Scalars['String'];
};


export type QueryGetTransactionCountOverTimeArgs = {
  filters: OverTimeFilterInput;
};


export type QueryGetTransactionWithCommandArgs = {
  id: Scalars['String'];
};


export type QueryGetTransactionsForIssuingBalanceTransferArgs = {
  input: ProcessBalanceTransferInput;
};


export type QueryGetVolumeAtDateArgs = {
  endDate: Scalars['Date'];
  startDate: Scalars['Date'];
};


export type QueryGetWebAnalyticsArgs = {
  filters: OverTimeFilterInput;
};


export type QueryGetWebhooksCountOverTimeArgs = {
  filters: OverTimeFilterInput;
};


export type QueryHasAnOccurrenceBeforeArgs = {
  days: Scalars['Int'];
  ticketingId: Scalars['String'];
};


export type QueryIsAnExistingUserArgs = {
  email: Scalars['String'];
};


export type QueryListCampaignFormTitlesArgs = {
  campaignId: Scalars['String'];
};


export type QueryListCommandsFromByOccurrenceIdArgs = {
  occurrenceId: Scalars['String'];
};


export type QueryListDashboardCommandBidRatesArgs = {
  commandIds: Array<Scalars['String']>;
  locale: Locales;
};


export type QueryListDashboardCommandFormsArgs = {
  commandIds: Array<Scalars['String']>;
};


export type QueryListDashboardCommandRatesArgs = {
  commandIds: Array<Scalars['String']>;
  locale: Locales;
};


export type QueryListDashboardContactTransactionsCountArgs = {
  userId: Scalars['String'];
};


export type QueryListDashboardTransactionsRatesArgs = {
  locale: Locales;
  transactionIds: Array<Scalars['String']>;
};


export type QueryListDonorDashboardCommandFormsArgs = {
  commandIds: Array<Scalars['String']>;
};


export type QueryListExpiringTransactionTicketsArgs = {
  transactionIds: Array<Scalars['String']>;
};


export type QueryListQuestionsWithChoicesArgs = {
  occurrenceId?: InputMaybe<Scalars['String']>;
  questionIds: Array<Scalars['String']>;
};


export type QueryListTransactionBidsArgs = {
  transactionId: Scalars['String'];
};


export type QueryListTransactionTicketsArgs = {
  transactionId: Scalars['String'];
};


export type QueryListTransactionsWithFullyCanceledTicketsArgs = {
  transactionIds: Array<Scalars['String']>;
};


export type QueryOrganizationOccurrencesAsMultiselectValuesArgs = {
  formIds?: InputMaybe<Array<Scalars['String']>>;
};


export type QueryRetrieveCommandArgs = {
  id: Scalars['String'];
};


export type QueryRetrieveCommandAnswersArgs = {
  commandId: Scalars['String'];
};


export type QueryRetrieveContactArgs = {
  contactId: Scalars['String'];
};


export type QueryRetrieveDashboardTransactionArgs = {
  id: Scalars['String'];
};


export type QueryRetrieveDonorCommandArgs = {
  id: Scalars['String'];
};


export type QueryRetrieveDonorDashboardSubscriptionArgs = {
  id: Scalars['String'];
};


export type QueryRetrieveDonorDashboardTransactionArgs = {
  id: Scalars['String'];
};


export type QueryRetrieveDonorReceiptArgs = {
  id: Scalars['String'];
};


export type QueryRetrieveFormArgs = {
  formInput: FormInput;
};


export type QueryRetrieveOrganizationArgs = {
  id: Scalars['String'];
};


export type QueryRetrieveProductTicketAnswersArgs = {
  productTicketIds: Array<Scalars['String']>;
};


export type QueryRetrieveProductTicketAnswersForCheckinArgs = {
  productTicketIds: Array<Scalars['String']>;
  token: Scalars['String'];
};


export type QueryRetrieveReceiptArgs = {
  id: Scalars['String'];
};


export type QueryRetrieveTransactionReceiptsArgs = {
  transactionId: Scalars['String'];
};


export type QueryRetrieveUnsubscribeContactInformationsArgs = {
  contactId: Scalars['String'];
};


export type QuerySearchNonprofitArgs = {
  filters: SearchNonprofitFilterInput;
  query: Scalars['String'];
};


export type QuerySearchUnsplashImagesArgs = {
  searchUnsplashImagesInput: SearchUnsplashImagesInput;
};


export type QueryShouldDisplayPostOccurrenceThankYouEmailArgs = {
  occurrenceIdInput: UuidInput;
};


export type QueryTicketingRatesAsMultiselectValuesArgs = {
  formId?: InputMaybe<Scalars['String']>;
};

export type QuestionFieldInput = {
  body: Scalars['String'];
  id: Scalars['String'];
  locale: Locales;
  questionId?: InputMaybe<Scalars['String']>;
};

export type QuestionFieldObject = {
  __typename?: 'QuestionFieldObject';
  body: Scalars['String'];
  id: Scalars['String'];
  locale: Locales;
};

export type QuestionInput = {
  choices: Array<ChoiceInput>;
  donationFormId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  isArchived: Scalars['Boolean'];
  questionFields: Array<QuestionFieldInput>;
  ratesAssigned?: InputMaybe<Array<Scalars['String']>>;
  required: Scalars['Boolean'];
  sortIndex: Scalars['Float'];
  ticketingId?: InputMaybe<Scalars['String']>;
  type: QuestionType;
};

export type QuestionListResponseObject = {
  __typename?: 'QuestionListResponseObject';
  error?: Maybe<QuestionListResponseObjectErrorObject>;
  items?: Maybe<Array<QuestionObject>>;
};

export type QuestionListResponseObjectErrorObject = {
  __typename?: 'QuestionListResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type QuestionObject = {
  __typename?: 'QuestionObject';
  answers?: Maybe<Array<AnswerObject>>;
  choices: Array<ChoiceObject>;
  countAlreadyAnswered: Scalars['Float'];
  hasRate: Scalars['Boolean'];
  id: Scalars['String'];
  questionFields: Array<QuestionFieldObject>;
  rateQuestions?: Maybe<Array<RateQuestionObject>>;
  required: Scalars['Boolean'];
  sortIndex: Scalars['Float'];
  type: QuestionType;
};

export enum QuestionType {
  Checkbox = 'Checkbox',
  Date = 'Date',
  Email = 'Email',
  Multiple = 'Multiple',
  MultipleCheckboxes = 'MultipleCheckboxes',
  Name = 'Name',
  PhoneNumber = 'PhoneNumber',
  Simple = 'Simple',
  Utm = 'Utm'
}

export type RateByOccurrenceObject = {
  __typename?: 'RateByOccurrenceObject';
  rateId: Scalars['String'];
  remainingTickets?: Maybe<Scalars['Float']>;
  soldTicketsCount?: Maybe<Scalars['Float']>;
};

export type RateFieldObject = {
  __typename?: 'RateFieldObject';
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  locale: Locales;
  title: Scalars['String'];
};

export type RateFieldObjectWithCount = {
  __typename?: 'RateFieldObjectWithCount';
  attendeesByTicket?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  locale: Locales;
  rateFieldCount: Scalars['Float'];
  rateId: Scalars['String'];
  title: Scalars['String'];
};

export type RateListResponseObjectErrorObject = {
  __typename?: 'RateListResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type RateObject = {
  __typename?: 'RateObject';
  allowAutomaticRenewal?: Maybe<Scalars['Boolean']>;
  alreadyBoughtTicketsCount?: Maybe<Scalars['Float']>;
  amount: Scalars['Float'];
  attendeesByTicket?: Maybe<Scalars['Float']>;
  bidIncrement?: Maybe<Scalars['Float']>;
  earlyBirdCloseDate?: Maybe<Scalars['Date']>;
  earlyBirdCloseDateTimezone?: Maybe<Scalars['String']>;
  eligibleAmount?: Maybe<Scalars['Float']>;
  id: Scalars['String'];
  isRateFull?: Maybe<Scalars['Boolean']>;
  maximumToBuy: Scalars['Float'];
  membershipValidityPeriod?: Maybe<MembershipValidityPeriod>;
  membershipValidityPeriodAtDate?: Maybe<Scalars['Date']>;
  minimumToBuy?: Maybe<Scalars['Float']>;
  photoUrls?: Maybe<Array<Scalars['String']>>;
  rateFields?: Maybe<Array<RateFieldObject>>;
  remainingTickets?: Maybe<Scalars['Float']>;
  seats?: Maybe<Scalars['Float']>;
  sortIndex: Scalars['Float'];
  tickets?: Maybe<Array<TicketObject>>;
};

export type RateQuestionObject = {
  __typename?: 'RateQuestionObject';
  id: Scalars['String'];
  rateId: Scalars['String'];
};

export type RatesWithQuantityObject = {
  __typename?: 'RatesWithQuantityObject';
  quantity: Scalars['Int'];
  rateId: Scalars['String'];
  title: Scalars['String'];
};

export type ReceiptListResponseObjectErrorObject = {
  __typename?: 'ReceiptListResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ReceiptObject = {
  __typename?: 'ReceiptObject';
  address?: Maybe<Scalars['String']>;
  advantageAmount?: Maybe<Scalars['Float']>;
  advantageDescription?: Maybe<Scalars['String']>;
  amount: Scalars['Float'];
  annotation?: Maybe<Scalars['String']>;
  charityNumber: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  commandId?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  contact?: Maybe<ContactObject>;
  country: Scalars['String'];
  createdAtUtc: Scalars['Date'];
  donationDate: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  fileUrl?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  fullName: Scalars['String'];
  id: Scalars['String'];
  isImported: Scalars['Boolean'];
  isSample: Scalars['Boolean'];
  issuingAtUtc: Scalars['Date'];
  lastName?: Maybe<Scalars['String']>;
  organizationAddress: Scalars['String'];
  organizationCity: Scalars['String'];
  organizationCountry: OrganizationCountry;
  organizationName: Scalars['String'];
  organizationPostalCode: Scalars['String'];
  organizationRegion: Scalars['String'];
  postalCode?: Maybe<Scalars['String']>;
  receiptNumber: Scalars['String'];
  region?: Maybe<Scalars['String']>;
  status: ReceiptStatus;
  transactions?: Maybe<Array<TransactionObject>>;
  userId?: Maybe<Scalars['String']>;
};

export type ReceiptObjectZyiBdk9B = {
  __typename?: 'ReceiptObjectZyiBDK9B';
  error?: Maybe<ReceiptObjectZyiBdk9BErrorObject>;
  object?: Maybe<ReceiptObject>;
};

export type ReceiptObjectZyiBdk9BErrorObject = {
  __typename?: 'ReceiptObjectZyiBDK9BErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ReceiptObjectnDhEiMoa = {
  __typename?: 'ReceiptObjectnDHEiMoa';
  error?: Maybe<ReceiptObjectnDhEiMoaErrorObject>;
  object?: Maybe<ReceiptObject>;
};

export type ReceiptObjectnDhEiMoaErrorObject = {
  __typename?: 'ReceiptObjectnDHEiMoaErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ReceiptResponseObject = {
  __typename?: 'ReceiptResponseObject';
  error?: Maybe<ReceiptResponseObjectErrorObject>;
  object?: Maybe<ReceiptObject>;
};

export type ReceiptResponseObjectErrorObject = {
  __typename?: 'ReceiptResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum ReceiptStatus {
  Canceled = 'Canceled',
  Created = 'Created',
  Pending = 'Pending'
}

export type ReceiptTableListResponseObjectErrorObject = {
  __typename?: 'ReceiptTableListResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type RecipientObject = {
  __typename?: 'RecipientObject';
  emailAddress: Scalars['String'];
  id: Scalars['String'];
};

export type RedirectToUrlObject = {
  __typename?: 'RedirectToUrlObject';
  returnUrl?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export enum ReferralQuestionChoice {
  AsDonor = 'AsDonor',
  Capterra = 'Capterra',
  Nbctc = 'Nbctc',
  OrganicSearch = 'OrganicSearch',
  Other = 'Other',
  ReferralFromDonor = 'ReferralFromDonor',
  ReferralFromOrganization = 'ReferralFromOrganization',
  SocialNetwork = 'SocialNetwork'
}

export type RefundObject = {
  __typename?: 'RefundObject';
  id: Scalars['String'];
};

export type RefundResponseObject = {
  __typename?: 'RefundResponseObject';
  error?: Maybe<RefundResponseObjectErrorObject>;
  object?: Maybe<RefundObject>;
};

export type RefundResponseObjectErrorObject = {
  __typename?: 'RefundResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ResetPasswordInput = {
  email: Scalars['String'];
  newPassword: Scalars['String'];
  resetPasswordToken: Scalars['String'];
  verifyEmailToken: Scalars['String'];
};

export enum ResetPasswordLinkError {
  EmailDoesNotExist = 'EmailDoesNotExist'
}

export type ResetPasswordLinkObject = {
  __typename?: 'ResetPasswordLinkObject';
  user?: Maybe<UserObject>;
};

export type ResetPasswordLinkResponseObject = {
  __typename?: 'ResetPasswordLinkResponseObject';
  error?: Maybe<ResetPasswordLinkResponseObjectErrorObject>;
  object?: Maybe<ResetPasswordLinkObject>;
};

export type ResetPasswordLinkResponseObjectErrorObject = {
  __typename?: 'ResetPasswordLinkResponseObjectErrorObject';
  code?: Maybe<ResetPasswordLinkError>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type RetroActiveReferralInput = {
  orgReferredEmail: Scalars['String'];
  orgReferringEmail: Scalars['String'];
};

export type ScheduledPayoutObject = {
  __typename?: 'ScheduledPayoutObject';
  amount: Scalars['Float'];
  id: Scalars['String'];
};

export type SearchNonprofitFilterInput = {
  country?: InputMaybe<OrganizationCountry>;
};

export type SearchUnsplashImagesInput = {
  orderBy: UnsplashOrderBy;
  orientation?: InputMaybe<UnsplashOrientation>;
  page: Scalars['Float'];
  query?: InputMaybe<Scalars['String']>;
};

export type SearchUnsplashImagesObject = {
  __typename?: 'SearchUnsplashImagesObject';
  nextPage: Scalars['Float'];
  results: Array<UnsplashImageObject>;
  total: Scalars['Float'];
  total_pages: Scalars['Float'];
};

export type SearchUnsplashImagesResponseObject = {
  __typename?: 'SearchUnsplashImagesResponseObject';
  error?: Maybe<SearchUnsplashImagesResponseObjectErrorObject>;
  object?: Maybe<SearchUnsplashImagesObject>;
};

export type SearchUnsplashImagesResponseObjectErrorObject = {
  __typename?: 'SearchUnsplashImagesResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum SendEmailDeliveryError {
  DesignConfigMissing = 'DesignConfigMissing',
  EmailDeliveryNotFound = 'EmailDeliveryNotFound',
  FailedToExportUnlayerHtml = 'FailedToExportUnlayerHtml',
  FailedToGetOwnerEmail = 'FailedToGetOwnerEmail',
  FailedToGetRecipients = 'FailedToGetRecipients',
  FailedToGetReminderRecipients = 'FailedToGetReminderRecipients',
  FailedToGetSmartInviteRecipients = 'FailedToGetSmartInviteRecipients',
  FormNotFound = 'FormNotFound',
  LimitExceeded = 'LimitExceeded',
  ManyRecipientsInPreview = 'ManyRecipientsInPreview',
  SenderNoEmail = 'SenderNoEmail',
  SendingFailed = 'SendingFailed',
  StripeAccountNotActive = 'StripeAccountNotActive',
  UnexpectedError = 'UnexpectedError'
}

export type SendEmailDeliveryResponseObjectErrorObject = {
  __typename?: 'SendEmailDeliveryResponseObjectErrorObject';
  code?: Maybe<SendEmailDeliveryError>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type SendMergeUsersRequestResponseObject = {
  __typename?: 'SendMergeUsersRequestResponseObject';
  error?: Maybe<SendMergeUsersRequestResponseObjectErrorObject>;
  object?: Maybe<Scalars['String']>;
};

export type SendMergeUsersRequestResponseObjectErrorObject = {
  __typename?: 'SendMergeUsersRequestResponseObjectErrorObject';
  code?: Maybe<MergeUsersError>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type SendResetPasswordRequestInput = {
  email: Scalars['String'];
  locale: Locales;
};

export type SentryIssueListResponseObject = {
  __typename?: 'SentryIssueListResponseObject';
  error?: Maybe<SentryIssueListResponseObjectErrorObject>;
  items?: Maybe<Array<SentryIssueObject>>;
};

export type SentryIssueListResponseObjectErrorObject = {
  __typename?: 'SentryIssueListResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type SentryIssueObject = {
  __typename?: 'SentryIssueObject';
  count: Scalars['Float'];
  culprit?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  permalink?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  userCount: Scalars['Float'];
  value?: Maybe<Scalars['String']>;
};

export type SentryIssueResponseObjectErrorObject = {
  __typename?: 'SentryIssueResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum SentrySlot {
  OneHour = 'OneHour',
  SevenDays = 'SevenDays',
  SeventyTwoHours = 'SeventyTwoHours',
  TwentyFourHours = 'TwentyFourHours'
}

export type SessionObject = {
  __typename?: 'SessionObject';
  amount?: Maybe<Scalars['Float']>;
  createdAtUtc: Scalars['Date'];
  id: Scalars['String'];
};

export type SessionResponseObject = {
  __typename?: 'SessionResponseObject';
  error?: Maybe<SessionResponseObjectErrorObject>;
  object?: Maybe<SessionObject>;
};

export type SessionResponseObjectErrorObject = {
  __typename?: 'SessionResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type SignInAsAdministratorInput = {
  email: Scalars['String'];
  emailTarget: Scalars['String'];
  password: Scalars['String'];
};

export type SignInFrontendInput = {
  email?: InputMaybe<Scalars['String']>;
  googleToken?: InputMaybe<Scalars['String']>;
  locale: Locales;
  organizationId?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  smsCode?: InputMaybe<Scalars['String']>;
  targetOrganizationId?: InputMaybe<Scalars['String']>;
  telemetryId?: InputMaybe<Scalars['String']>;
  twoFactorCode?: InputMaybe<Scalars['String']>;
};

export type SignInInput = {
  email: Scalars['String'];
  encryptPassword?: InputMaybe<Scalars['String']>;
  locale: Locales;
  organizationId?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
};

export type SignUpInput = {
  charityNumber?: InputMaybe<Scalars['String']>;
  country: OrganizationCountry;
  email: Scalars['String'];
  firstName: Scalars['String'];
  googleToken?: InputMaybe<Scalars['String']>;
  hubspotCompanyId?: InputMaybe<Scalars['String']>;
  isMobile?: InputMaybe<Scalars['Boolean']>;
  isNonprofitSearchSuccessful?: InputMaybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  locale: Locales;
  npoStarterDealId?: InputMaybe<Scalars['String']>;
  organizationName: Scalars['String'];
  password: Scalars['String'];
  referralQuestionOther?: InputMaybe<Scalars['String']>;
  referredByOrganizationId?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<ReferralQuestionChoice>;
  ssoStytchData?: InputMaybe<SsoStytchData>;
  type?: InputMaybe<OrganizationType>;
  websiteUrl?: InputMaybe<Scalars['String']>;
};

export type SsoStytchData = {
  provider: Scalars['String'];
  stytchOrganizationId: Scalars['String'];
  stytchSessionJwt: Scalars['String'];
  stytchUserId: Scalars['String'];
};

export type String1mX11tU5 = {
  __typename?: 'String1mX11tU5';
  error?: Maybe<String1mX11tU5ErrorObject>;
  object?: Maybe<Scalars['String']>;
};

export type String1mX11tU5ErrorObject = {
  __typename?: 'String1mX11tU5ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type StringCuFAjg8i = {
  __typename?: 'StringCuFAjg8i';
  error?: Maybe<StringCuFAjg8iErrorObject>;
  object?: Maybe<Scalars['String']>;
};

export type StringCuFAjg8iErrorObject = {
  __typename?: 'StringCuFAjg8iErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type StringF9y3WLeA = {
  __typename?: 'StringF9y3WLeA';
  error?: Maybe<StringF9y3WLeAErrorObject>;
  object?: Maybe<Scalars['String']>;
};

export type StringF9y3WLeAErrorObject = {
  __typename?: 'StringF9y3WLeAErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type StringGWxzmVlj = {
  __typename?: 'StringGWxzmVLJ';
  error?: Maybe<StringGWxzmVljErrorObject>;
  object?: Maybe<Scalars['String']>;
};

export type StringGWxzmVljErrorObject = {
  __typename?: 'StringGWxzmVLJErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type StringHl8K84Lq = {
  __typename?: 'StringHL8K84LQ';
  error?: Maybe<StringHl8K84LqErrorObject>;
  object?: Maybe<Scalars['String']>;
};

export type StringHl8K84LqErrorObject = {
  __typename?: 'StringHL8K84LQErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type StringListResponseObject = {
  __typename?: 'StringListResponseObject';
  error?: Maybe<StringListResponseObjectErrorObject>;
  items?: Maybe<Array<Scalars['String']>>;
};

export type StringListResponseObjectErrorObject = {
  __typename?: 'StringListResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type StringResponseObject = {
  __typename?: 'StringResponseObject';
  error?: Maybe<StringResponseObjectErrorObject>;
  object?: Maybe<Scalars['String']>;
};

export type StringResponseObjectErrorObject = {
  __typename?: 'StringResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type StringWpyJfmDt = {
  __typename?: 'StringWPYJfmDT';
  error?: Maybe<StringWpyJfmDtErrorObject>;
  object?: Maybe<Scalars['String']>;
};

export type StringWpyJfmDtErrorObject = {
  __typename?: 'StringWPYJfmDTErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type StringavrJn9Ni = {
  __typename?: 'StringavrJN9Ni';
  error?: Maybe<StringavrJn9NiErrorObject>;
  object?: Maybe<Scalars['String']>;
};

export type StringavrJn9NiErrorObject = {
  __typename?: 'StringavrJN9NiErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type StringvaYhEzPp = {
  __typename?: 'StringvaYhEzPP';
  error?: Maybe<StringvaYhEzPpErrorObject>;
  object?: Maybe<Scalars['String']>;
};

export type StringvaYhEzPpErrorObject = {
  __typename?: 'StringvaYhEzPPErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type Stringxibsuuhu = {
  __typename?: 'Stringxibsuuhu';
  error?: Maybe<StringxibsuuhuErrorObject>;
  items?: Maybe<Array<Scalars['String']>>;
};

export type StringxibsuuhuErrorObject = {
  __typename?: 'StringxibsuuhuErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type StripeAccountObject = {
  __typename?: 'StripeAccountObject';
  capabilities: StripeCapabilities;
  country: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  externalAccount?: Maybe<ExternalAccountObject>;
  future_requirements?: Maybe<StripeRequirements>;
  hasAtLeastOnePayout?: Maybe<Scalars['Boolean']>;
  hasOnlyOnePayout?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  isBankConnected: Scalars['Boolean'];
  isRejectedByStripe?: Maybe<Scalars['Boolean']>;
  isRejectedByZeffy?: Maybe<Scalars['Boolean']>;
  lastPayout?: Maybe<PayoutObject>;
  payoutInterval: PayoutInterval;
  requirements?: Maybe<StripeRequirements>;
  type?: Maybe<Scalars['String']>;
};

export type StripeAccountResponseObject = {
  __typename?: 'StripeAccountResponseObject';
  error?: Maybe<StripeAccountResponseObjectErrorObject>;
  object?: Maybe<StripeAccountObject>;
};

export type StripeAccountResponseObjectErrorObject = {
  __typename?: 'StripeAccountResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type StripeCapabilities = {
  __typename?: 'StripeCapabilities';
  card_issuing?: Maybe<StripeCapabilityStatus>;
  transfers?: Maybe<StripeCapabilityStatus>;
  treasury?: Maybe<StripeCapabilityStatus>;
};

export enum StripeCapabilityStatus {
  Active = 'Active',
  Inactive = 'Inactive',
  Pending = 'Pending'
}

export type StripeCustomerObject = {
  __typename?: 'StripeCustomerObject';
  country: OrganizationCountry;
  id: Scalars['String'];
  stripeCustomerId: Scalars['String'];
  userId: Scalars['String'];
};

export enum StripePayoutStatus {
  canceled = 'canceled',
  failed = 'failed',
  inTransit = 'inTransit',
  paid = 'paid',
  pending = 'pending'
}

export type StripeRequirements = {
  __typename?: 'StripeRequirements';
  current_deadline?: Maybe<Scalars['Float']>;
  currently_due?: Maybe<Array<Scalars['String']>>;
  pending_verification?: Maybe<Array<Scalars['String']>>;
};

export enum SubscriptionRecurrenceInterval {
  Monthly = 'Monthly',
  Yearly = 'Yearly'
}

export enum SubscriptionStatus {
  Cancelled = 'Cancelled',
  InProgress = 'InProgress'
}

export type SwitchOrgAsAdministratorInput = {
  emailTarget: Scalars['String'];
};

export type TagListResponseObject = {
  __typename?: 'TagListResponseObject';
  error?: Maybe<TagListResponseObjectErrorObject>;
  items?: Maybe<Array<TagObject>>;
};

export type TagListResponseObjectErrorObject = {
  __typename?: 'TagListResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TagObject = {
  __typename?: 'TagObject';
  id: Scalars['String'];
  value: Scalars['String'];
};

export type TicketListResponseObjectErrorObject = {
  __typename?: 'TicketListResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TicketObject = {
  __typename?: 'TicketObject';
  amount: Scalars['Float'];
  annotation?: Maybe<Scalars['String']>;
  answers?: Maybe<Array<AnswerObject>>;
  automaticRenewal: Scalars['Boolean'];
  cancelled: Scalars['Boolean'];
  expirationDate?: Maybe<Scalars['Date']>;
  id: Scalars['String'];
  order: OrderObject;
  orderId: Scalars['String'];
  rate: RateObject;
  rateId: Scalars['String'];
  scanDates?: Maybe<Array<Scalars['Date']>>;
  ticketingIndex?: Maybe<Scalars['Float']>;
};

export type TicketResponseObjectErrorObject = {
  __typename?: 'TicketResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TicketingCategoryObject = {
  __typename?: 'TicketingCategoryObject';
  formCategory: TicketingFormCategory;
  id: Scalars['String'];
};

export type TicketingCategoryObjectopPSrO9D = {
  __typename?: 'TicketingCategoryObjectopPSrO9D';
  error?: Maybe<TicketingCategoryObjectopPSrO9DErrorObject>;
  object?: Maybe<TicketingCategoryObject>;
};

export type TicketingCategoryObjectopPSrO9DErrorObject = {
  __typename?: 'TicketingCategoryObjectopPSrO9DErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TicketingDetailsRate = {
  __typename?: 'TicketingDetailsRate';
  alreadyBoughtTicketsCount?: Maybe<Scalars['Float']>;
  fields: Array<RateFieldObject>;
  id: Scalars['String'];
  seats?: Maybe<Scalars['Int']>;
};

export type TicketingDonationFormObject = {
  __typename?: 'TicketingDonationFormObject';
  allowFundraiserCreation?: Maybe<Scalars['Boolean']>;
  allowTeamCreation?: Maybe<Scalars['Boolean']>;
  campaignId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isArchived: Scalars['Boolean'];
  registrationIncreaseThermometer: Scalars['Boolean'];
};

export type TicketingFeatureFlagObject = {
  __typename?: 'TicketingFeatureFlagObject';
  isPaymentByPadAllowed: Scalars['Boolean'];
};

export type TicketingFieldObject = {
  __typename?: 'TicketingFieldObject';
  bidLoserEmailContent?: Maybe<Scalars['String']>;
  bidLoserEmailObject?: Maybe<Scalars['String']>;
  chequeDescription?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  emailAttachmentUrl?: Maybe<Scalars['String']>;
  emailContent: Scalars['String'];
  emailObject: Scalars['String'];
  id: Scalars['String'];
  locale: Locales;
  offlinePaymentWording?: Maybe<Scalars['String']>;
  postEventBody?: Maybe<Scalars['String']>;
  postEventSubject?: Maybe<Scalars['String']>;
  postTransactionUrl?: Maybe<Scalars['String']>;
  reminderBody?: Maybe<Scalars['String']>;
  reminderSubject?: Maybe<Scalars['String']>;
  sanitizedDescription?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type TicketingFieldObjectYxCxUzAs = {
  __typename?: 'TicketingFieldObjectYxCXUzAs';
  error?: Maybe<TicketingFieldObjectYxCxUzAsErrorObject>;
  object?: Maybe<TicketingFieldObject>;
};

export type TicketingFieldObjectYxCxUzAsErrorObject = {
  __typename?: 'TicketingFieldObjectYxCXUzAsErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TicketingFieldResponseObjectErrorObject = {
  __typename?: 'TicketingFieldResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum TicketingFormCategory {
  Auction = 'Auction',
  Custom = 'Custom',
  Event = 'Event',
  Lottery = 'Lottery',
  Membership = 'Membership',
  MembershipV2 = 'MembershipV2',
  Shop = 'Shop'
}

export type TicketingListResponseObjectErrorObject = {
  __typename?: 'TicketingListResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TicketingObject = {
  __typename?: 'TicketingObject';
  address?: Maybe<Scalars['String']>;
  allowCheque: Scalars['Boolean'];
  askForAddress: Scalars['Boolean'];
  auctionSoldDate?: Maybe<Scalars['Date']>;
  bannerPdfUrl?: Maybe<Scalars['String']>;
  bannerUrl?: Maybe<Scalars['String']>;
  bannerVideoUrl?: Maybe<Scalars['String']>;
  closed: Scalars['Boolean'];
  color: Scalars['String'];
  coordinates: CoordinatesObject;
  discounts?: Maybe<Array<DiscountObject>>;
  displayAddressQuestion: Scalars['Boolean'];
  donationForm?: Maybe<TicketingDonationFormObject>;
  eventInvitationEmailDeliveryId?: Maybe<Scalars['String']>;
  eventInvitationEmailDeliveryScheduledFor?: Maybe<Scalars['Date']>;
  eventTimezone?: Maybe<Scalars['String']>;
  extraDonation: Scalars['Boolean'];
  formCategory: TicketingFormCategory;
  generateExtraDonationReceipt: Scalars['Boolean'];
  generateQrCode: Scalars['Boolean'];
  id: Scalars['String'];
  isArchived: Scalars['Boolean'];
  isTicketingFull: Scalars['Boolean'];
  liveFormEditorStep?: Maybe<Scalars['String']>;
  locale: Locales;
  logoUrl?: Maybe<Scalars['String']>;
  moneyGathered?: Maybe<Scalars['Float']>;
  notificationEmails?: Maybe<Scalars['NotificationEmailsScalar']>;
  occurrences?: Maybe<Array<TicketingOccurrenceObject>>;
  occurrencesWithRates?: Maybe<Array<OccurrenceWithRatesObject>>;
  orders?: Maybe<Array<OrderObject>>;
  organization?: Maybe<OrganizationObject>;
  organizationId?: Maybe<Scalars['String']>;
  path: Scalars['String'];
  postEventEmailDeliveryId?: Maybe<Scalars['String']>;
  postEventEmailDeliveryScheduledFor?: Maybe<Scalars['Date']>;
  postEventSendDateOffset?: Maybe<Scalars['Float']>;
  postTransactionUrl?: Maybe<Scalars['String']>;
  questions?: Maybe<Array<QuestionObject>>;
  rates?: Maybe<Array<RateObject>>;
  recurrences?: Maybe<Array<TicketingRecurrenceObject>>;
  remainingTickets?: Maybe<Scalars['Float']>;
  reminderSendDateOffset?: Maybe<Scalars['Float']>;
  seats?: Maybe<Scalars['Float']>;
  soldTicketsCount: Scalars['Float'];
  status?: Maybe<FormStatus>;
  stripeProductId?: Maybe<Scalars['String']>;
  thermometerTarget?: Maybe<Scalars['Float']>;
  ticketingFeatureFlagTreatment?: Maybe<TicketingFeatureFlagObject>;
  ticketingFields: Array<TicketingFieldObject>;
  withoutMap: Scalars['Boolean'];
};

export type TicketingObject4W03Nj1G = {
  __typename?: 'TicketingObject4W03Nj1G';
  error?: Maybe<TicketingObject4W03Nj1GErrorObject>;
  object?: Maybe<TicketingObject>;
};

export type TicketingObject4W03Nj1GErrorObject = {
  __typename?: 'TicketingObject4W03Nj1GErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TicketingObject4zf4p3Jo = {
  __typename?: 'TicketingObject4zf4p3Jo';
  error?: Maybe<TicketingObject4zf4p3JoErrorObject>;
  object?: Maybe<TicketingObject>;
};

export type TicketingObject4zf4p3JoErrorObject = {
  __typename?: 'TicketingObject4zf4p3JoErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TicketingObject6T08gEpK = {
  __typename?: 'TicketingObject6T08gEpK';
  error?: Maybe<TicketingObject6T08gEpKErrorObject>;
  object?: Maybe<TicketingObject>;
};

export type TicketingObject6T08gEpKErrorObject = {
  __typename?: 'TicketingObject6T08gEpKErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TicketingObjectC9kMfkHb = {
  __typename?: 'TicketingObjectC9kMfkHb';
  error?: Maybe<TicketingObjectC9kMfkHbErrorObject>;
  object?: Maybe<TicketingObject>;
};

export type TicketingObjectC9kMfkHbErrorObject = {
  __typename?: 'TicketingObjectC9kMfkHbErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TicketingObjectTVj6zdU3 = {
  __typename?: 'TicketingObjectTVj6zdU3';
  error?: Maybe<TicketingObjectTVj6zdU3ErrorObject>;
  object?: Maybe<TicketingObject>;
};

export type TicketingObjectTVj6zdU3ErrorObject = {
  __typename?: 'TicketingObjectTVj6zdU3ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TicketingObjectUflkSwQq = {
  __typename?: 'TicketingObjectUflkSwQq';
  error?: Maybe<TicketingObjectUflkSwQqErrorObject>;
  object?: Maybe<TicketingObject>;
};

export type TicketingObjectUflkSwQqErrorObject = {
  __typename?: 'TicketingObjectUflkSwQqErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TicketingObjectY6kQ288u = {
  __typename?: 'TicketingObjectY6kQ288u';
  error?: Maybe<TicketingObjectY6kQ288uErrorObject>;
  object?: Maybe<TicketingObject>;
};

export type TicketingObjectY6kQ288uErrorObject = {
  __typename?: 'TicketingObjectY6kQ288uErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TicketingObjectb8hBr8ic = {
  __typename?: 'TicketingObjectb8hBr8ic';
  error?: Maybe<TicketingObjectb8hBr8icErrorObject>;
  object?: Maybe<TicketingObject>;
};

export type TicketingObjectb8hBr8icErrorObject = {
  __typename?: 'TicketingObjectb8hBr8icErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TicketingObjecterB2vEr2 = {
  __typename?: 'TicketingObjecterB2vER2';
  error?: Maybe<TicketingObjecterB2vEr2ErrorObject>;
  object?: Maybe<TicketingObject>;
};

export type TicketingObjecterB2vEr2ErrorObject = {
  __typename?: 'TicketingObjecterB2vER2ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TicketingObjectqveAeKfB = {
  __typename?: 'TicketingObjectqveAEKfB';
  error?: Maybe<TicketingObjectqveAeKfBErrorObject>;
  items?: Maybe<Array<TicketingObject>>;
};

export type TicketingObjectqveAeKfBErrorObject = {
  __typename?: 'TicketingObjectqveAEKfBErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TicketingObjectrJ40AiYs = {
  __typename?: 'TicketingObjectrJ40AIYs';
  error?: Maybe<TicketingObjectrJ40AiYsErrorObject>;
  object?: Maybe<TicketingObject>;
};

export type TicketingObjectrJ40AiYsErrorObject = {
  __typename?: 'TicketingObjectrJ40AIYsErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TicketingOccurrenceObject = {
  __typename?: 'TicketingOccurrenceObject';
  closeDate?: Maybe<Scalars['Date']>;
  closed?: Maybe<Scalars['Boolean']>;
  endUtc: Scalars['Date'];
  eventReminderEmailDelivery?: Maybe<EmailDeliveryObject>;
  id: Scalars['String'];
  isArchived: Scalars['Boolean'];
  startUtc: Scalars['Date'];
  ticketingId: Scalars['String'];
};

export type TicketingPaymentIntentInput = {
  amount: Scalars['Float'];
  commandId?: InputMaybe<Scalars['String']>;
  connectedAccountId: Scalars['String'];
  email?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  locale: Locales;
  stripeCustomerId?: InputMaybe<Scalars['String']>;
  ticketingId: Scalars['String'];
  tip: Scalars['Float'];
  ttpoi?: InputMaybe<Scalars['Boolean']>;
};

export type TicketingRecurrenceInput = {
  closeDate?: InputMaybe<Scalars['Date']>;
  endUtc: Scalars['Date'];
  id: Scalars['String'];
  repeatUntilDate?: InputMaybe<Scalars['Date']>;
  startUtc: Scalars['Date'];
  type: TicketingRecurrenceType;
};

export type TicketingRecurrenceObject = {
  __typename?: 'TicketingRecurrenceObject';
  closeDate?: Maybe<Scalars['Date']>;
  endUtc: Scalars['Date'];
  id: Scalars['String'];
  isArchived: Scalars['Boolean'];
  repeatUntilDate?: Maybe<Scalars['Date']>;
  startUtc: Scalars['Date'];
  ticketingId?: Maybe<Scalars['String']>;
  type: TicketingRecurrenceType;
};

export enum TicketingRecurrenceType {
  Daily = 'Daily',
  Monthly = 'Monthly',
  Once = 'Once',
  WeekDays = 'WeekDays',
  Weekly = 'Weekly'
}

export type TicketingResponseObjectErrorObject = {
  __typename?: 'TicketingResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TicketingSummaryObject = {
  __typename?: 'TicketingSummaryObject';
  description: Scalars['String'];
  end?: Maybe<Scalars['Date']>;
  id: Scalars['String'];
  start?: Maybe<Scalars['Date']>;
};

export enum TipModelExperience {
  Model1 = 'Model1',
  ModelA = 'ModelA',
  ModelB = 'ModelB',
  ModelC = 'ModelC',
  ModelD = 'ModelD',
  ModelE = 'ModelE'
}

export type TipNetVolumeObject = {
  __typename?: 'TipNetVolumeObject';
  volumeFee: Scalars['Float'];
  volumeTip: Scalars['Float'];
};

export type TipNetVolumeResponseObject = {
  __typename?: 'TipNetVolumeResponseObject';
  error?: Maybe<TipNetVolumeResponseObjectErrorObject>;
  object?: Maybe<TipNetVolumeObject>;
};

export type TipNetVolumeResponseObjectErrorObject = {
  __typename?: 'TipNetVolumeResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TipSuggestionObject = {
  __typename?: 'TipSuggestionObject';
  amount: Scalars['Int'];
  percentage: Scalars['Int'];
};

export type TipSuggestionResponseListObjectErrorObject = {
  __typename?: 'TipSuggestionResponseListObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TransactionExportFilter = {
  endDate?: InputMaybe<Scalars['Date']>;
  startDate?: InputMaybe<Scalars['Date']>;
  stripePayoutId?: InputMaybe<Scalars['String']>;
};

export type TransactionObject = {
  __typename?: 'TransactionObject';
  command?: Maybe<CommandObject>;
  contact?: Maybe<ContactObject>;
  country: OrganizationCountry;
  createdAtUtc: Scalars['Date'];
  currency: AvailablePaymentCurrency;
  donation?: Maybe<DonationObject>;
  donationId?: Maybe<Scalars['String']>;
  hasBeenThanked: Scalars['Boolean'];
  hasRadarWarning: Scalars['Boolean'];
  id: Scalars['String'];
  inProgressRefundAmount: Scalars['Int'];
  isImported: Scalars['Boolean'];
  isSample?: Maybe<Scalars['Boolean']>;
  last4?: Maybe<Scalars['String']>;
  nextPaymentAmount?: Maybe<Scalars['Int']>;
  order?: Maybe<OrderObject>;
  orderId?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationObject>;
  paymentMethod: PaymentMethod;
  receipt?: Maybe<ReceiptObject>;
  receiptId?: Maybe<Scalars['String']>;
  refundCreatedAtUtc?: Maybe<Scalars['Date']>;
  refundableAmount?: Maybe<Scalars['Int']>;
  refundableTipAmount?: Maybe<Scalars['Int']>;
  refundedAmount?: Maybe<Scalars['Int']>;
  refundedTipAmount?: Maybe<Scalars['Int']>;
  status?: Maybe<PaymentStatus>;
  stripeChargeId?: Maybe<Scalars['String']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  tip: Scalars['Int'];
  totalAmount: Scalars['Int'];
  transferGroup?: Maybe<Scalars['String']>;
  transferrableAmount: Scalars['Float'];
};

export type TransactionObjectSiiMrhql = {
  __typename?: 'TransactionObjectSiiMRHQL';
  error?: Maybe<TransactionObjectSiiMrhqlErrorObject>;
  object?: Maybe<TransactionObject>;
};

export type TransactionObjectSiiMrhqlErrorObject = {
  __typename?: 'TransactionObjectSiiMRHQLErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TransactionObjectx6YQbGtn = {
  __typename?: 'TransactionObjectx6YQbGtn';
  error?: Maybe<TransactionObjectx6YQbGtnErrorObject>;
  object?: Maybe<TransactionObject>;
};

export type TransactionObjectx6YQbGtnErrorObject = {
  __typename?: 'TransactionObjectx6YQbGtnErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TransactionRatesWithQuantityObject = {
  __typename?: 'TransactionRatesWithQuantityObject';
  id: Scalars['String'];
  rates?: Maybe<Array<RatesWithQuantityObject>>;
};

export type TransactionRatesWithQuantityObjectQxNpghx4 = {
  __typename?: 'TransactionRatesWithQuantityObjectQxNPGHX4';
  error?: Maybe<TransactionRatesWithQuantityObjectQxNpghx4ErrorObject>;
  items?: Maybe<Array<TransactionRatesWithQuantityObject>>;
};

export type TransactionRatesWithQuantityObjectQxNpghx4ErrorObject = {
  __typename?: 'TransactionRatesWithQuantityObjectQxNPGHX4ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TransactionResponseObject = {
  __typename?: 'TransactionResponseObject';
  error?: Maybe<TransactionResponseObjectErrorObject>;
  object?: Maybe<TransactionObject>;
};

export type TransactionResponseObjectErrorObject = {
  __typename?: 'TransactionResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TransactionsOverTimeListResponseObject = {
  __typename?: 'TransactionsOverTimeListResponseObject';
  error?: Maybe<TransactionsOverTimeListResponseObjectErrorObject>;
  items?: Maybe<Array<TransactionsOverTimeObject>>;
};

export type TransactionsOverTimeListResponseObjectErrorObject = {
  __typename?: 'TransactionsOverTimeListResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TransactionsOverTimeObject = {
  __typename?: 'TransactionsOverTimeObject';
  created: Scalars['Float'];
  failed: Scalars['Float'];
  processing: Scalars['Float'];
  succeeded: Scalars['Float'];
  time: Scalars['Date'];
};

export type TransactionsOverTimeResponseObjectErrorObject = {
  __typename?: 'TransactionsOverTimeResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TranslationsDonationFormFieldInput = {
  chequeDescription?: InputMaybe<Scalars['String']>;
  choiceFields?: InputMaybe<Array<ChoiceFieldInput>>;
  description: Scalars['String'];
  donationFormAmountFields: Array<EditDonationFormAmountTranslationInput>;
  emailAttachmentUrl?: InputMaybe<Scalars['String']>;
  emailContent?: InputMaybe<Scalars['String']>;
  emailObject?: InputMaybe<Scalars['String']>;
  fundraiserEmailBody?: InputMaybe<Scalars['String']>;
  fundraisingRegistrationWording?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  locale: Locales;
  message?: InputMaybe<Scalars['String']>;
  questionsFields?: InputMaybe<Array<QuestionFieldInput>>;
  ticketingRegistrationWording?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  transferDescription?: InputMaybe<Scalars['String']>;
};

export type UnsplashImageObject = {
  __typename?: 'UnsplashImageObject';
  alt_description?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  height: Scalars['Float'];
  id: Scalars['String'];
  links: UnsplashLinks;
  urls: UnsplashUrls;
  user: UnsplashUser;
  width: Scalars['Float'];
};

export type UnsplashLinks = {
  __typename?: 'UnsplashLinks';
  download_location: Scalars['String'];
};

export enum UnsplashOrderBy {
  latest = 'latest',
  relevant = 'relevant'
}

export enum UnsplashOrientation {
  landscape = 'landscape',
  portrait = 'portrait',
  squarish = 'squarish'
}

export type UnsplashUrls = {
  __typename?: 'UnsplashUrls';
  full: Scalars['String'];
  raw: Scalars['String'];
  regular: Scalars['String'];
  small: Scalars['String'];
  thumb: Scalars['String'];
};

export type UnsplashUser = {
  __typename?: 'UnsplashUser';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type UnsubscribePreferenceInput = {
  unsubscribedFromEmailTypes?: InputMaybe<Array<EmailType>>;
};

export type UnsubscribePreferencesObject = {
  __typename?: 'UnsubscribePreferencesObject';
  id: Scalars['String'];
  unsubscribedFromEmailTypes: Array<EmailType>;
};

export type UnsubscribePreferencesObjectOcGgtxX0 = {
  __typename?: 'UnsubscribePreferencesObjectOcGgtxX0';
  error?: Maybe<UnsubscribePreferencesObjectOcGgtxX0ErrorObject>;
  object?: Maybe<UnsubscribePreferencesObject>;
};

export type UnsubscribePreferencesObjectOcGgtxX0ErrorObject = {
  __typename?: 'UnsubscribePreferencesObjectOcGgtxX0ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type UpdateAdministrativeInformationInput = {
  address?: InputMaybe<Scalars['String']>;
  bankDescriptor?: InputMaybe<Scalars['String']>;
  charityNumber?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  hasFinishZeffyOnboarding?: InputMaybe<Scalars['Boolean']>;
  hideFromZeffyMap?: InputMaybe<Scalars['Boolean']>;
  isPoBoxAddress: Scalars['Boolean'];
  logoUrl?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  propublicaCCode?: InputMaybe<Scalars['Float']>;
  referralQuestion?: InputMaybe<ReferralQuestionChoice>;
  referralQuestionOrganizationId?: InputMaybe<Scalars['String']>;
  referralQuestionOrganizationName?: InputMaybe<Scalars['String']>;
  referralQuestionOther?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
  signatureUrl?: InputMaybe<Scalars['String']>;
  type: OrganizationType;
  website?: InputMaybe<Scalars['String']>;
};

export type UpdateAdministrativeMoreInformationInput = {
  category: OrganizationCategory;
  hasFinishZeffyOnboarding?: InputMaybe<Scalars['Boolean']>;
  referralQuestion?: InputMaybe<ReferralQuestionChoice>;
  referralQuestionOrganizationId?: InputMaybe<Scalars['String']>;
  referralQuestionOrganizationName?: InputMaybe<Scalars['String']>;
  referralQuestionOther?: InputMaybe<Scalars['String']>;
};

export type UpdateDonationFormAmountInput = {
  amount: Scalars['Float'];
  donationFormFieldId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  isRecurrent: Scalars['Boolean'];
  message?: InputMaybe<Scalars['String']>;
  recurrenceInterval: ProductDonationRecurrenceInterval;
  sortIndex: Scalars['Float'];
};

export type UpdateOrganizationEngagementFlagsInput = {
  engagementAddContactTour?: InputMaybe<Scalars['Boolean']>;
  engagementContactNotesTour?: InputMaybe<Scalars['Boolean']>;
  engagementContactPaymentsTour?: InputMaybe<Scalars['Boolean']>;
  engagementContactTagsTour?: InputMaybe<Scalars['Boolean']>;
  engagementContactsBulkTour?: InputMaybe<Scalars['Boolean']>;
  engagementEmailRecipientsTour?: InputMaybe<Scalars['Boolean']>;
  engagementEmailStatsTour?: InputMaybe<Scalars['Boolean']>;
  engagementEmailTemplatesTour?: InputMaybe<Scalars['Boolean']>;
  engagementPaymentDetailsTour?: InputMaybe<Scalars['Boolean']>;
  engagementPaymentTour?: InputMaybe<Scalars['Boolean']>;
  engagementPaymentsExportTour?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['String'];
};

export type UpdateOrganizationHubspotPropertyInput = {
  property: Scalars['String'];
  value: Scalars['String'];
};

export type UpdatePaymentIntentInput = {
  billedAmount: Scalars['Int'];
  donationId?: InputMaybe<Scalars['String']>;
  orderId?: InputMaybe<Scalars['String']>;
  organizationId: Scalars['String'];
  stripePaymentIntentId: Scalars['String'];
  transferAmount: Scalars['Int'];
};

export type UpdatePaymentIntentResponse = {
  __typename?: 'UpdatePaymentIntentResponse';
  error?: Maybe<UpdatePaymentIntentResponseErrorObject>;
  object?: Maybe<UpdatePaymentIntentResponseObject>;
};

export type UpdatePaymentIntentResponseErrorObject = {
  __typename?: 'UpdatePaymentIntentResponseErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type UpdatePaymentIntentResponseObject = {
  __typename?: 'UpdatePaymentIntentResponseObject';
  stripePaymentIntentId: Scalars['String'];
};

export type UpdatePayoutInterval = {
  schedule: PayoutInterval;
};

export type UpdateVolumeThresholdReached = {
  volumeThresholdReached: Scalars['Float'];
};

export type UpsertSessionInput = {
  amount?: InputMaybe<Scalars['Float']>;
  appCodeName: Scalars['String'];
  appName: Scalars['String'];
  appVersion: Scalars['String'];
  defaultSuggestedTip?: InputMaybe<Scalars['Int']>;
  formId?: InputMaybe<Scalars['String']>;
  formType?: InputMaybe<FormType>;
  id: Scalars['String'];
  isEmbed: Scalars['Boolean'];
  isSuggestedTipsAmount?: InputMaybe<Scalars['Boolean']>;
  language: Scalars['String'];
  suggestedTips?: InputMaybe<Array<Scalars['Int']>>;
  tipExperimentValue?: InputMaybe<FeatureFlagValue>;
  url: Scalars['String'];
};

export type UserDetailsObject = {
  __typename?: 'UserDetailsObject';
  firstPaymentDate?: Maybe<Scalars['Date']>;
  lastPaymentDate?: Maybe<Scalars['Date']>;
  totalContribution: Scalars['Float'];
};

export type UserListResponseObject = {
  __typename?: 'UserListResponseObject';
  error?: Maybe<UserListResponseObjectErrorObject>;
  items?: Maybe<Array<UserObject>>;
};

export type UserListResponseObjectErrorObject = {
  __typename?: 'UserListResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type UserObject = {
  __typename?: 'UserObject';
  contact?: Maybe<ContactObject>;
  contacts?: Maybe<Array<ContactObject>>;
  deprecatedMongoId?: Maybe<Scalars['String']>;
  details: UserDetailsObject;
  deviceIds?: Maybe<Array<Scalars['String']>>;
  donations?: Maybe<Array<DonationObject>>;
  email?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  fullName: Scalars['String'];
  hasPassword: Scalars['Boolean'];
  id: Scalars['String'];
  isAuthenticatedWithGoogleSSO: Scalars['Boolean'];
  isContactOfAnotherOrganization: Scalars['Boolean'];
  isImported: Scalars['Boolean'];
  lastName: Scalars['String'];
  locale: Locales;
  maskedPhoneNumber?: Maybe<Scalars['String']>;
  orders?: Maybe<Array<OrderObject>>;
  organizationGroups?: Maybe<Array<OrganizationGroupObject>>;
  refreshToken?: Maybe<Scalars['String']>;
  stripeCustomers?: Maybe<Array<StripeCustomerObject>>;
  tags?: Maybe<Array<TagObject>>;
};

export type UserObject1jx9IfMp = {
  __typename?: 'UserObject1jx9IfMp';
  error?: Maybe<UserObject1jx9IfMpErrorObject>;
  object?: Maybe<UserObject>;
};

export type UserObject1jx9IfMpErrorObject = {
  __typename?: 'UserObject1jx9IfMpErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type UserObjectaD0e4cDg = {
  __typename?: 'UserObjectaD0e4cDg';
  error?: Maybe<UserObjectaD0e4cDgErrorObject>;
  object?: Maybe<UserObject>;
};

export type UserObjectaD0e4cDgErrorObject = {
  __typename?: 'UserObjectaD0e4cDgErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type UserObjectkNfuLhOk = {
  __typename?: 'UserObjectkNFULhOK';
  error?: Maybe<UserObjectkNfuLhOkErrorObject>;
  object?: Maybe<UserObject>;
};

export type UserObjectkNfuLhOkErrorObject = {
  __typename?: 'UserObjectkNFULhOKErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type UserObjectyCErfFnd = {
  __typename?: 'UserObjectyCErfFnd';
  error?: Maybe<UserObjectyCErfFndErrorObject>;
  object?: Maybe<UserObject>;
};

export type UserObjectyCErfFndErrorObject = {
  __typename?: 'UserObjectyCErfFndErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum UserPermissions {
  Bank = 'Bank',
  Contacts = 'Contacts',
  Donation = 'Donation',
  Receipt = 'Receipt',
  Ticketing = 'Ticketing',
  UserManagement = 'UserManagement'
}

export enum UserRegistrationStatus {
  Registered = 'Registered',
  Unknown = 'Unknown'
}

export type UserResponseObject = {
  __typename?: 'UserResponseObject';
  error?: Maybe<UserResponseObjectErrorObject>;
  object?: Maybe<UserObject>;
};

export type UserResponseObjectErrorObject = {
  __typename?: 'UserResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum UserRoles {
  Donor = 'Donor',
  Member = 'Member',
  Owner = 'Owner'
}

export type UuidInput = {
  id: Scalars['String'];
};

export type VerifyEmailObject = {
  __typename?: 'VerifyEmailObject';
  accessToken?: Maybe<Scalars['String']>;
  locale?: Maybe<Locales>;
  role?: Maybe<UserRoles>;
};

export type VerifyEmailObjectughfeaZr = {
  __typename?: 'VerifyEmailObjectughfeaZR';
  error?: Maybe<VerifyEmailObjectughfeaZrErrorObject>;
  object?: Maybe<VerifyEmailObject>;
};

export type VerifyEmailObjectughfeaZrErrorObject = {
  __typename?: 'VerifyEmailObjectughfeaZRErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type WebAnalyticsItemObject = {
  __typename?: 'WebAnalyticsItemObject';
  connectionTime: Scalars['Float'];
  count: Scalars['Float'];
  dnsTime: Scalars['Float'];
  firstContentfulPaint: Scalars['Float'];
  firstPaint: Scalars['Float'];
  loadEventTime: Scalars['Float'];
  pageLoadTime: Scalars['Float'];
  pageRenderTime: Scalars['Float'];
  requestTime: Scalars['Float'];
  responseTime: Scalars['Float'];
  time: Scalars['String'];
};

export type WebAnalyticsObject = {
  __typename?: 'WebAnalyticsObject';
  data?: Maybe<Array<WebAnalyticsItemObject>>;
};

export type WebAnalyticsResponseObject = {
  __typename?: 'WebAnalyticsResponseObject';
  error?: Maybe<WebAnalyticsResponseObjectErrorObject>;
  object?: Maybe<WebAnalyticsObject>;
};

export type WebAnalyticsResponseObjectErrorObject = {
  __typename?: 'WebAnalyticsResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type WebhooksOverTimeListResponseObject = {
  __typename?: 'WebhooksOverTimeListResponseObject';
  error?: Maybe<WebhooksOverTimeListResponseObjectErrorObject>;
  items?: Maybe<Array<WebhooksOverTimeObject>>;
};

export type WebhooksOverTimeListResponseObjectErrorObject = {
  __typename?: 'WebhooksOverTimeListResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type WebhooksOverTimeObject = {
  __typename?: 'WebhooksOverTimeObject';
  failed: Scalars['Float'];
  queued: Scalars['Float'];
  succeeded: Scalars['Float'];
  time: Scalars['Date'];
};

export type WebhooksOverTimeResponseObjectErrorObject = {
  __typename?: 'WebhooksOverTimeResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ZeffyImportObject = {
  __typename?: 'ZeffyImportObject';
  id: Scalars['String'];
};
